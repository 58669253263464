import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Typography } from '@barpay/barpay-design-system';
import { CheckboxWithLabel } from '@barpay/barpay-design-system';
const { SmallLead } = Typography;

export interface ExtraItemProps {
  name: string;
  price: string;
  checked: boolean;
  id: Number;
  toggleChecked: Function;
  allowCheck: Function;
  removeCheckedItem: Function;
}

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.article`
  padding: 0.5rem 0;
`;

const RightSide = styled.article`
  padding: 0.5rem 0;
`;

function ExtraItem(props: ExtraItemProps) {
  return (
    <Container>
      <LeftSide>
        <CheckboxWithLabel
          label={props.name}
          checked={props.checked}
          id={props.id}
          toggleChecked={props.toggleChecked}
          allowCheck={props.allowCheck}
          removeCheckedItem={props.removeCheckedItem}
        />
      </LeftSide>
      <RightSide>
        <SmallLead margin={3}>
          <NumberFormat
            value={props.price}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={+props.price >= 0 ? '+$' : '$'}
          />
        </SmallLead>
      </RightSide>
    </Container>
  );
}

export default ExtraItem;
