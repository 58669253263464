import React from 'react';
import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';
import barpayBadge from '@barpay/barpay-design-system/lib/assets/images/BarpayBadge.png';
import { Loader } from '@barpay/barpay-design-system';

interface PageContainerProps {
  show: boolean;
}

const PageContainer = styled.div<PageContainerProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.show ? 100 : -1)};
`;

const Header = styled.header`
  width: 100%;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.background};
`;

const BarpayBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-top: 2rem;
`;

interface LoadingPageProps {
  show: boolean;
}

function LoadingPage(props: LoadingPageProps) {
  return (
    <PageContainer {...props}>
      <Header>
        <BarpayBadge src={barpayBadge} alt="Barpay Badge" />
      </Header>
      <Main>
        <Loader color={colors.primary} />
      </Main>
    </PageContainer>
  );
}

export default LoadingPage;
