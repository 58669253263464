import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import { Typography } from '@barpay/barpay-design-system';

const { LargeLead, SmallLead } = Typography;

export interface MyOrderItemPrintingErrorProps {
  name: string;
  price: string;
  logo: string;
  quantity: number;
  id: number;
  index: number;
}

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled.article``;

function MyOrderItemPrintingError(props: MyOrderItemPrintingErrorProps) {
  return (
    <Container>
      <LeftSide>
        <LargeLead>{props.name}</LargeLead>
        <SmallLead>Qty: {props.quantity}</SmallLead>
        <SmallLead>
          <NumberFormat
            value={props.price}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </SmallLead>
      </LeftSide>
    </Container>
  );
}

export default MyOrderItemPrintingError;
