// @ts-nocheck
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { Loader } from '@barpay/barpay-design-system';
import { useLocation } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm';
import { thunkStripeGetPaymentIntentDetails } from 'store/thunks';

const { H2 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin: 2rem auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderSubContainer = styled.div``;

function FastPassStripePayment() {
  let queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  let paymentIntent = queryParams.get('payment_intent');
  const order = useSelector((state: RootState) => state.order);

  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );

  const paymentIntentStatus = useSelector(
    (state: RootState) => state.order.status
  );

  const stripe = useSelector((state: RootState) => state.order.stripe);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: stripe.stripeAccountId,
  });

  useEffect(() => {
    if (paymentIntent && !paymentIntentStatus) {
      dispatch(thunkStripeGetPaymentIntentDetails(paymentIntent, parseInt(id)));
    }
  }, []);

  useEffect(() => {
    if (fastPassesToPurchase <= 0 && !paymentIntent) {
      history.push(`/venue/${id}/fastpass`);
    }
  });

  if (
    paymentIntent &&
    paymentIntentStatus === 'succeeded' &&
    order.fastPassId
  ) {
    history.push(`/venue/${id}/fastpassorderconfirmed/${order.fastPassId}`);
  }

  let mainPaymentContent = (
    <LoadingContainer>
      <Loader
        color={colors.primary}
        message={'Placing Your Fast Pass Order'}
        subheader={''}
        secondsubheader={'PLEASE DO NOT REFRESH'}
      />
    </LoadingContainer>
  );
  let footerContent = <React.Fragment></React.Fragment>;
  let paymentButton = <React.Fragment />;

  if (!order.brainTree.makingManualPayment) {
    paymentButton = (
      <CloseModalButton
        onClick={() => {
          history.push(`/venue/${id}/fastpasspayment`);
        }}
      />
    );

    let options = undefined;

    if (stripe && stripe.stripeSecret) {
      options = {
        paymentMethodCreation: 'manual',
        clientSecret: stripe.stripeSecret,
        appearance: { theme: 'stripe' },
      };
    }

    mainPaymentContent = <div id="dropin-container"></div>;
    mainPaymentContent = options && stripePromise && (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    );

    footerContent = (
      <React.Fragment>
        <Button
          onClick={() => {
            history.push(`/venue/${id}/fastpasspayment`);
          }}
          label="Go Back"
          styleType={ButtonStyleType.Back}
        />
      </React.Fragment>
    );
  }

  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2>Payment</H2>
        </HeaderSubContainer>
        <HeaderSubContainer>{paymentButton}</HeaderSubContainer>
      </Header>
      <Main>{mainPaymentContent}</Main>
      <Footer>{footerContent}</Footer>
    </PageContainer>
  );
}

export default FastPassStripePayment;
