import { JsonConvert, ValueCheckingMode, OperationMode } from 'json2typescript';
import braintreeClient from 'braintree-web';
import { Action } from 'redux';
import {
  requestMenu,
  receiveMenu,
  requestVenue,
  receiveVenue,
  raiseError,
  unverifiedPhoneNumber,
  verifiedPhoneNumber,
  invalidPhoneNumber,
  requestStripePaymentIntent,
  receiveStripePaymentIntent,
  requestBraintreeAuth,
  receiveBraintreeAuth,
  requestGooglePay,
  receiveGooglePay,
  requestGooglePayMakePayment,
  receiveGooglePayMakePayment,
  requestApplePay,
  receiveApplePay,
  makingManualPayment,
  makingStripePayment,
  receiveStripeMakePayment,
  receiveApplePayMakePayment,
  requestApplePayMakePayment,
  refreshMenu,
  notifyPriceChange,
  orderCompletedWithPrintingError,
  requestFastPass,
  receiveFastPass,
  redeemingFastPass,
  redeemedFastPass,
  receiveStripePaymentIntentDetails,
  setTab,
  setFastPassId,
} from 'store/actions';
import { RootState } from 'store';
import { ThunkAction } from 'redux-thunk';
import Api from 'api/api';
import { rollbar } from 'api/api';
import { Menu } from 'types/Menu';
import { Venue } from 'types/Venue';
import {
  buildGooglePaymentDataRequest,
  createOrderAndPayment,
  createOrderAndStripePayment,
  createOrderAndPlaceWithoutPayment,
  sendReceipt,
  buildGoogleInstance,
  isGoogleReady,
  createFastPassAndPayment,
  createFastPassAndStripePayment,
} from 'store/payment';
import { ApiResult, ERROR_FAST_PASS_ORDER_CREATION } from './types';
import {
  ERROR_UNKNOWN,
  ERROR_ORDER_CREATION,
  ERROR_PAYMENT,
  ERROR_PRINTING,
  ERROR_OUT_OF_STOCK,
  ERROR_BAR_CLOSED,
} from 'store/types';
import { orderCompletedWithError, orderCompleted } from './actions';
import { browserHistory } from 'index';

const jsonConvert: JsonConvert = new JsonConvert();
jsonConvert.operationMode =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? OperationMode.ENABLE
    : OperationMode.ENABLE;
jsonConvert.ignorePrimitiveChecks = false;
jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

export const API = new Api(
  process.env.REACT_APP_API_PROTOCOL as string,
  process.env.REACT_APP_API_URL as string,
  +(process.env.REACT_APP_API_PORT as string)
);

//@ts-ignore
let googlePaymentClient = new google.payments.api.PaymentsClient({
  environment:
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 'PRODUCTION' : 'TEST',
});
const googlePayButton = googlePaymentClient.createButton({
  onClick: () => {},
  buttonType: 'short',
});

export const thunkVerifyCode =
  (
    phoneNumber: string,
    code: string,
    fastpass?: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await API.verifyCode(
        state.order.venue.details.venueId,
        phoneNumber,
        code
      );

      if (response.status === 200 && response.data.status === 'approved') {
        dispatch(verifiedPhoneNumber(phoneNumber));
        browserHistory.push(
          `/venue/${state.order.venue.details.venueId}/${
            fastpass ? 'fastpassorder' : 'menu'
          }` + browserHistory.location.search
        );
      } else {
        dispatch(invalidPhoneNumber());
      }
      return response.status;
    } catch (ex) {
      if (ex.response.status === 400) {
        dispatch(invalidPhoneNumber());
      } else {
        console.error(ex);
        rollbar.error('An error occurred while verifying a phone number.', ex);
        dispatch(raiseError());
      }
    }
  };

export const thunkVerifyPhoneNumber =
  (
    phoneNumber: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await API.verifyPhoneNumber(
        state.order.venue.details.venueId,
        phoneNumber
      );

      if (response.status === 200) {
        dispatch(unverifiedPhoneNumber());
      } else {
        dispatch(invalidPhoneNumber());
      }
      return response.status;
    } catch (ex) {
      if (ex.response.status === 400) {
        dispatch(invalidPhoneNumber());
      } else {
        console.error(ex);
        rollbar.error('An error occurred while verifying a phone number.', ex);
        dispatch(raiseError());
      }
    }
  };

export const thunkCreateOrderOnly =
  (nonce: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(makingManualPayment());
    try {
      createOrderAndPlaceWithoutPayment(API, state, nonce).then(
        (response: ApiResult) => {
          dispatch(receiveGooglePayMakePayment(response));
          dispatch(afterPaymentFlow());
        }
      );
    } catch (ex) {
      rollbar.error(
        'An error occurred while creating an order only order.',
        ex
      );
      dispatch(raiseError());
    }
  };

export const thunkMakeTemporaryStripePayment =
  (
    nonce: string,
    purchasedTier: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const result = await createFastPassAndPayment(
      API,
      state,
      nonce,
      purchasedTier,
      true
    );

    if (!result.session_url) {
      dispatch(raiseError());
      return;
    }
    window.location.href = result.session_url;
  };

export const thunkMakeManualPayment =
  (
    nonce: string,
    isFastPass?: boolean,
    purchasedTier?: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(makingManualPayment());
    try {
      if (!isFastPass) {
        createOrderAndPayment(API, state, nonce).then((response: ApiResult) => {
          dispatch(receiveGooglePayMakePayment(response));
          dispatch(afterPaymentFlow());
        });
      } else {
        createFastPassAndPayment(API, state, nonce, purchasedTier || 0).then(
          (response: ApiResult) => {
            dispatch(receiveGooglePayMakePayment(response));
            dispatch(afterFastPassPaymentFlow());
          }
        );
      }
    } catch (ex) {
      rollbar.error('An error occurred while making a manual payment.', ex);
      dispatch(raiseError());
    }
  };

export const thunkSendReceipt =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await sendReceipt(API, state);

      if (response.status >= 400) {
        dispatch(raiseError());
      }
    } catch (ex) {
      rollbar.error('An error occurred while sending an email.', ex);
      dispatch(raiseError());
    }
  };

export const thunkGetBrainTreeAuth =
  (): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(requestBraintreeAuth());
    try {
      const response = await API.getBraintreeAuth();

      if (response.status === 200) {
        const auth = response.data['bt_token'];
        const instance = await braintreeClient.client
          .create({
            authorization: auth,
          })
          .then((clientInstance: any) => {
            return clientInstance;
          });

        dispatch(receiveBraintreeAuth(auth, instance));
      }
    } catch (ex) {
      rollbar.error('An error occurred while getting braintree auth token', ex);
      dispatch(raiseError());
    }
  };

export const thunkStripeGetPaymentIntentDetails =
  (
    intent: string,
    venueIdOpt?: number
  ): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const venueId = venueIdOpt ?? state.order.venue.details.venueId;
    try {
      const response = await API.getPaymentIntentDetails(venueId, intent);

      if (response.status === 200) {
        const status = response.data['message'];
        const tabId = response.data['tabId'];
        const fastPassId = response.data['fastPassId'];

        dispatch(receiveStripePaymentIntentDetails(status));
        dispatch(setTab(tabId));
        dispatch(setFastPassId(fastPassId));
      }
    } catch (ex) {
      rollbar.error('An error occurred while retreiving intent details', ex);
      dispatch(raiseError());
    }
  };

export const thunkStripePaymentIntent =
  (): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(requestStripePaymentIntent());
    const state = getState();
    const amount =
      state.order.fastPassesPrice === 0
        ? state.order.total
        : state.order.fastPassesPrice;
    const id = state.order.venue.details.venueId;
    try {
      const response = await API.getStripePaymentIntent(id, amount);

      if (response.status === 200) {
        const secret = response.data['secret'];
        const intentId = response.data['intent'];
        const amount = response.data['amount'];
        const accountId = response.data['account'];

        dispatch(
          receiveStripePaymentIntent(secret, amount, intentId, accountId)
        );
      }
    } catch (ex) {
      rollbar.error('An error occurred while getting Stripe intent', ex);
      dispatch(raiseError());
    }
  };

export const thunkMakeStripePayment =
  (
    stripeData: any,
    isFastPass?: boolean,
    purchasedTier?: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(makingStripePayment());
    try {
      if (!isFastPass) {
        createOrderAndStripePayment(API, state, stripeData).then(
          (response: ApiResult) => {
            dispatch(receiveStripeMakePayment(response));
            dispatch(afterPaymentFlow());
          }
        );
      } else {
        createFastPassAndStripePayment(
          API,
          state,
          purchasedTier || 0,
          stripeData
        ).then((response: ApiResult) => {
          dispatch(receiveStripeMakePayment(response));
          dispatch(afterFastPassPaymentFlow());
        });
      }
    } catch (ex) {
      rollbar.error('An error occurred while making a manual payment.', ex);
      dispatch(raiseError());
    }
  };

export const thunkMakeGooglePayment =
  (
    paymentAmount: number,
    isFastPass?: boolean,
    purchasedTier?: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      if (
        state.order.brainTree.braintreeInstance !== undefined &&
        state.order.googlePay.googlePayInstance !== undefined
      ) {
        dispatch(requestGooglePayMakePayment());

        const paymentDataRequest = buildGooglePaymentDataRequest(
          state,
          paymentAmount
        );

        googlePaymentClient
          .loadPaymentData(paymentDataRequest)
          .then((paymentData: any) => {
            state.order.googlePay.googlePayInstance.parseResponse(
              paymentData,
              (err: any, result: any) => {
                if (err) {
                  // Handle parsing error
                  rollbar.error(
                    'An error occurred while loading payment data for Google Pay.',
                    err
                  );
                  dispatch(raiseError());
                  return;
                }

                if (!isFastPass) {
                  createOrderAndPayment(API, state, result.nonce).then(
                    (response: ApiResult) => {
                      dispatch(receiveGooglePayMakePayment(response));
                      dispatch(afterPaymentFlow());
                    }
                  );
                } else {
                  createFastPassAndPayment(
                    API,
                    state,
                    result.nonce,
                    purchasedTier || 0
                  ).then((response: ApiResult) => {
                    dispatch(receiveGooglePayMakePayment(response));
                    dispatch(afterFastPassPaymentFlow());
                  });
                }
              }
            );
          })
          .catch((err: any) => {
            // Handle Google Pay errors
            if (!err || err.statusCode !== 'CANCELED') {
              rollbar.error(
                'An error occurred while making a google pay payment',
                err
              );
              dispatch(raiseError());
            } else {
              dispatch(
                receiveGooglePay(
                  state.order.googlePay.googlePayInstance,
                  true,
                  googlePayButton
                )
              );
            }
          });
      }
    } catch (ex) {
      rollbar.error('An error occurred while making a google pay payment', ex);
      dispatch(raiseError());
    }
  };

export const thunkGetGooglePayAuth =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      if (state.order.brainTree.braintreeInstance !== undefined) {
        dispatch(requestGooglePay());

        const googleInstance = await buildGoogleInstance(
          state,
          braintreeClient
        );
        const isReadyToPay = await isGoogleReady(
          googlePaymentClient,
          googleInstance
        );

        dispatch(
          receiveGooglePay(
            googleInstance,
            isReadyToPay as boolean,
            googlePayButton
          )
        );
      }
    } catch (ex) {
      rollbar.error('An error occurred while getting google pay auth', ex);
      dispatch(raiseError());
    }
  };

export const thunkMakeApplePayment =
  (
    paymentAmount: number,
    isFastPass?: boolean,
    purchasedTier?: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(requestApplePayMakePayment());
    const state = getState();
    try {
      if (
        state.order.brainTree.braintreeInstance !== undefined &&
        state.order.applePay.applePayInstance !== undefined
      ) {
        var paymentRequest =
          state.order.applePay.applePayInstance.createPaymentRequest({
            total: {
              label: state.order.venue.details.venueName,
              amount: paymentAmount.toFixed(2),
            },

            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with
            // all Apple Pay transactions as a best practice.
            requiredBillingContactFields: ['postalAddress'],
          });

        //@ts-ignore
        var session = new ApplePaySession(3, paymentRequest);

        session.onvalidatemerchant = (event: any) => {
          state.order.applePay.applePayInstance.performValidation(
            {
              validationURL: event.validationURL,
              displayName: state.order.venue.details.venueName,
            },
            (err: any, merchantSession: any) => {
              if (err) {
                rollbar.error(
                  'An error occurred while making an apple pay payment.',
                  err
                );
                dispatch(raiseError());
                return;
              }
              session.completeMerchantValidation(merchantSession);
            }
          );
        };

        session.oncancel = (event: any) => {
          dispatch(
            receiveApplePay(state.order.applePay.applePayInstance, true)
          );
        };

        session.onpaymentauthorized = (event: any) => {
          state.order.applePay.applePayInstance.tokenize(
            {
              token: event.payment.token,
            },
            (tokenizeErr: any, payload: any) => {
              if (tokenizeErr) {
                rollbar.error(
                  'An error occurred while tokenizing apple pay',
                  tokenizeErr
                );
                //@ts-ignore
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                dispatch(raiseError());
                return;
              }

              if (!isFastPass) {
                createOrderAndPayment(API, state, payload.nonce).then(
                  (response: ApiResult) => {
                    dispatch(receiveApplePayMakePayment(response));
                    dispatch(afterPaymentFlow());
                  }
                );
              } else {
                createFastPassAndPayment(
                  API,
                  state,
                  payload.nonce,
                  purchasedTier || 0
                ).then((response: ApiResult) => {
                  dispatch(receiveApplePayMakePayment(response));
                  dispatch(afterFastPassPaymentFlow());
                });
              }
              //@ts-ignore
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            }
          );
        };

        session.begin();
      }
    } catch (ex) {
      rollbar.error('An error occurred while making an apple pay payment', ex);
      dispatch(raiseError());
    }
  };

export const thunkGetApplePayAuth =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      if (state.order.brainTree.braintreeInstance !== undefined) {
        dispatch(requestApplePay());
        //@ts-ignore
        const appleInstance = window.ApplePaySession;
        let isReady = false;

        if (appleInstance) {
          if (appleInstance.canMakePayments()) {
            braintreeClient.applePay.create(
              {
                client: state.order.brainTree.braintreeInstance,
              },
              (applePayErr, applePayInstance) => {
                if (applePayErr) {
                  rollbar.error(
                    'An error occurred while geting apple pay auth',
                    applePayErr
                  );
                }

                return appleInstance
                  .canMakePaymentsWithActiveCard(
                    applePayInstance.merchantIdentifier
                  )
                  .then((canMakePaymentsWithActiveCard: any) => {
                    isReady = canMakePaymentsWithActiveCard;
                    dispatch(
                      receiveApplePay(applePayInstance, isReady as boolean)
                    ); // call the dispatch again since its in a callback because braintree cant be consistent in its api
                  });
              }
            );
          }
        }

        dispatch(receiveApplePay(appleInstance, isReady as boolean));
      }
    } catch (ex) {
      rollbar.error('An error occurred while geting apple pay auth', ex);
      dispatch(raiseError());
    }
  };

export const initializeAllPaymentMethods =
  (
    isStripeVenue: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    if (!isStripeVenue) {
      return dispatch(thunkGetBrainTreeAuth()).then(() => {
        dispatch(thunkGetGooglePayAuth());
        dispatch(thunkGetApplePayAuth());
      });
    }
  };

export const thunkGetFastPass =
  (
    venueId: number,
    uuid: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(requestFastPass(venueId, uuid));
    try {
      const response = await API.getFastPass(venueId, uuid);

      if (response.status === 200) {
        // aggregate the web fast pass count
        const uuid = response.data[0].uuid;
        const totalFastPasses = response.data.reduce(
          (accumulator: number, curr: { quantity: number }) =>
            accumulator + curr.quantity,
          0
        );
        const expiration = new Date(response.data[0].expiration);
        const redeemed = response.data[0].redeemed as unknown as boolean;
        dispatch(receiveFastPass(uuid, totalFastPasses, expiration, redeemed));
      }
    } catch (ex) {
      if (ex && ex.response && ex.response.status !== 304) {
        rollbar.error(
          'An error occurred while retrieving the fast pass from api',
          ex
        );
        browserHistory.push(`/venue/${venueId}/fastpasserror`);
      }
    }
  };

export const thunkRedeemFastPass =
  (
    venueId: number,
    uuid: string,
    qrcode: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(redeemingFastPass(venueId, uuid));
    try {
      const response = await API.redeemFastPass(venueId, uuid, qrcode);

      if (response.status === 201) {
        dispatch(redeemedFastPass(uuid));
      }
    } catch (ex) {
      if (ex && ex.response && ex.response.status !== 304) {
        rollbar.error(
          'An error occurred while retrieving the fast pass from api',
          ex
        );
        browserHistory.push(
          `/venue/${venueId}/fastpasserror?error=${ex.response.data.error}`
        );
      }
    }
  };

export const thunkGetMenu =
  (
    venueId: number,
    etag: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(requestMenu(venueId));
    try {
      const response = await API.getMenu(venueId, etag, false);

      if (response.status === 200) {
        //do nothing on a 304. it will load from local storage
        const foodMenu = jsonConvert.deserializeObject(
          response.data['venues']['food_menu'],
          Menu
        );
        //set the type to be used for the sidbar scrollTo
        foodMenu.categories.forEach((menu) => (menu.type = 'Food'));
        if (foodMenu.categories.length > 0) {
          foodMenu.categories[0].selected = true;
        }

        const drinksMenu = jsonConvert.deserializeObject(
          response.data['venues']['drink_menu'],
          Menu
        );

        drinksMenu.categories.forEach((menu) => (menu.type = 'Drink'));
        if (drinksMenu.categories.length > 0) {
          drinksMenu.categories[0].selected = true;
        }

        dispatch(receiveMenu(drinksMenu, foodMenu, response.headers['etag']));
      }
    } catch (ex) {
      if (ex && ex.response && ex.response.status !== 304) {
        rollbar.error(
          'An error occurred while retrieving the menu items from api',
          ex
        );
        dispatch(raiseError());
      }
    }
  };

export const thunkRefreshMenu =
  (venueId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await API.getMenu(venueId, state.order.etag, true);

      if (response.status === 200) {
        //if the response is a 304 then nothing will happen
        const foodMenu = jsonConvert.deserializeObject(
          response.data['venues']['food_menu'],
          Menu
        );
        //set the type to be used for the sidbar scrollTo
        foodMenu.categories.forEach((menu) => (menu.type = 'Food'));

        const drinksMenu = jsonConvert.deserializeObject(
          response.data['venues']['drink_menu'],
          Menu
        );

        drinksMenu.categories.forEach((menu) => (menu.type = 'Drink'));

        dispatch(refreshMenu(drinksMenu, foodMenu, response.headers['etag']));

        if (
          state.order.orderItems.length > 0 &&
          !state.order.notifiedPriceChange &&
          !state.order.brainTree.makingManualPayment &&
          !state.order.googlePay.makingPayment &&
          !state.order.applePay.makingPayment &&
          !state.order.stripe.makingPayment
        ) {
          dispatch(notifyPriceChange());
          browserHistory.push(
            `/venue/${venueId}/menu/cart-cleared` +
              browserHistory.location.search
          );
        }
      }
    } catch (ex) {
      if (ex.response !== undefined && ex.response.status !== 304) {
        rollbar.error(
          'An error occurred while retrieving the menu items from api',
          ex
        );
        dispatch(raiseError());
      }
    }
  };

export const thunkGetVenueInformation =
  (venueId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(requestVenue(venueId));
    try {
      const response = await API.getVenue(venueId);

      if (response.status === 200) {
        const venue = jsonConvert.deserializeObject(response.data, Venue);
        dispatch(receiveVenue(venue));
        if (!venue.orderOnly) {
          dispatch(initializeAllPaymentMethods(venue.isStripeVenue));
        }
      }
    } catch (ex) {
      rollbar.error(
        'An error occurred while getting venue information from the api',
        ex
      );
      dispatch(raiseError());
    }
  };

export const thunkSubmitReview =
  (
    venueId: number,
    rating: number,
    comment: string | undefined,
    guid: string,
    email: string,
    callback: Function
  ): ThunkAction<Promise<void>, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      const response = await API.submitReview(
        venueId,
        rating,
        comment,
        guid,
        email
      );

      callback(response);
    } catch (ex) {
      if (ex.response.status === 404) {
        callback(ex.response);
        return;
      } else {
        rollbar.error('An error occurred while submitting the review', ex);
        dispatch(raiseError());
      }
    }
  };

export const afterPaymentFlow =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentError = state.order.paymentError;
    const paymentSuccess = state.order.paymentSuccess;
    const id = state.order.venue.details.venueId;
    const location = state.order.location;

    if (paymentError) {
      if (paymentError.code === ERROR_ORDER_CREATION) {
        browserHistory.push(
          `/venue/${id}/menu/generic-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithError());
      } else if (paymentError.code === ERROR_PAYMENT) {
        browserHistory.push(
          `/venue/${id}/menu/payment-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithError());
      } else if (paymentError.code === ERROR_PRINTING) {
        browserHistory.push(
          `/venue/${id}/menu/printing-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithPrintingError());
      } else if (paymentError.code === ERROR_OUT_OF_STOCK) {
        browserHistory.push(
          `/venue/${id}/menu/outofstock-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithError());
      } else if (paymentError.code === ERROR_BAR_CLOSED) {
        browserHistory.push(
          `/venue/${id}/menu/barclosed-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithError());
      } else if (paymentError.code === ERROR_UNKNOWN) {
        browserHistory.push(
          `/venue/${id}/menu/generic-error` + browserHistory.location.search
        );
        dispatch(orderCompletedWithError());
      }
    }

    if (paymentSuccess) {
      //barpay station order within venue
      if (location === 'bps' || location === 'Bar Pick Up') {
        browserHistory.push(
          `/venue/${id}/menu/barpay-station-order` +
            browserHistory.location.search
        );
      } else {
        browserHistory.push(
          `/venue/${id}/menu/order-confirmed` + browserHistory.location.search
        );
      }

      dispatch(orderCompleted());
    }
  };

export const afterFastPassPaymentFlow =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentError = state.order.paymentError;
    const paymentSuccess = state.order.paymentSuccess;
    const id = state.order.venue.details.venueId;

    if (paymentError) {
      if (paymentError.code === ERROR_FAST_PASS_ORDER_CREATION) {
        browserHistory.push(
          `/venue/${id}/fastpasserror?error=${paymentError.itemString}`
        );
        dispatch(orderCompletedWithError());
      }
    }

    if (paymentSuccess) {
      browserHistory.push(
        `/venue/${id}/fastpassorderconfirmed/${state.order.fastPassUUID}`
      );
      dispatch(orderCompleted());
    }
  };

export function handlePaymentErrors() {}
