import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { theme } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { GlobalStyle } from '@barpay/barpay-design-system';
import { ModalBackground } from '@barpay/barpay-design-system';
import { RootState } from 'store';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import { MenuSubList } from 'types/Menu';

import Home from 'pages/Home';
import ThankYou from 'pages/ThankYou';
import CompletedReview from 'pages/CompletedReview';
import VenueReview from 'pages/VenueReview';
import Menu from 'pages/Menu';
import { useSelector } from 'react-redux';
import ApplicationMenuCategory from 'components/ApplicationMenuCategory';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import FastPassHome from 'pages/FastPassHome';
import FastPassOrder from 'pages/FastPassOrder';
import FastPassDisabled from 'pages/FastPassDisabled';
import FastPassPayment from 'pages/FastPassPayment';
import FastPassOrderConfirmed from 'pages/FastPassOrderConfirmed';
import FastPassManualPayment from 'pages/FastPassManualPayment';
import FastPassStripePayment from 'pages/FastPassStripePayment';
import FastPassError from 'pages/FastPassError';
import FastPassRedemption from 'pages/FastPassRedemption';

const AppContainer = styled.div`
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;

function App() {
  const showMenu = useSelector((state: RootState) => state.order.showMenu);
  const hasError = useSelector((state: RootState) => state.order.error);
  const { foodMenu, drinksMenu } = useSelector(
    (state: RootState) => state.order.menu
  );

  const itemReducer = (accumulator: number, currentValue: MenuSubList) =>
    accumulator + currentValue.items.length;

  let items = drinksMenu.categories.concat(foodMenu.categories);
  items = items.filter((item) => item.sublists.reduce(itemReducer, 0) > 0);

  const location = useLocation();
  const history = useHistory();
  const isFetching = useSelector(
    (state: RootState) =>
      state.order.venue.isFetching || state.order.menu.isFetching
  );

  let container = <ErrorPage />;

  //redirect in development environment to prevent error page on start up
  if (
    process.env.REACT_APP_ENVIRONMENT === 'development' &&
    location.pathname === '/'
  ) {
    history.push(`/venue/8`);
  }

  let loading = <React.Fragment />;
  if (isFetching) {
    loading = <LoadingPage show={isFetching} />;
  }

  //check and see if the user passed all the information needed in URL
  if (!hasError) {
    container = (
      <React.Fragment>
        <Sidebar show={showMenu}>
          <ApplicationMenuCategory items={items} name={'Menu'} />
        </Sidebar>
        {loading}

        <Switch>
          <Route exact path="/venue/:id" component={Home} />
          <Route exact path="/venue/:id/fastpass" component={FastPassHome} />
          <Route
            exact
            path="/venue/:id/fastpasspayment"
            component={FastPassPayment}
          />
          <Route
            exact
            path="/venue/:id/fastpasspaymentmanual"
            component={FastPassManualPayment}
          />
          <Route
            exact
            path="/venue/:id/fastpasspaymentstripe"
            component={FastPassStripePayment}
          />
          <Route
            exact
            path="/venue/:id/fastpassdisabled"
            component={FastPassDisabled}
          />
          <Route
            exact
            path="/venue/:id/fastpassorder"
            component={FastPassOrder}
          />
          <Route
            exact
            path="/venue/:id/fastpasserror"
            component={FastPassError}
          />

          <Route
            exact
            path="/venue/:id/fastpassorderconfirmed/:uuid"
            component={FastPassOrderConfirmed}
          />
          <Route
            exact
            path="/venue/:id/fastpassredeem/:uuid"
            component={FastPassRedemption}
          />
          <Route exact path="/venue/:id/review" component={VenueReview} />
          <Route exact path="/venue/:id/thankyou" component={ThankYou} />
          <Route
            exact
            path="/venue/:id/completedreview"
            component={CompletedReview}
          />
          <Route exact path="/venue/:id/menu" component={Menu} />
          <Route exact path="/venue/:id/menu/my-order" component={Menu} />
          <Route exact path="/venue/:id/menu/verify-order" component={Menu} />
          <Route
            exact
            path="/venue/:id/menu/alcohol-agreement"
            component={Menu}
          />
          <Route exact path="/venue/:id/menu/payment/manual" component={Menu} />
          <Route exact path="/venue/:id/menu/payment/stripe" component={Menu} />
          <Route
            exact
            path="/venue/:id/menu/order-confirmed"
            component={Menu}
          />
          <Route
            exact
            path="/venue/:id/menu/barpay-station-order"
            component={Menu}
          />
          <Route exact path="/venue/:id/menu/printing-error" component={Menu} />
          <Route
            exact
            path="/venue/:id/menu/outofstock-error"
            component={Menu}
          />
          <Route
            exact
            path="/venue/:id/menu/barclosed-error"
            component={Menu}
          />
          <Route exact path="/venue/:id/menu/cart-cleared" component={Menu} />
          <Route exact path="/venue/:id/menu/busy-bar" component={Menu} />
          <Route exact path="/venue/:id/menu/payment-error" component={Menu} />
          <Route exact path="/venue/:id/menu/generic-error" component={Menu} />
          <Route exact path="/venue/:id/menu/receipt" component={Menu} />
          <Route exact path="/venue/:id/menu/confirmedrec" component={Menu} />
          <Route exact path="/venue/:id/menu/item-details" component={Menu} />
          <Route exact path="/error" component={ErrorPage} />
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalProvider backgroundComponent={ModalBackground}>
        <AppContainer>{container}</AppContainer>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
