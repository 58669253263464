import { Menu, MenuItem } from 'types/Menu';
import { Venue } from 'types/Venue';

export const ERROR_ORDER_CREATION = 'ERROR_ORDER_CREATION';
export const ERROR_FAST_PASS_ORDER_CREATION = 'ERROR_FAST_PASS_ORDER_CREATION';
export const ERROR_PAYMENT = 'ERROR_PAYMENT';
export const ERROR_BAR_CLOSED = 'ERROR_BAR_CLOSED';
export const ERROR_OUT_OF_STOCK = 'ERROR_OUT_OF_STOCK';
export const ERROR_PRINTING = 'ERROR_PRINTING';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
export type ERROR = string | undefined;

export const SET_TAKEOUT = 'SET_TAKEOUT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_TAB_ID = 'SET_TAB_ID';
export const SET_FAST_PASS_ID = 'SET_FAST_PASS_ID';
export const SET_LOCATION_TYPE = 'SET_LOCATION_TYPE';
export const SET_PRINT_QR = 'SET_PRINT_QR';
export const SET_RECORD_ACCOUNT_NUMBER = 'SET_RECORD_ACCOUNT_NUMBER';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_OPT_SF = 'SET_OPT_SF';
export const SET_LOCATION_LIMIT = 'SET_LOCATION_LIMIT';
export const GIVE_NAME = 'GIVE_NAME';
export const GIVE_EMAIL = 'GIVE_EMAIL';
export const SELECT_TABLE = 'SELECT_TABLE';
export const SELECT_PHONE = 'SELECT_PHONE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const RESET_SELECTED_CATEGORY = 'RESET_SELECTED_CATEGORY';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const REQUEST_MENU = 'REQUEST_MENU';
export const REFRESH_MENU = 'REFRESH_MENU';
export const RECEIVE_MENU = 'RECEIVE_MENU';
export const REQUEST_VENUE = 'REQUEST_VENUE';
export const RECEIVE_VENUE = 'RECEIVE_VENUE';
export const RAISE_ERROR = 'RAISE_ERROR';
export const FILTER_MENU = 'FILTER_MENU';
export const ADD_EXTRA = 'ADD_EXTRA';
export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const CLEAR_MENU = 'CLEAR_MENU';
export const EDIT_TIP = 'EDIT_TIP';
export const TOGGLE_TIP = 'TOGGLE_TIP';
export const UPDATE_TOTALS = 'UPDATE_TOTALS';
export const UPDATE_TOTAL_ONLY = 'UPDATE_TOTAL_ONLY';
export const REQUEST_STRIPE_INTENT = 'REQUEST_STRIPE_INTENT';
export const RECEIVE_STRIPE_INTENT = 'RECEIVE_STRIPE_INTENT';
export const RECEIVE_STRIPE_INTENT_DETAILS = 'RECEIVE_STRIPE_INTENT_DETAILS';
export const REQUEST_BRAINTREE_AUTH = 'REQUEST_BRAINTREE_AUTH';
export const RECEIVE_BRAINTREE_AUTH = 'RECEIVE_BRAINTREE_AUTH';
export const REQUEST_GOOGLE_PAY = 'REQUEST_GOOGLE_PAY';
export const RECEIVE_GOOGLE_PAY = 'RECEIVE_GOOGLE_PAY';
export const REQUEST_GOOGLE_PAY_MAKE_PAYMENT =
  'REQUEST_GOOGLE_PAY_MAKE_PAYMENT';
export const RECEIVE_GOOGLE_PAY_MAKE_PAYMENT =
  'RECEIVE_GOOGLE_PAY_MAKE_PAYMENT';
export const REQUEST_APPLE_PAY = 'REQUEST_APPLE_PAY';
export const RECEIVE_APPLE_PAY = 'RECEIVE_APPLE_PAY';
export const REQUEST_APPLE_PAY_MAKE_PAYMENT = 'REQUEST_APPLE_PAY_MAKE_PAYMENT';
export const RECEIVE_APPLE_PAY_MAKE_PAYMENT = 'RECEIVE_APPLE_PAY_MAKE_PAYMENT';
export const ORDER_COMPLETED = 'ORDER_COMPLETED';
export const ORDER_COMPLETED_WITH_ERROR = 'ORDER_COMPLETED_WITH_ERROR';
export const ORDER_COMPLETED_WITH_PRINTING_ERROR =
  'ORDER_COMPLETED_WITH_PRINTING_ERROR';
export const MAKING_MANUAL_PAYMENT = 'MAKING_MANUAL_PAYMENT';
export const MAKING_STRIPE_PAYMENT = 'MAKING_STRIPE_PAYMENT';
export const RECEIVE_STRIPE_MAKE_PAYMENT = 'RECEIVE_STRIPE_MAKE_PAYMENT';
export const CLEAR_PAYMENT_ERROR = 'CLEAR_PAYMENT_ERROR';
export const SELECT_ITEM = 'SELECT_ITEM';
export const NOTIFY_PRICE_CHANGE = 'NOTIFY_PRICE_CHANGE';
export const NOTIFY_BUSY_BAR = 'NOTIFY_BUSY_BAR';
export const UNVERIFIED_PHONE_NUMBER = 'UNVERIFIED_PHONE_NUMBER';
export const VERIFIED_PHONE_NUMBER = 'VERIFIED_PHONE_NUMBER';
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const SELECT_VERIFICATION_CODE = 'SELECT_VERIFICATION_CODE';
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION ';
export const ORDER_FAST_PASS = 'ORDER_FAST_PASS ';
export const DISPLAY_FAST_PASS = 'DISPLAY_FAST_PASS ';
export const REQUEST_FAST_PASS = 'REQUEST_FAST_PASS';
export const RECEIVE_FAST_PASS = 'RECEIVE_FAST_PASS';
export const REDEEMING_FAST_PASS = 'REDEEMING_FAST_PASS';
export const REDEEMED_FAST_PASS = 'REDEEMED_FAST_PASS';
export const CLEAR_FAST_PASS = 'CLEAR_FAST_PASS';

export class OrderItemExtra {
  quantity: number;
  menuItemId: number;
  price: number;
  groupName: string;
  extraName: string;

  constructor() {
    this.quantity = 0;
    this.price = 0;
    this.menuItemId = 0;
    this.groupName = '';
    this.extraName = '';
  }
}

export class OrderItem {
  quantity: number;
  extras: OrderItemExtra[];
  preferences: string;
  menuItemId: number;
  name: string;
  price: string;
  logo: string;
  menu: string;
  constructor() {
    this.quantity = 0;
    this.menuItemId = 0;
    this.extras = [];
    this.preferences = '';
    this.name = '';
    this.price = '';
    this.logo = '';
    this.menu = '';
  }
}

export class ErrorDetails {
  code: ERROR;
  orderId: number | undefined;
  tabId: number | undefined;
  itemString: string | undefined;
  session_url: string | undefined;

  constructor(
    code?: ERROR,
    orderId?: number,
    itemString?: string,
    tabId?: number,
    sessionUrl?: string
  ) {
    this.code = code;
    this.orderId = orderId;
    this.itemString = itemString;
    this.tabId = tabId;
    this.session_url = sessionUrl;
  }
}
export class SuccessDetails {
  orderId: number;
  tabId: number;
  longQueue: boolean;
  waitTime: number;

  constructor(
    orderId: number,
    tabId: number,
    longQueue: boolean,
    waitTime: number
  ) {
    this.orderId = orderId;
    this.tabId = tabId;
    this.longQueue = longQueue;
    this.waitTime = waitTime;
  }
}

export class SuccessFastPassDetails {
  id: number;
  uuid: string;
  session_url: string | undefined;

  constructor(id: number, uuid: string, session_url?: string) {
    this.id = id;
    this.uuid = uuid;
    if (session_url) this.session_url = session_url;
  }
}

export type ApiResult = ErrorDetails | SuccessDetails | SuccessFastPassDetails;

export interface OrderState {
  etag: string;
  takeout: boolean;
  location: string;
  pickupLocation: string;
  printQr: boolean;
  locationType: string;
  recordAccountNumber: boolean;
  accountNumber: string;
  optSF: string;
  locationLimit: number;
  orderId: number;
  tabId: number;
  fastPassId: string;
  longQueue: boolean;
  waitTime: number;
  name: string;
  email: string;
  tax: number;
  table: string;
  phoneNumber: string;
  tip: string;
  hasEditedTip: boolean;
  foodTaxPercent: number;
  drinksTaxPercent: number;
  subtotal: number;
  total: number;
  convenienceFee: number;
  optionalFee: number;
  editTip: boolean;
  orderItems: OrderItem[];
  previousOrderItems: OrderItem[];
  showMenu: boolean;
  fastPassesToPurchase: number;
  fastPassesPrice: number;
  fastPassesExpiration: Date;
  fastPassesRedeemed: boolean;
  fastPassStripeTemporary?: boolean;
  fastPassUUID: string;
  fastPass: {
    isFetching: boolean;
    isRedeemed: boolean;
  };
  paymentError: ErrorDetails | null;
  error: boolean;
  unverifiedPhoneNumber: boolean;
  verifiedPhoneNumber: string;
  invalidPhoneNumber: boolean;
  verificationCode: string;
  paymentSuccess: boolean;
  stripe: {
    isFetching: boolean;
    stripeSecret: string;
    stripeIntentId: string;
    stripeAccountId: string;
    stripeAmount: number;
    makingPayment: boolean;
  };
  brainTree: {
    isFetching: boolean;
    braintreeAuth: string;
    braintreeInstance: any;
    makingManualPayment: boolean;
  };
  googlePay: {
    isFetching: boolean;
    googlePayInstance: any;
    isReady: boolean;
    makingPayment: boolean;
    googlePayButton: any;
  };
  applePay: {
    isFetching: boolean;
    applePayInstance: any;
    isReady: boolean;
    makingPayment: boolean;
  };
  selectedCategory: number;
  venue: {
    isFetching: boolean;
    details: Venue;
  };

  menu: {
    isFetching: boolean;
    foodMenu: Menu;
    drinksMenu: Menu;
  };
  selectedItem: MenuItem;
  filter: string;
  notifiedPriceChange: boolean;
  notifiedBusyBar: boolean;
  status: string;
}

interface RequestBraintreeAuthAction {
  type: typeof REQUEST_BRAINTREE_AUTH;
}

interface ReceiveBraintreeAuthAction {
  type: typeof RECEIVE_BRAINTREE_AUTH;
  auth: string;
  braintreeInstance: any;
}

interface RequestStripePaymentIntent {
  type: typeof REQUEST_STRIPE_INTENT;
}

interface ReceiveStripePaymentIntent {
  type: typeof RECEIVE_STRIPE_INTENT;
  secret: string;
  intentId: string;
  accountId: string;
  amount: number;
}

interface ReceiveStripePaymentIntentDetails {
  type: typeof RECEIVE_STRIPE_INTENT_DETAILS;
  status: string;
}

interface RequestGooglePayAction {
  type: typeof REQUEST_GOOGLE_PAY;
}

interface ReceiveGooglePayAction {
  type: typeof RECEIVE_GOOGLE_PAY;
  googlePayInstance: any;
  isReady: boolean;
  googlePayButton: any;
}

interface RequestGooglePayMakePaymentAction {
  type: typeof REQUEST_GOOGLE_PAY_MAKE_PAYMENT;
}

interface ReceiveGooglePayMakePaymentAction {
  type: typeof RECEIVE_GOOGLE_PAY_MAKE_PAYMENT;
  result: ApiResult;
}

interface ReceiveStripeMakePaymentAction {
  type: typeof RECEIVE_STRIPE_MAKE_PAYMENT;
  result: ApiResult;
}

interface RequestApplePayAction {
  type: typeof REQUEST_APPLE_PAY;
}

interface ReceiveApplePayAction {
  type: typeof RECEIVE_APPLE_PAY;
  applePayInstance: any;
  isReady: boolean;
}

interface RequestApplePayMakePaymentAction {
  type: typeof REQUEST_APPLE_PAY_MAKE_PAYMENT;
}

interface ReceiveApplePayMakePaymentAction {
  type: typeof RECEIVE_APPLE_PAY_MAKE_PAYMENT;
  result: ApiResult;
}

interface RequestMenuAction {
  type: typeof REQUEST_MENU;
  venueId: number;
}

interface OrderFastPassAction {
  type: typeof ORDER_FAST_PASS;
  fastPassesPrice: number;
  fastPassesToPurchase: number;
  fastPassStripeTemporary?: boolean;
}

interface RefreshMenuAction {
  type: typeof REFRESH_MENU;
  etag: string;
  foodMenu: Menu;
  drinksMenu: Menu;
}

interface ReceiveMenuAction {
  type: typeof RECEIVE_MENU;
  etag: string;
  foodMenu: Menu;
  drinksMenu: Menu;
}

interface GiveNameAction {
  type: typeof GIVE_NAME;
  name: string;
}

interface SetTakeoutAction {
  type: typeof SET_TAKEOUT;
  takeout: boolean;
}

interface SetLocationAction {
  type: typeof SET_LOCATION;
  location: string;
}

interface SetTabIdAction {
  type: typeof SET_TAB_ID;
  tabId: number;
}

interface SetFastPassIdAction {
  type: typeof SET_FAST_PASS_ID;
  fastPassId: string;
}

interface SetPickupLocationAction {
  type: typeof SET_PICKUP_LOCATION;
  pickupLocation: string;
}

interface SetPrintQrAction {
  type: typeof SET_PRINT_QR;
  printQr: boolean;
}

interface SetLocationTypeAction {
  type: typeof SET_LOCATION_TYPE;
  locationType: string;
}

interface SetRecordAccountNumberAction {
  type: typeof SET_RECORD_ACCOUNT_NUMBER;
  recordAccountNumber: boolean;
}

interface SetAccountNumberAction {
  type: typeof SET_ACCOUNT_NUMBER;
  accountNumber: string;
}

interface SetOptSFAction {
  type: typeof SET_OPT_SF;
  optSF: string;
}

interface SetLocationLimitAction {
  type: typeof SET_LOCATION_LIMIT;
  locationLimit: number;
}

interface GiveEmailAction {
  type: typeof GIVE_EMAIL;
  email: string;
}

interface SelectTableAction {
  type: typeof SELECT_TABLE;
  table: string;
}

interface SelectPhoneAction {
  type: typeof SELECT_PHONE;
  phone: string;
}

interface ToggleMenuAction {
  type: typeof TOGGLE_MENU;
  selectedCategory: number;
}

interface ResetCategoryAction {
  type: typeof RESET_SELECTED_CATEGORY;
}

interface SelectCategoryAction {
  type: typeof SELECT_CATEGORY;
  selectedCategory: number;
  menuId: number;
}

interface ToggleCategoryAction {
  type: typeof TOGGLE_CATEGORY;
  selectedCategory: number;
  menuId: number;
}

interface RequestVenueAction {
  type: typeof REQUEST_VENUE;
  venueId: number;
}

interface ReceiveVenueAction {
  type: typeof RECEIVE_VENUE;
  venue: Venue;
}

interface RaiseErrorAction {
  type: typeof RAISE_ERROR;
}

interface UnverifiedPhoneNumberAction {
  type: typeof UNVERIFIED_PHONE_NUMBER;
}

interface VerifiedPhoneNumberAction {
  type: typeof VERIFIED_PHONE_NUMBER;
  verifiedPhoneNumber: string;
}

interface InvalidPhoneNumberAction {
  type: typeof INVALID_PHONE_NUMBER;
}

interface SelectVerificationCodeAction {
  type: typeof SELECT_VERIFICATION_CODE;
  code: string;
}

interface FilterMenuAction {
  type: typeof FILTER_MENU;
  filter: string;
}

interface AddMenuItemAction {
  type: typeof ADD_MENU_ITEM;
  item: OrderItem;
}

interface DeleteMenuItemAction {
  type: typeof DELETE_MENU_ITEM;
  index: number;
}

interface ClearMenuAction {
  type: typeof CLEAR_MENU;
}

interface EditTipAction {
  type: typeof EDIT_TIP;
  tip: string;
}

interface ToggleTipAction {
  type: typeof TOGGLE_TIP;
  turnOn: boolean;
  hasEditedTip?: boolean;
}

interface UpdateTotalsAction {
  type: typeof UPDATE_TOTALS;
}

interface UpdateTotalOnlyAction {
  type: typeof UPDATE_TOTAL_ONLY;
}

interface OrderCompleted {
  type: typeof ORDER_COMPLETED;
}

interface OrderCompletedWithError {
  type: typeof ORDER_COMPLETED_WITH_ERROR;
}

interface OrderCompletedWithPrintingError {
  type: typeof ORDER_COMPLETED_WITH_PRINTING_ERROR;
}

interface MakingManualPayment {
  type: typeof MAKING_MANUAL_PAYMENT;
}

interface MakingStripePayment {
  type: typeof MAKING_STRIPE_PAYMENT;
}

interface ClearPaymentErrorAction {
  type: typeof CLEAR_PAYMENT_ERROR;
}

interface NotifyPriceChange {
  type: typeof NOTIFY_PRICE_CHANGE;
}

interface NotifyBusyBar {
  type: typeof NOTIFY_BUSY_BAR;
}

interface SelectItemAction {
  type: typeof SELECT_ITEM;
  item: MenuItem;
}

interface DisplayFastPassAction {
  type: typeof DISPLAY_FAST_PASS;
  uuid: string;
}

interface RequestFastPassAction {
  type: typeof REQUEST_FAST_PASS;
  venueId: number;
  uuid: string;
}

interface RedeemingFastPassAction {
  type: typeof REDEEMING_FAST_PASS;
  uuid: string;
  venueId: number;
}

interface RedeemedFastPassAction {
  type: typeof REDEEMED_FAST_PASS;
  uuid: string;
}

interface ReceiveFastPassAction {
  type: typeof RECEIVE_FAST_PASS;
  uuid: string;
  quantity: number;
  expiration: Date;
  redeemed: boolean;
}

interface ClearFastPassAction {
  type: typeof CLEAR_FAST_PASS;
}

export type OrderActionTypes =
  | SetTakeoutAction
  | SelectCategoryAction
  | ToggleCategoryAction
  | SetLocationAction
  | SetTabIdAction
  | SetFastPassIdAction
  | SetPickupLocationAction
  | SetPrintQrAction
  | SetRecordAccountNumberAction
  | SetAccountNumberAction
  | SetLocationTypeAction
  | SetOptSFAction
  | SetLocationLimitAction
  | GiveNameAction
  | GiveEmailAction
  | SelectTableAction
  | ToggleMenuAction
  | ResetCategoryAction
  | RequestMenuAction
  | ReceiveMenuAction
  | RequestVenueAction
  | ReceiveVenueAction
  | RaiseErrorAction
  | UnverifiedPhoneNumberAction
  | VerifiedPhoneNumberAction
  | InvalidPhoneNumberAction
  | SelectVerificationCodeAction
  | FilterMenuAction
  | AddMenuItemAction
  | DeleteMenuItemAction
  | ClearMenuAction
  | EditTipAction
  | ToggleTipAction
  | UpdateTotalsAction
  | RequestStripePaymentIntent
  | ReceiveStripePaymentIntent
  | ReceiveStripePaymentIntentDetails
  | RequestBraintreeAuthAction
  | ReceiveBraintreeAuthAction
  | RequestGooglePayAction
  | ReceiveGooglePayAction
  | RequestGooglePayMakePaymentAction
  | ReceiveGooglePayMakePaymentAction
  | RequestApplePayAction
  | ReceiveApplePayAction
  | RequestApplePayMakePaymentAction
  | ReceiveApplePayMakePaymentAction
  | OrderCompleted
  | OrderCompletedWithError
  | MakingManualPayment
  | MakingStripePayment
  | ReceiveStripeMakePaymentAction
  | UpdateTotalOnlyAction
  | ClearPaymentErrorAction
  | SelectItemAction
  | RefreshMenuAction
  | NotifyPriceChange
  | NotifyBusyBar
  | SelectPhoneAction
  | OrderCompletedWithPrintingError
  | OrderFastPassAction
  | DisplayFastPassAction
  | RequestFastPassAction
  | ReceiveFastPassAction
  | RedeemingFastPassAction
  | RedeemedFastPassAction
  | ClearFastPassAction;
