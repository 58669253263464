import axios, { AxiosError } from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import Rollbar from 'rollbar';

import {
  ErrorDetails,
  OrderState,
  ERROR_BAR_CLOSED,
  ERROR_OUT_OF_STOCK,
  ERROR_ORDER_CREATION,
  SuccessDetails,
  ERROR_PAYMENT,
  ERROR_PRINTING,
  ERROR_UNKNOWN,
  ERROR_FAST_PASS_ORDER_CREATION,
  SuccessFastPassDetails,
} from 'store/types';

export const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
});

//TODO refactor to use json2Typescript?
const buildOrder = (stateOrder: OrderState) => {
  const order: any = {};
  order.first_name = stateOrder.name;
  order.table_number = stateOrder.table;
  order.location = stateOrder.location;
  order.pickup_location = stateOrder.pickupLocation;
  order.print_qr = stateOrder.printQr;
  order.account_number = stateOrder.accountNumber;

  if (stateOrder.phoneNumber && stateOrder.phoneNumber.length > 0) {
    const phoneNumber = parsePhoneNumber(stateOrder.phoneNumber, 'US');
    order.phone_number = phoneNumber.number.toString();
  }

  order.tip = stateOrder.tip;

  let orderItems: any = [];
  for (let item of stateOrder.orderItems) {
    let apiItem: any = {};
    let extras: any = [];

    apiItem.quantity = item.quantity;
    apiItem.preferences = item.preferences;
    apiItem.menu_item = { id: item.menuItemId };

    for (let extra of item.extras) {
      let apiExtra: any = {};
      apiExtra.quantity = item.quantity; //you have to use item quantity here because an extra can't be different than the item. This helps with the total on the backend. Don't remove.
      apiExtra.menu_item = { id: extra.menuItemId };
      extras.push(apiExtra);
    }

    apiItem.extras = extras;
    orderItems.push(apiItem);
  }

  order.order_items = orderItems;
  return order;
};

const buildFastPassOrder = (
  stateOrder: OrderState,
  nonce: string,
  purchasedTier: number,
  stripeData: any
) => {
  const order: any = {};
  order.first_name = stateOrder.name;

  if (stateOrder.phoneNumber && stateOrder.phoneNumber.length > 0) {
    const phoneNumber = parsePhoneNumber(stateOrder.phoneNumber, 'US');
    order.phone_number = phoneNumber.number.toString();
  }

  order.quantity = stateOrder.fastPassesToPurchase;
  order.purchased_tier = purchasedTier;
  order.payment_nonce = nonce;
  order.data = stripeData;
  return order;
};

export default class Api {
  url: string;

  constructor(protocol: string, apiUrl: string, apiPort?: number) {
    this.url = `${protocol}://${apiUrl}${apiPort !== 0 ? ':' + apiPort : ''}`;
  }

  async verifyCaptcha(value: string) {
    const path = `${this.url}/api/user/verify`;
    const params = {
      response: value,
    };
    return axios.post(path, params);
  }

  async checkOrderStatus(venueId: number, orderId: number) {
    const path = `${this.url}/api/venues/${venueId}/orders/${orderId}`;
    return axios.get(path);
  }

  async getMenu(venueId: number, etag: string, useRand: boolean) {
    //TODO add try catches to all of these
    const rand = new Date().getTime();
    const path = `${this.url}/api/venues/${venueId}${
      useRand ? `?rand=${rand}` : ''
    }`;
    return axios.get(path, {
      headers: {
        'If-None-Match': etag,
      },
    });
  }

  async getFastPass(venueId: number, uuid: string) {
    const path = `${this.url}/api/venues/${venueId}/fastpasses/${uuid}`;
    return axios.get(path);
  }

  async getVenue(venueId: number) {
    const path = `${this.url}/api/venues/${venueId}/withoutmenus`;
    return axios.get(path);
  }

  async getStripePaymentIntent(venueId: number, amount: number) {
    const path = `${this.url}/api/customers/payment_methods/intent`;
    const data = { id: venueId, amount: amount };
    return axios.post(path, data);
  }

  async getPaymentIntentDetails(venueId: number, intent: string) {
    const path = `${this.url}/api/venues/${venueId}/tabs/status/${intent}`;
    return axios.get(path);
  }

  async getBraintreeAuth() {
    const path = `${this.url}/api/customers/payment_methods/token`;
    return axios.get(path);
  }

  async createOrder(venueId: number, order: OrderState) {
    const path = `${this.url}/api/venues/${venueId}/orders`;
    const orderData = buildOrder(order);
    return axios.post(path, orderData);
  }

  async createFastPassOrder(
    venueId: number,
    order: OrderState,
    nonce: string,
    purchasedTier: number,
    stripeData: any
  ) {
    const path = `${this.url}/api/venues/${venueId}/fastpasses`;
    const orderData = buildFastPassOrder(
      order,
      'stripe',
      purchasedTier,
      stripeData
    );
    return axios.post(path, orderData);
  }

  async redeemFastPass(venueId: number, uuid: string, qrcode: number) {
    const path = `${this.url}/api/venues/${venueId}/fastpasses/${uuid}/redeem`;
    return axios.post(path, {
      qrcode,
    });
  }

  async sendReceipt(
    venueId: number,
    email: string,
    tab: number,
    optionalFee: boolean
  ) {
    const path = `${this.url}/api/venues/${venueId}/receipts`;
    return axios.post(path, {
      email: email,
      tab: tab,
      optional_fee: optionalFee,
    });
  }

  async placeWithoutPayment(
    venueId: number,
    tabId: number,
    tableNumber: string,
    nonce: string,
    optFee: boolean,
    locationType: string
  ) {
    const path = `${this.url}/api/venues/${venueId}/tabs/${tabId}`;
    //we are passing in the captcha verification code as the "payment" nonce so that
    //users cant use the API endpoint to create fake orders without going through a
    //captcha process
    return axios.post(path, {
      payment_nonce: nonce,
      table_number: tableNumber,
      optional_fee: optFee,
      location_type: locationType,
    });
  }

  async makePayment(
    venueId: number,
    tabId: number,
    tableNumber: string,
    nonce: string,
    optFee: boolean,
    locationType: string,
    data: any
  ) {
    const path = `${this.url}/api/venues/${venueId}/tabs/${tabId}`;
    //402 is payment failed, and 422 is failed to print
    return axios.post(path, {
      payment_nonce: nonce,
      table_number: tableNumber,
      optional_fee: optFee,
      location_type: locationType,
      data: data,
    });
  }

  async submitReview(
    venueId: number,
    rating: number,
    comment: string | undefined,
    guid: string,
    email: string
  ) {
    const path = `${this.url}/api/venues/${venueId}/reviews`;
    //402 is payment failed, and 422 is failed to print
    return axios.post(path, {
      rating: rating,
      comment: comment,
      id: venueId,
      guid: guid,
      email: email,
    });
  }

  async verifyCode(venueId: number, phoneNumber: string, code: string) {
    const parsedPhoneNumber = parsePhoneNumber(
      phoneNumber,
      'US'
    ).number.toString();
    const path = `${
      this.url
    }/api/venues/${venueId}/check-verify-number?number_to_verify=${encodeURIComponent(
      parsedPhoneNumber
    )}&code=${code}`;
    return axios.get(path);
  }

  async verifyPhoneNumber(venueId: number, phoneNumber: string) {
    const parsedPhoneNumber = parsePhoneNumber(
      phoneNumber,
      'US'
    ).number.toString();
    const path = `${
      this.url
    }/api/venues/${venueId}/verify-number?number_to_verify=${encodeURIComponent(
      parsedPhoneNumber
    )}`;
    return axios.get(path);
  }

  async createAndPlaceWithoutPayment(
    venueId: number,
    order: OrderState,
    nonce: string
  ) {
    let responseFromCreate: any = undefined;
    let orderNumber = 0;
    let tabId = 0;

    try {
      responseFromCreate = await this.createOrder(venueId, order);
    } catch (ex) {
      const err = ex as AxiosError;
      if (err && err.response) {
        rollbar.error('An error occurred when trying to create an order.', ex);
        if (err.response.status === 400) {
          return new ErrorDetails(ERROR_BAR_CLOSED, orderNumber);
        } else if (err.response.status === 404) {
          return new ErrorDetails(
            ERROR_OUT_OF_STOCK,
            orderNumber,
            err.response.data.error
              .replace(' is out of stock', '')
              .replace('The item ', '')
          );
        } else {
          return new ErrorDetails(ERROR_ORDER_CREATION);
        }
      }
      return new ErrorDetails(ERROR_ORDER_CREATION);
    }

    try {
      orderNumber = responseFromCreate.data.orders[0].order_number;
      const orderId = responseFromCreate.data.orders[0].id;
      tabId = responseFromCreate.data.id;
      const optFee = order.optSF !== '';
      await this.placeWithoutPayment(
        venueId,
        tabId,
        order.table,
        nonce,
        optFee,
        order.locationType
      );

      const waitFor = (delay: number) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      // 90 second timeout
      for (let i = 0; i < 45; i++) {
        await waitFor(2 * 1000);
        const response = await this.checkOrderStatus(venueId, orderId);

        if (response.status === 200 && response.data.status === 'placed') {
          return new SuccessDetails(
            orderNumber,
            responseFromCreate.data.id,
            response.data.long_queue,
            0
          );
        }

        if (response.status === 200 && response.data.long_queue === true) {
          return new SuccessDetails(
            orderNumber,
            responseFromCreate.data.id,
            response.data.long_queue,
            response.data.wait_time
          );
        }
      }

      return new ErrorDetails(ERROR_PRINTING, orderNumber, '', tabId);
    } catch (ex) {
      const err = ex as AxiosError;
      if (err && err.response) {
        rollbar.error('An error occurred when trying to make a payment.', ex);
        orderNumber = responseFromCreate.data.orders[0].order_number;
        tabId = responseFromCreate.data.id;

        if (err.response.status === 402) {
          return new ErrorDetails(ERROR_PAYMENT, orderNumber);
        } else if (err.response.status === 422) {
          return new ErrorDetails(ERROR_PRINTING, orderNumber, '', tabId);
        } else {
          return new ErrorDetails(ERROR_UNKNOWN, orderNumber);
        }
      }
      return new ErrorDetails(ERROR_UNKNOWN, orderNumber);
    }
  }

  async createAndPay(
    venueId: number,
    order: OrderState,
    nonce: string,
    stripeData: any
  ) {
    let responseFromCreate: any = undefined;
    let orderNumber = 0;
    let tabId = 0;

    try {
      responseFromCreate = await this.createOrder(venueId, order);
    } catch (ex) {
      const err = ex as AxiosError;
      if (err && err.response) {
        rollbar.error('An error occurred when trying to create an order.', ex);
        if (err.response.status === 400) {
          return new ErrorDetails(ERROR_BAR_CLOSED, orderNumber);
        } else if (err.response.status === 404) {
          return new ErrorDetails(
            ERROR_OUT_OF_STOCK,
            orderNumber,
            err.response.data.error
              .replace(' is out of stock', '')
              .replace('The item ', '')
          );
        } else {
          return new ErrorDetails(ERROR_ORDER_CREATION);
        }
      }
      return new ErrorDetails(ERROR_ORDER_CREATION);
    }

    try {
      orderNumber = responseFromCreate.data.orders[0].order_number;
      const orderId = responseFromCreate.data.orders[0].id;
      tabId = responseFromCreate.data.id;
      const optFee = order.optSF !== '';
      const response = await this.makePayment(
        venueId,
        tabId,
        order.table,
        nonce,
        optFee,
        order.locationType,
        stripeData
      );

      if (response.status === 200 && response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }

      const waitFor = (delay: number) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      // 90 second timeout
      for (let i = 0; i < 45; i++) {
        await waitFor(2 * 1000);
        const response = await this.checkOrderStatus(venueId, orderId);

        if (response.status === 200 && response.data.status === 'placed') {
          return new SuccessDetails(
            orderNumber,
            responseFromCreate.data.id,
            response.data.long_queue,
            0
          );
        }

        if (response.status === 200 && response.data.long_queue === true) {
          return new SuccessDetails(
            orderNumber,
            responseFromCreate.data.id,
            response.data.long_queue,
            response.data.wait_time
          );
        }
      }

      return new ErrorDetails(ERROR_PRINTING, orderNumber, '', tabId);
    } catch (ex) {
      const err = ex as AxiosError;
      if (err && err.response) {
        rollbar.error('An error occurred when trying to make a payment.', ex);
        orderNumber = responseFromCreate.data.orders[0].order_number;
        tabId = responseFromCreate.data.id;

        if (err.response.status === 402) {
          return new ErrorDetails(ERROR_PAYMENT, orderNumber);
        } else if (err.response.status === 422) {
          return new ErrorDetails(ERROR_PRINTING, orderNumber, '', tabId);
        } else {
          return new ErrorDetails(ERROR_UNKNOWN, orderNumber);
        }
      }
      return new ErrorDetails(ERROR_UNKNOWN, orderNumber);
    }
  }

  async createFastPassAndPay(
    venueId: number,
    order: OrderState,
    nonce: string,
    purchasedTier: number,
    stripeData: any
  ) {
    try {
      const responseFromCreate = await this.createFastPassOrder(
        venueId,
        order,
        nonce,
        purchasedTier,
        stripeData
      );

      if (
        responseFromCreate.status === 200 &&
        responseFromCreate.data.redirect_url
      ) {
        window.location.href = responseFromCreate.data.redirect_url;
      }

      return new SuccessFastPassDetails(
        responseFromCreate.data[0].id,
        responseFromCreate.data[0].uuid,
        responseFromCreate.data[0].session_url
      );
    } catch (ex) {
      const err = ex as AxiosError;
      if (err && err.response) {
        rollbar.error(
          'An error occurred when trying to create a fast pass.',
          ex
        );
        if (err.response.status === 406) {
          return new ErrorDetails(
            ERROR_FAST_PASS_ORDER_CREATION,
            undefined,
            err.response.data.error
          );
        } else {
          return new ErrorDetails(
            ERROR_FAST_PASS_ORDER_CREATION,
            undefined,
            'An error occurred when trying to create a fast pass.'
          );
        }
      }
      return new ErrorDetails(
        ERROR_FAST_PASS_ORDER_CREATION,
        undefined,
        'An error occurred when trying to create a fast pass.'
      );
    }
  }
}
