import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType, colors } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { thunkGetFastPass, thunkRedeemFastPass } from 'store/thunks';
import { RootState } from 'store';
import LoadingPage from './LoadingPage';
import QrReader from 'react-qr-reader';

const { H2, SmallParagraph } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  width: 100%;
  margin-top: 5%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
`;

const Footer = styled.footer`
  width: 100%;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function FastPassRedemption() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id, uuid } = useParams();
  const [isScanned, setIsScanned] = useState(false);

  const handleScan = (data: any) => {
    if (data && !isScanned) {
      setIsScanned(true);
      dispatch(thunkRedeemFastPass(id, uuid, parseInt(data, 10)));
    }
  };
  const handleError = (err: any) => {
    console.error(err);
  };

  const unverifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.unverifiedPhoneNumber
  );

  const isFetching = useSelector(
    (state: RootState) => state.order.fastPass.isFetching
  );

  const isRedeemed = useSelector(
    (state: RootState) => state.order.fastPass.isRedeemed
  );

  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );

  let loading = <React.Fragment />;
  if (isFetching) {
    loading = <LoadingPage show={isFetching} />;
  }

  useEffect(() => {
    if (!uuid || uuid.length !== 36) {
      history.push(`/venue/${id}/fastpass`);
    } else {
      dispatch(thunkGetFastPass(id, uuid));
    }
  }, [id, dispatch, uuid, history]);

  let content = (
    <React.Fragment>
      <Header>
        <H2>Scan The Code</H2>
        <SmallParagraph>
          Verify your purchase by scanning the code supplied by the staff
          member.
        </SmallParagraph>
        <Container>
          <H2 fontSize={'1.75rem'}>Valid For</H2>
          <H2 margin={0} color={colors.success} fontSize={'1.75rem'}>
            {fastPassesToPurchase}
          </H2>
          <H2 fontSize={'1.75rem'}>
            {fastPassesToPurchase === 1 ? 'Guest' : 'Guests'}
          </H2>
        </Container>
      </Header>
      <Main>
        <QrReader
          delay={300}
          style={{ width: '100%' }}
          onError={handleError}
          onScan={handleScan}
        />
      </Main>
      <Footer>
        <Button
          label={`Cancel Scan`}
          onClick={async () => {
            history.push(`/venue/${id}/fastpassorderconfirmed/${uuid}`);
          }}
          styleType={ButtonStyleType.Primary}
        />
      </Footer>
    </React.Fragment>
  );

  if (isRedeemed) {
    content = (
      <React.Fragment>
        <Main>
          <H2 color={colors.success} marginTop={75} fontSize={32}>
            Success!
          </H2>
          <SmallParagraph fontSize={15}>
            Thank you for purchasing a Fast Pass. Your receipt was texted to you
            at the time of purchase.
          </SmallParagraph>
        </Main>
        <Footer>
          <Button
            label={`Finish`}
            onClick={async () => {
              history.push(`/venue/${id}`);
              if (unverifiedPhoneNumber) {
                history.go(0);
              }
            }}
            styleType={ButtonStyleType.Primary}
          />
        </Footer>
      </React.Fragment>
    );
  }
  return (
    <PageContainer>
      {loading}
      {content}
    </PageContainer>
  );
}

export default FastPassRedemption;
