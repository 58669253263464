import React from 'react';
import styled from 'styled-components';
import VenueInformation from 'components/VenueInformation';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { size } from '@barpay/barpay-design-system';
import { device } from '@barpay/barpay-design-system';

interface StyledHeaderProps {
  disableHours?: boolean;
}

const StyledHeader = styled.header<StyledHeaderProps>`
  height: 27%;
  width: 100%;
  margin: 0 auto;
  max-width: ${size.tablet};
  margin-bottom: ${(p) => (p.disableHours ? '30px' : '70px')};

  @media ${device.mobileS} {
    min-height: 220px;
  }

  @media ${device.heightTablet} {
    visibility: visible;
  }

  @media ${device.heightMobileL} {
    visibility: hidden;
    height: 0%;
  }
`;

interface AppHeaderProps {
  disableHours?: boolean;
}

function AppHeader(props: AppHeaderProps) {
  const logoImageUrl = useSelector(
    (state: RootState) => state.order.venue.details.venueLogoImageUrl
  );
  const heroImageUrl = useSelector(
    (state: RootState) => state.order.venue.details.venueHeroImageUrl
  );
  const name = useSelector(
    (state: RootState) => state.order.venue.details.venueName
  );
  const address1 = useSelector(
    (state: RootState) => state.order.venue.details.venueAddress1
  );
  const address2 = useSelector(
    (state: RootState) => state.order.venue.details.venueAddress2
  );
  const city = useSelector(
    (state: RootState) => state.order.venue.details.venueCity
  );
  const state = useSelector(
    (state: RootState) => state.order.venue.details.venueState
  );
  const zipCode = useSelector(
    (state: RootState) => state.order.venue.details.venueZipCode
  );

  return (
    <StyledHeader id={'venueHeader'} disableHours={props.disableHours}>
      <VenueInformation
        logo={logoImageUrl}
        heroImage={heroImageUrl}
        name={name}
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        zipCode={zipCode}
        disableHours={props.disableHours}
      />
    </StyledHeader>
  );
}

export default AppHeader;
