import { useState } from 'react';
import { useSelector, DefaultRootState } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

function UseValidateEmailField<TState = DefaultRootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  fieldName: string,
  url: string
): [boolean, string, (callback: Function) => void] {
  const field = useSelector(selector);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  let { id } = useParams();
  let regexpEmail: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})\s*$/i;

  const validate = (callback: Function) => {
    if (!field || !regexpEmail.test((field as unknown) as string)) {
      setHasError(true);
      setErrorMessage(`You must enter a valid ${fieldName}`);
    } else {
      setHasError(false);
      setErrorMessage('');
      callback();
      history.push(`/venue/${id}${url}` + history.location.search);
    }
  };

  return [hasError, errorMessage, validate];
}

export default UseValidateEmailField;
