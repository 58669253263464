import {
  OrderActionTypes,
  SET_TAKEOUT,
  SET_LOCATION,
  SET_TAB_ID,
  SET_FAST_PASS_ID,
  SET_PICKUP_LOCATION,
  SET_PRINT_QR,
  SET_LOCATION_TYPE,
  SET_RECORD_ACCOUNT_NUMBER,
  SET_ACCOUNT_NUMBER,
  SET_OPT_SF,
  SET_LOCATION_LIMIT,
  GIVE_NAME,
  GIVE_EMAIL,
  SELECT_TABLE,
  SELECT_PHONE,
  SELECT_CATEGORY,
  TOGGLE_CATEGORY,
  TOGGLE_MENU,
  RESET_SELECTED_CATEGORY,
  REQUEST_MENU,
  RECEIVE_MENU,
  REQUEST_VENUE,
  RECEIVE_VENUE,
  RAISE_ERROR,
  UNVERIFIED_PHONE_NUMBER,
  VERIFIED_PHONE_NUMBER,
  INVALID_PHONE_NUMBER,
  FILTER_MENU,
  ADD_MENU_ITEM,
  OrderItem,
  OrderItemExtra,
  CLEAR_MENU,
  DELETE_MENU_ITEM,
  EDIT_TIP,
  TOGGLE_TIP,
  UPDATE_TOTALS,
  REQUEST_STRIPE_INTENT,
  RECEIVE_STRIPE_INTENT,
  RECEIVE_STRIPE_INTENT_DETAILS,
  REQUEST_BRAINTREE_AUTH,
  RECEIVE_BRAINTREE_AUTH,
  REQUEST_GOOGLE_PAY,
  RECEIVE_GOOGLE_PAY,
  REQUEST_GOOGLE_PAY_MAKE_PAYMENT,
  RECEIVE_GOOGLE_PAY_MAKE_PAYMENT,
  REQUEST_APPLE_PAY,
  RECEIVE_APPLE_PAY,
  REQUEST_APPLE_PAY_MAKE_PAYMENT,
  RECEIVE_APPLE_PAY_MAKE_PAYMENT,
  ORDER_COMPLETED,
  MAKING_MANUAL_PAYMENT,
  MAKING_STRIPE_PAYMENT,
  RECEIVE_STRIPE_MAKE_PAYMENT,
  UPDATE_TOTAL_ONLY,
  ORDER_COMPLETED_WITH_ERROR,
  ApiResult,
  CLEAR_PAYMENT_ERROR,
  SELECT_ITEM,
  REFRESH_MENU,
  NOTIFY_PRICE_CHANGE,
  NOTIFY_BUSY_BAR,
  ORDER_COMPLETED_WITH_PRINTING_ERROR,
  SELECT_VERIFICATION_CODE,
  ORDER_FAST_PASS,
  DISPLAY_FAST_PASS,
  REQUEST_FAST_PASS,
  RECEIVE_FAST_PASS,
  REDEEMING_FAST_PASS,
  REDEEMED_FAST_PASS,
  CLEAR_FAST_PASS,
} from 'store/types';
import { Menu, MenuItem } from 'types/Menu';
import { Venue } from 'types/Venue';

export function setTakeout(takeout: boolean): any {
  return {
    type: SET_TAKEOUT,
    takeout: takeout,
  };
}

export function setLocation(location: string): any {
  return {
    type: SET_LOCATION,
    location: location,
  };
}

export function setTab(tabId: number): any {
  return {
    type: SET_TAB_ID,
    tabId,
  };
}

export function setFastPassId(fastPassId: string): any {
  return {
    type: SET_FAST_PASS_ID,
    fastPassId,
  };
}

export function setPickupLocation(pickupLocation: string): any {
  return {
    type: SET_PICKUP_LOCATION,
    pickupLocation: pickupLocation,
  };
}

export function setPrintQr(printQr: boolean): any {
  return {
    type: SET_PRINT_QR,
    printQr: printQr,
  };
}

export function setLocationType(locationType: string): any {
  return {
    type: SET_LOCATION_TYPE,
    locationType: locationType,
  };
}

export function setRecordAccountNumber(recordAccountNumber: boolean): any {
  return {
    type: SET_RECORD_ACCOUNT_NUMBER,
    recordAccountNumber: recordAccountNumber,
  };
}

export function setAccountNumber(accountNumber: string): any {
  return {
    type: SET_ACCOUNT_NUMBER,
    accountNumber: accountNumber,
  };
}

export function setOptSF(optSF: string): any {
  return {
    type: SET_OPT_SF,
    optSF: optSF,
  };
}

export function setLocationLimit(locationLimit: number): any {
  return {
    type: SET_LOCATION_LIMIT,
    locationLimit: locationLimit,
  };
}

export function giveName(name: string): OrderActionTypes {
  return {
    type: GIVE_NAME,
    name: name,
  };
}

export function giveEmail(email: string): OrderActionTypes {
  return {
    type: GIVE_EMAIL,
    email: email,
  };
}

export function selectTable(table: string): OrderActionTypes {
  return {
    type: SELECT_TABLE,
    table: table,
  };
}

export function selectVerificationCode(code: string): OrderActionTypes {
  return {
    type: SELECT_VERIFICATION_CODE,
    code: code,
  };
}

export function selectPhone(phone: string): OrderActionTypes {
  return {
    type: SELECT_PHONE,
    phone: phone,
  };
}

export function toggleMenu(categoryId?: number): OrderActionTypes {
  return {
    type: TOGGLE_MENU,
    selectedCategory: categoryId ?? 0,
  };
}

export function resetSelectedCategory(): OrderActionTypes {
  return {
    type: RESET_SELECTED_CATEGORY,
  };
}

export function selectCategory(
  menuId?: number,
  categoryId?: number
): OrderActionTypes {
  return {
    type: SELECT_CATEGORY,
    selectedCategory: categoryId ?? 0,
    menuId: menuId ?? 0,
  };
}

export function toggleCategory(
  menuId?: number,
  categoryId?: number
): OrderActionTypes {
  return {
    type: TOGGLE_CATEGORY,
    selectedCategory: categoryId ?? 0,
    menuId: menuId ?? 0,
  };
}

export function requestStripePaymentIntent(): OrderActionTypes {
  return {
    type: REQUEST_STRIPE_INTENT,
  };
}

export function receiveStripePaymentIntentDetails(
  status: string
): OrderActionTypes {
  return {
    type: RECEIVE_STRIPE_INTENT_DETAILS,
    status,
  };
}

export function receiveStripePaymentIntent(
  secret: string,
  amount: number,
  intentId: string,
  accountId: string
): OrderActionTypes {
  return {
    type: RECEIVE_STRIPE_INTENT,
    secret: secret,
    amount: amount,
    intentId: intentId,
    accountId: accountId,
  };
}

export function requestBraintreeAuth(): OrderActionTypes {
  return {
    type: REQUEST_BRAINTREE_AUTH,
  };
}

export function receiveBraintreeAuth(
  auth: string,
  braintreeInstance: any
): OrderActionTypes {
  return {
    type: RECEIVE_BRAINTREE_AUTH,
    auth: auth,
    braintreeInstance: braintreeInstance,
  };
}

export function requestMenu(venueId: number): OrderActionTypes {
  return {
    type: REQUEST_MENU,
    venueId: venueId,
  };
}

export function receiveMenu(
  drinks: Menu,
  food: Menu,
  etag: string
): OrderActionTypes {
  return {
    type: RECEIVE_MENU,
    etag: etag,
    foodMenu: food,
    drinksMenu: drinks,
  };
}

export function requestFastPass(
  venueId: number,
  uuid: string
): OrderActionTypes {
  return {
    type: REQUEST_FAST_PASS,
    venueId,
    uuid,
  };
}

export function receiveFastPass(
  uuid: string,
  quantity: number,
  expiration: Date,
  redeemed: boolean
): OrderActionTypes {
  return {
    type: RECEIVE_FAST_PASS,
    uuid,
    quantity,
    expiration,
    redeemed,
  };
}

export function redeemingFastPass(
  venueId: number,
  uuid: string
): OrderActionTypes {
  return {
    type: REDEEMING_FAST_PASS,
    venueId,
    uuid,
  };
}

export function redeemedFastPass(uuid: string): OrderActionTypes {
  return {
    type: REDEEMED_FAST_PASS,
    uuid,
  };
}

export function clearFastPass(): OrderActionTypes {
  return {
    type: CLEAR_FAST_PASS,
  };
}

export function refreshMenu(
  drinks: Menu,
  food: Menu,
  etag: string
): OrderActionTypes {
  return {
    type: REFRESH_MENU,
    etag: etag,
    foodMenu: food,
    drinksMenu: drinks,
  };
}

export function requestVenue(venueId: number): OrderActionTypes {
  return {
    type: REQUEST_VENUE,
    venueId: venueId,
  };
}

export function raiseError(): OrderActionTypes {
  return {
    type: RAISE_ERROR,
  };
}

export function unverifiedPhoneNumber(): OrderActionTypes {
  return {
    type: UNVERIFIED_PHONE_NUMBER,
  };
}

export function verifiedPhoneNumber(phoneNumber: string): OrderActionTypes {
  return {
    type: VERIFIED_PHONE_NUMBER,
    verifiedPhoneNumber: phoneNumber,
  };
}

export function invalidPhoneNumber(): OrderActionTypes {
  return {
    type: INVALID_PHONE_NUMBER,
  };
}

export function receiveVenue(venue: Venue): OrderActionTypes {
  return {
    type: RECEIVE_VENUE,
    venue: venue,
  };
}

export function filterMenu(filter: string): OrderActionTypes {
  return {
    type: FILTER_MENU,
    filter: filter,
  };
}

export function clearMenu(): OrderActionTypes {
  return {
    type: CLEAR_MENU,
  };
}

export function notifyPriceChange(): OrderActionTypes {
  return {
    type: NOTIFY_PRICE_CHANGE,
  };
}

export function notifyBusyBar(): OrderActionTypes {
  return {
    type: NOTIFY_BUSY_BAR,
  };
}

export function deleteMenuItem(index: number): OrderActionTypes {
  return {
    type: DELETE_MENU_ITEM,
    index: index,
  };
}

export function editTip(val: string): OrderActionTypes {
  return {
    type: EDIT_TIP,
    tip: val,
  };
}

export function toggleTip(
  turnOn: boolean,
  hasEditedTip?: boolean
): OrderActionTypes {
  return {
    type: TOGGLE_TIP,
    turnOn: turnOn,
    hasEditedTip: hasEditedTip,
  };
}

export function updateTotals(): OrderActionTypes {
  return {
    type: UPDATE_TOTALS,
  };
}

export function updateTotalOnly(): OrderActionTypes {
  return {
    type: UPDATE_TOTAL_ONLY,
  };
}

export function requestGooglePay(): OrderActionTypes {
  return {
    type: REQUEST_GOOGLE_PAY,
  };
}

export function receiveGooglePay(
  googlePayInstance: any,
  isReady: boolean,
  googlePayButton: any
): OrderActionTypes {
  return {
    type: RECEIVE_GOOGLE_PAY,
    googlePayInstance: googlePayInstance,
    isReady: isReady,
    googlePayButton: googlePayButton,
  };
}

export function requestGooglePayMakePayment(): OrderActionTypes {
  return {
    type: REQUEST_GOOGLE_PAY_MAKE_PAYMENT,
  };
}

export function receiveGooglePayMakePayment(
  result: ApiResult
): OrderActionTypes {
  return {
    type: RECEIVE_GOOGLE_PAY_MAKE_PAYMENT,
    result: result,
  };
}

export function receiveStripeMakePayment(result: ApiResult): OrderActionTypes {
  return {
    type: RECEIVE_STRIPE_MAKE_PAYMENT,
    result: result,
  };
}

export function requestApplePay(): OrderActionTypes {
  return {
    type: REQUEST_APPLE_PAY,
  };
}

export function receiveApplePay(
  applePayInstance: any,
  isReady: boolean
): OrderActionTypes {
  return {
    type: RECEIVE_APPLE_PAY,
    applePayInstance: applePayInstance,
    isReady: isReady,
  };
}

export function requestApplePayMakePayment(): OrderActionTypes {
  return {
    type: REQUEST_APPLE_PAY_MAKE_PAYMENT,
  };
}

export function receiveApplePayMakePayment(
  result: ApiResult
): OrderActionTypes {
  return {
    type: RECEIVE_APPLE_PAY_MAKE_PAYMENT,
    result: result,
  };
}

export function addMenuItem(
  menuItemId: number,
  quantity: number,
  preferences: string,
  extras: OrderItemExtra[],
  logo: string,
  price: string,
  name: string,
  menu: string
): OrderActionTypes {
  let orderItem = new OrderItem();
  orderItem.quantity = quantity;
  orderItem.menuItemId = menuItemId;
  orderItem.preferences = preferences;
  orderItem.logo = logo;
  orderItem.price = price;
  orderItem.name = name;
  orderItem.extras = extras;
  orderItem.menu = menu;

  return {
    type: ADD_MENU_ITEM,
    item: orderItem,
  };
}

export function orderCompleted(): OrderActionTypes {
  return {
    type: ORDER_COMPLETED,
  };
}

export function orderCompletedWithError(): OrderActionTypes {
  return {
    type: ORDER_COMPLETED_WITH_ERROR,
  };
}

export function orderCompletedWithPrintingError(): OrderActionTypes {
  return {
    type: ORDER_COMPLETED_WITH_PRINTING_ERROR,
  };
}

export function clearPaymentError(): OrderActionTypes {
  return {
    type: CLEAR_PAYMENT_ERROR,
  };
}

export function makingManualPayment(): OrderActionTypes {
  return {
    type: MAKING_MANUAL_PAYMENT,
  };
}

export function makingStripePayment(): OrderActionTypes {
  return {
    type: MAKING_STRIPE_PAYMENT,
  };
}

export function selectItem(item: MenuItem): OrderActionTypes {
  return {
    type: SELECT_ITEM,
    item: item,
  };
}

export function orderFastPass(
  fastPassesPrice: number,
  fastPassesToPurchase: number,
  fastPassStripeTemporary?: boolean
) {
  return {
    type: ORDER_FAST_PASS,
    fastPassesPrice,
    fastPassesToPurchase,
    fastPassStripeTemporary,
  };
}

export function displayFastPass(uuid: string) {
  return {
    type: DISPLAY_FAST_PASS,
    uuid,
  };
}
