import React from 'react';
import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';
import AppHeader from 'components/AppHeader';
import useVenueInformation from 'hooks/useVenueInformationWithoutMenus';
import { AppContent } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import barpayBadge from '@barpay/barpay-design-system/lib/assets/images/BarpayBadge.png';

const { H5 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Header = styled.header`
  height: 20%;
  width: 100%;
  text-align: center;
`;

const Main = styled.main`
  height: 80%;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
`;

const BarpayBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-top: 2rem;
`;

function FastPassDisabled() {
  useVenueInformation();

  return (
    <React.Fragment>
      <AppHeader disableHours={true} />
      <AppContent>
        <PageContainer>
          <Header>
            <BarpayBadge src={barpayBadge} alt="Barpay Badge" />
          </Header>
          <Main>
            <H5 fontWeight={400}>Fast pass is not enabled for this venue</H5>
          </Main>
        </PageContainer>
      </AppContent>
    </React.Fragment>
  );
}

export default FastPassDisabled;
