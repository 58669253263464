import React, { useEffect } from 'react';
import { Typography } from '@barpay/barpay-design-system';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button, ButtonStyleType } from '@barpay/barpay-design-system';

import {
  PageContainer,
  Header,
  HeaderSubContainer,
  Main,
  Footer,
  PaymentContainer,
  PaymentButtonContainer,
  ManualPaymentButtonContainer,
  LoadingContainer,
} from 'pages/MyOrder.styles';
import {
  thunkMakeGooglePayment,
  thunkMakeApplePayment,
  thunkStripePaymentIntent,
} from 'store/thunks';

import { colors } from '@barpay/barpay-design-system';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

const { H2, H4, H1 } = Typography;

const Pointer = styled.div`
  cursor: pointer;
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function FastPassPayment() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const fastPassesPrice = useSelector(
    (state: RootState) => state.order.fastPassesPrice
  );
  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );
  const venueDetails = useSelector(
    (state: RootState) => state.order.venue.details
  );

  const paymentSuccess = useSelector(
    (state: RootState) => state.order.paymentSuccess
  );

  const isStripeVenue = useSelector(
    (state: RootState) => state.order.venue.details.isStripeVenue
  );

  const stripe = useSelector((state: RootState) => state.order.stripe);
  const applePay = useSelector((state: RootState) => state.order.applePay);
  const googlePay = useSelector((state: RootState) => state.order.googlePay);

  useEffect(() => {
    if (fastPassesToPurchase <= 0) {
      history.push(`/venue/${id}/fastpass`);
    }
  });

  useEffect(() => {
    let stripeCheck =
      isStripeVenue &&
      !stripe.isFetching &&
      stripe.stripeSecret.length === 0 &&
      fastPassesPrice > 0;
    if (stripeCheck) {
      dispatch(thunkStripePaymentIntent());
    }
  }, [dispatch, fastPassesPrice]);

  let headerContent = (
    <React.Fragment>
      <HeaderSubContainer></HeaderSubContainer>
      <HeaderSubContainer>
        <H2>Fast Pass Payment</H2>
      </HeaderSubContainer>
      <HeaderSubContainer></HeaderSubContainer>
    </React.Fragment>
  );

  let mainContent = (
    <LoadingContainer>
      <Loader
        color={colors.primary}
        message={'Placing Your Fast Pass Order'}
        subheader={''}
        secondsubheader={'PLEASE DO NOT REFRESH'}
      />
    </LoadingContainer>
  );
  let footerContent = <React.Fragment />;
  let applePayButton = <React.Fragment />;

  if (applePay.isReady) {
    applePayButton = (
      <div
        className="apple-pay-button apple-pay-button-black"
        onClick={() => {
          if (applePay.isReady) {
            if (fastPassesToPurchase > 0) {
              dispatch(
                thunkMakeApplePayment(
                  fastPassesPrice,
                  true,
                  venueDetails.currentFastPassTier
                )
              );
            }
          }
        }}
      ></div>
    );
  }
  if (
    !googlePay.isFetching &&
    !applePay.isFetching &&
    !stripe.isFetching &&
    !paymentSuccess
  ) {
    headerContent = (
      <React.Fragment>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2 marginTop={75}>Fast Pass Payment</H2>
        </HeaderSubContainer>
        <HeaderSubContainer></HeaderSubContainer>
      </React.Fragment>
    );
    mainContent = (
      <Article>
        <H4>You are ordering {fastPassesToPurchase} fast passes for:</H4>
        <H1 color={colors.success} fontSize={'60px'}>
          <NumberFormat
            value={fastPassesPrice}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={0}
            prefix={'$'}
          />
        </H1>
      </Article>
    );

    let paymentDetails = isStripeVenue ? (
      <PaymentContainer>
        <ManualPaymentButtonContainer>
          <Button
            onClick={() => {
              history.push(`/venue/${id}/fastpasspaymentstripe`);
            }}
            label="CHECK OUT"
            styleType={ButtonStyleType.Primary}
            disabled={fastPassesPrice === 0}
          />
        </ManualPaymentButtonContainer>
      </PaymentContainer>
    ) : (
      <PaymentContainer>
        <H4>Place Order With:</H4>
        <PaymentButtonContainer id="paymentButtonContainer">
          {applePayButton}

          <div
            onClick={() => {
              if (googlePay.isReady) {
                if (fastPassesToPurchase > 0) {
                  dispatch(
                    thunkMakeGooglePayment(
                      fastPassesPrice,
                      true,
                      venueDetails.currentFastPassTier
                    )
                  );
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html:
                googlePay.googlePayButton &&
                googlePay.googlePayButton.innerHTML,
            }}
          />
        </PaymentButtonContainer>
        <ManualPaymentButtonContainer
          onClick={() => {
            if (fastPassesToPurchase > 0) {
              history.push(`/venue/${id}/fastpasspaymentmanual`);
            }
          }}
        >
          <Pointer>
            <H4 marginTop="1rem">Enter Payment Manually</H4>
          </Pointer>
        </ManualPaymentButtonContainer>
      </PaymentContainer>
    );

    footerContent = <React.Fragment>{paymentDetails}</React.Fragment>;
  }

  return (
    <PageContainer>
      <Header>{headerContent}</Header>
      <Main>{mainContent}</Main>
      <Footer>{footerContent}</Footer>
    </PageContainer>
  );
}

export default FastPassPayment;
