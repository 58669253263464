import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import NumberFormat from 'react-number-format';
import { fonts } from '@barpay/barpay-design-system';
import { device } from '@barpay/barpay-design-system';
import { containsLink, parseUrl } from 'util/urlUtils';
import { Link } from '@barpay/barpay-design-system';

const { H2, Paragraph } = Typography;

interface OrderItemDetailsProps {
  image: string;
  name: string;
  price: string;
  description: string;
}

interface MenuItemLogo {
  url: string;
  show: boolean;
}

const OrderItemImageDiv = styled.div<MenuItemLogo>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  max-width: 400px;
  margin: auto;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

const OrderItemImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  @media ${device.mobileS} {
    max-height: 200px;
  }
  opacity: 0;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
`;

const Header = styled.header`
  text-align: center;
`;

const Main = styled.main`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
`;

const LeftSide = styled.article`
  margin-top: auto;
  margin-bottom: auto;
`;

const RightSide = styled.article`
  margin-left: auto;
`;

const Footer = styled.footer``;

function OrderItemDetails(props: OrderItemDetailsProps) {
  const linkConvertText = 'More Info ';
  //@ts-ignore
  const addDefaultSrc = (ev) => {
    ev.target.parentNode.style.display = 'none';
    ev.target.style.visibility = 'hidden';
  };

  let image = (
    <OrderItemImageDiv
      show={props.image !== null && props.image !== undefined}
      url={props.image}
    >
      <OrderItemImage src={props.image} alt="logo" onError={addDefaultSrc} />
    </OrderItemImageDiv>
  );

  if (!props.image) {
    image = <React.Fragment />;
  }

  let descriptionComponent = <Paragraph>{props.description}</Paragraph>;

  if (containsLink(props.description)) {
    const { firstPart, url, secondPart } = parseUrl(props.description);
    descriptionComponent = (
      <Paragraph>
        {firstPart}
        <Link label={linkConvertText} onClick={() => window.open(url)} />
        {secondPart}
      </Paragraph>
    );
  }

  return (
    <Container>
      <Header>{image}</Header>
      <Main>
        <LeftSide>
          <H2 marginLeft={0}>{props.name}</H2>
        </LeftSide>
        <RightSide>
          <H2 marginRight={0} fontFamily={fonts.secondary} fontWeight={400}>
            <NumberFormat
              value={props.price}
              displayType={'text'}
              thousandSeparator={true}
              allowNegative={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </H2>
        </RightSide>
      </Main>
      <Footer>{descriptionComponent}</Footer>
    </Container>
  );
}

export default OrderItemDetails;
