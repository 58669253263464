import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from 'components/AppHeader';
import { AppContent } from '@barpay/barpay-design-system';
import useVenueInformation from 'hooks/useVenueInformation';
import { RootState } from 'store';
import { Typography } from '@barpay/barpay-design-system';
import StarRatingComponent from 'react-star-rating-component';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { thunkSubmitReview } from 'store/thunks';
import { AxiosResponse } from 'axios';

const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header``;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
  button {
    margin: 0;
  }
`;

const Comment = styled.textarea`
  width: 90%;
  border: 1px solid ${(p) => p.theme.colors.secondaryvarient};
  border-radius: 10px;
  height: 4rem;
  font-family: ${(p) => p.theme.fonts.secondary};
  font-size: 16px;
  &:focus {
    outline: none;
  }
  padding: 10px;
`;

function VenueReview() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  let params = new URLSearchParams(location.search);
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState<string | undefined>();
  const name = useSelector(
    (state: RootState) => state.order.venue.details.venueName
  );

  const setStar = (nextValue: number) => {
    setRating(nextValue);
  };

  useVenueInformation();
  return (
    <React.Fragment>
      <AppHeader />
      <AppContent>
        <PageContainer>
          <Header></Header>
          <Main>
            <H2>Leave a Review!</H2>
            <H5 fontWeight={400}>How was your experience at {name}?</H5>
            <div style={{ fontSize: 36 }}>
              <StarRatingComponent
                name="reviewRating"
                starCount={5}
                value={rating}
                onStarClick={setStar}
              />
            </div>
            <Comment
              placeholder={'Enter Comment'}
              onChange={(event) => setComment(event.target.value)}
            ></Comment>
          </Main>
          <Footer>
            <Button
              onClick={() => {
                dispatch(
                  thunkSubmitReview(
                    id,
                    rating,
                    comment,
                    params.get('guid') ?? '',
                    params.get('email') ?? '',
                    (response: AxiosResponse<any>) => {
                      if (response.status === 404) {
                        history.push(
                          `/venue/${id}/completedreview` +
                            history.location.search
                        );
                      } else {
                        history.push(
                          `/venue/${id}/thankyou` + history.location.search
                        );
                      }
                    }
                  )
                );
              }}
              label="Submit Review"
              styleType={ButtonStyleType.Primary}
            />
          </Footer>
        </PageContainer>
      </AppContent>
    </React.Fragment>
  );
}

export default VenueReview;
