import React from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import AppHeader from 'components/AppHeader';
import { AppContent } from '@barpay/barpay-design-system';
import useVenueInformation from 'hooks/useVenueInformation';
import { Typography } from '@barpay/barpay-design-system';
import { useHistory, useParams } from 'react-router-dom';

const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header``;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
  button {
    margin: 0;
  }
`;

function ThankYou() {
  useVenueInformation();
  const history = useHistory();
  const { id } = useParams();

  return (
    <React.Fragment>
      <AppHeader />
      <AppContent>
        <PageContainer>
          <Header></Header>
          <Main>
            <H2>Thank you!</H2>
            <H5 fontWeight={400}>Please, come see us again!</H5>
          </Main>
          <Footer>
            <Button
              onClick={() => {
                history.push(`/venue/${id}` + history.location.search);
              }}
              label="Home"
              styleType={ButtonStyleType.Primary}
            />
          </Footer>
        </PageContainer>
      </AppContent>
    </React.Fragment>
  );
}

export default ThankYou;
