import React, { useState } from 'react';
import styled from 'styled-components';
import Api from 'api/api';
import { Typography } from '@barpay/barpay-design-system';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { thunkCreateOrderOnly } from 'store/thunks';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { Loader } from '@barpay/barpay-design-system';
import HCaptcha from '@hcaptcha/react-hcaptcha';
const { H2 } = Typography;

const TextBoxContainer = styled.div`
  width: 80%;
  margin: auto;
`;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
  button {
    margin: 0;
  }
`;

const CaptchaContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const API = new Api(
  process.env.REACT_APP_API_PROTOCOL as string,
  process.env.REACT_APP_API_URL as string,
  +(process.env.REACT_APP_API_PORT as string)
);

async function onChange(dispatch: any, token: any) {
  dispatch(thunkCreateOrderOnly(token));
}

function VerifyMyOrder() {
  const dispatch = useDispatch();
  const [pin, setPin] = useState('');
  const placingOrder = useSelector(
    (state: RootState) => state.order.brainTree.makingManualPayment
  );

  const placingStripeOrder = useSelector(
    (state: RootState) => state.order.stripe.makingPayment
  );

  const optOutCaptcha = useSelector(
    (state: RootState) => state.order.venue.details.optOutCaptcha
  );

  let mainContent = <React.Fragment />;

  if (optOutCaptcha) {
    mainContent = (
      <Main>
        <CaptchaContainer>
          <H2>Verify You're Human</H2>
          <TextBoxContainer>
            <TextBox
              label="pedigree"
              onChange={(event) => setPin(event.target.value)}
              value={pin}
              styleType={TextBoxStyleType.Big}
              placeholder={'Enter Venue PIN'}
              inputMode="numeric"
            />
          </TextBoxContainer>
          <Button
            label="Submit"
            onClick={async () => {
              onChange(dispatch, pin);
            }}
            styleType={ButtonStyleType.Secondary}
          />
        </CaptchaContainer>
      </Main>
    );
  } else {
    mainContent = (
      <Main>
        <CaptchaContainer>
          <H2>Verify You're Human</H2>
          <HCaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''}
            onVerify={(token) => onChange(dispatch, token)}
          />
        </CaptchaContainer>
      </Main>
    );
  }

  if (placingOrder || placingStripeOrder) {
    mainContent = (
      <LoadingContainer>
        <Loader
          color={colors.primary}
          message={'Placing Your Order'}
          subheader={
            'If the venue is busy it can take a couple of minutes to print your order.'
          }
          secondsubheader={'PLEASE DO NOT REFRESH'}
        />
      </LoadingContainer>
    );
  }

  return (
    <PageContainer>
      {mainContent}
      <Footer></Footer>
    </PageContainer>
  );
}

export default VerifyMyOrder;
