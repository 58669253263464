import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import ApplicationMenuItem from 'components/ApplicationMenuItem';
import { colors } from '@barpay/barpay-design-system';
import { MenuCategory } from 'types/Menu';
import { useDispatch } from 'react-redux';
import { selectCategory, toggleMenu } from 'store/actions';

const { H5 } = Typography;

export interface ApplicationMenuCategoryProps {
  name: string;
  items: Array<MenuCategory>;
}

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  margin-left: 0.5rem;
  h5 {
    letter-spacing: 2px;
  }
`;

function ApplicationMenuCategory(props: ApplicationMenuCategoryProps) {
  const dispatch = useDispatch();

  const items = props.items.map((item) => (
    <ApplicationMenuItem
      key={item.id}
      name={item.name}
      handleClick={() => {
        dispatch(toggleMenu(item.id));
        dispatch(selectCategory(item.menuId, item.id));
      }}
    />
  ));
  return (
    <Container>
      <H5 marginBottom={0} color={colors.secondary}>
        {props.name}
      </H5>
      {items}
    </Container>
  );
}

export default ApplicationMenuCategory;
