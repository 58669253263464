import React, { useEffect } from 'react';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { ModalButton } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import MyOrderItems from 'components/MyOrderItems';
import MyOrderPricing from 'components/MyOrderPricing';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { clearMenu, toggleTip } from 'store/actions';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from '@barpay/barpay-design-system';
import alcoholAgreement from 'pages/AlcoholAgreement';
import useValidateNumericField from 'hooks/useValidateNumericField';

import {
  PageContainer,
  Header,
  HeaderSubContainer,
  Main,
  Footer,
  PaymentContainer,
  PaymentButtonContainer,
  ManualPaymentButtonContainer,
  LoadingContainer,
} from 'pages/MyOrder.styles';
import {
  thunkMakeGooglePayment,
  thunkMakeApplePayment,
  thunkStripePaymentIntent,
} from 'store/thunks';

import { colors } from '@barpay/barpay-design-system';
import styled from 'styled-components';

const { H2, H4 } = Typography;

const Pointer = styled.div`
  cursor: pointer;
`;

interface MyOrderProps {
  toggleModal?: Function;
}

function MyOrder(props: MyOrderProps) {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const order = useSelector((state: RootState) => state.order);
  const paymentSuccess = useSelector(
    (state: RootState) => state.order.paymentSuccess
  );
  const hasItems = useSelector(
    (state: RootState) => state.order.orderItems.length > 0
  );
  const orderOnly = useSelector(
    (state: RootState) => state.order.venue.details.orderOnly
  );
  const applePay = useSelector((state: RootState) => state.order.applePay);
  const googlePay = useSelector((state: RootState) => state.order.googlePay);
  const stripe = useSelector((state: RootState) => state.order.stripe);
  const isStripeVenue = useSelector(
    (state: RootState) => state.order.venue.details.isStripeVenue
  );
  const total = useSelector((state: RootState) => state.order.total);
  const [hasError, errorMessage, validate] = useValidateNumericField(
    (state: RootState) => state.order.tip,
    'tip'
  );
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );

  useEffect(() => {
    let stripeCheck =
      isStripeVenue &&
      !stripe.isFetching &&
      stripe.stripeSecret.length === 0 &&
      total > 0;
    if (stripeCheck) {
      dispatch(thunkStripePaymentIntent());
    }
  }, [dispatch, total]);

  let headerContent = (
    <React.Fragment>
      <HeaderSubContainer></HeaderSubContainer>
      <HeaderSubContainer>
        <H2>My Order</H2>
      </HeaderSubContainer>
      <HeaderSubContainer></HeaderSubContainer>
    </React.Fragment>
  );

  let mainContent = (
    <LoadingContainer>
      <Loader
        color={colors.primary}
        message={'Placing Your Order'}
        subheader={
          'If the venue is busy it can take a couple of minutes to print your order.'
        }
        secondsubheader={'PLEASE DO NOT REFRESH'}
      />
    </LoadingContainer>
  );
  let footerContent = <React.Fragment />;
  let applePayButton = <React.Fragment />;

  if (applePay.isReady) {
    applePayButton = (
      <div
        className="apple-pay-button apple-pay-button-black"
        onClick={() => {
          if (applePay.isReady) {
            validate();
            if (!hasError) {
              order.orderItems.forEach((item) => {
                //@ts-ignore
                window.clevertap.event.push('Placed Order', {
                  ItemName: item.name,
                  Quantity: item.quantity,
                  venueId: venueId,
                });
              });

              //@ts-ignore
              window.clevertap.event.push('Charged', {
                Type: 'Apple',
                Amount: order.total,
                Venue: order.venue.details.venueName,
                venueId: venueId,
              });
              dispatch(toggleTip(false));
              dispatch(thunkMakeApplePayment(total));
            }
          }
        }}
      ></div>
    );
  }
  if (
    !googlePay.isFetching &&
    !applePay.isFetching &&
    !stripe.isFetching &&
    !paymentSuccess
  ) {
    headerContent = (
      <React.Fragment>
        <HeaderSubContainer>
          <Button
            onClick={() => {
              dispatch(clearMenu());
              dispatch(toggleTip(false));
              if (props.toggleModal) props.toggleModal();
              //@ts-ignore
              window.clevertap.event.push('Orders Cleared', {
                venueId: venueId,
              });
            }}
            padding="0"
            label="Clear"
            styleType={ButtonStyleType.Back}
          />
        </HeaderSubContainer>
        <HeaderSubContainer>
          <H2>My Order</H2>
        </HeaderSubContainer>
        <HeaderSubContainer>
          <CloseModalButton
            onClick={() => {
              dispatch(toggleTip(false, false));
              if (props.toggleModal) props.toggleModal();
            }}
          />
        </HeaderSubContainer>
      </React.Fragment>
    );
    mainContent = (
      <React.Fragment>
        <MyOrderItems items={order.orderItems} />
        <MyOrderPricing
          hasError={hasError}
          errorMessage={errorMessage}
          validate={validate}
        />
      </React.Fragment>
    );

    let paymentDetails = isStripeVenue ? (
      <PaymentContainer>
        <ManualPaymentButtonContainer>
          <Button
            onClick={() => {
              history.push(
                `/venue/${id}/menu/payment/stripe` + history.location.search
              );
            }}
            label="CHECK OUT"
            styleType={ButtonStyleType.Primary}
            disabled={total === 0}
          />
        </ManualPaymentButtonContainer>
      </PaymentContainer>
    ) : (
      <PaymentContainer>
        <H4>Place Order With:</H4>
        <PaymentButtonContainer id="paymentButtonContainer">
          {applePayButton}

          <div
            onClick={() => {
              if (googlePay.isReady) {
                validate();
                if (!hasError) {
                  order.orderItems.forEach((item) => {
                    //@ts-ignore
                    window.clevertap.event.push('Placed Order', {
                      ItemName: item.name,
                      Quantity: item.quantity,
                      venueId: venueId,
                    });
                  });

                  //@ts-ignore
                  window.clevertap.event.push('Charged', {
                    Type: 'Google',
                    Amount: order.total,
                    Venue: order.venue.details.venueName,
                    venueId: venueId,
                  });
                  dispatch(toggleTip(false));
                  dispatch(thunkMakeGooglePayment(total));
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html:
                googlePay.googlePayButton &&
                googlePay.googlePayButton.innerHTML,
            }}
          />
        </PaymentButtonContainer>
        <ManualPaymentButtonContainer
          onClick={() => {
            validate();
            if (!hasError) {
              dispatch(toggleTip(false));
              history.push(
                `/venue/${id}/menu/payment/manual` + history.location.search
              );
            }
          }}
        >
          <Pointer>
            <H4 marginTop="1rem">Enter Payment Manually</H4>
          </Pointer>
        </ManualPaymentButtonContainer>
      </PaymentContainer>
    );

    const orderOnlyButton = (
      <Button
        onClick={() => {
          history.push(
            `/venue/${id}/menu/verify-order` + history.location.search
          );
        }}
        label="PLACE ORDER"
        styleType={ButtonStyleType.Primary}
      />
    );

    footerContent = (
      <React.Fragment>
        <ModalButton content={alcoholAgreement()}>
          <Button
            label="Ordering Alcohol?"
            styleType={ButtonStyleType.Back}
            onClick={() => {
              //@ts-ignore
              window.clevertap.event.push('Alcohol Agreement Viewed', {
                venueId: venueId,
              });
              history.push(
                `/venue/${id}/menu/alcohol-agreement` + history.location.search
              );
            }}
          />
        </ModalButton>
        {orderOnly ? (
          hasItems ? (
            orderOnlyButton
          ) : (
            <React.Fragment />
          )
        ) : (
          paymentDetails
        )}
      </React.Fragment>
    );
  }

  return (
    <PageContainer>
      <Header>{headerContent}</Header>
      <Main>{mainContent}</Main>
      <Footer>{footerContent}</Footer>
    </PageContainer>
  );
}

export default MyOrder;
