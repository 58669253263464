import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonStyleType,
  TextBox,
  TextBoxStyleType,
} from '@barpay/barpay-design-system';
import UserInformation from 'components/UserInformation';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearFastPass,
  giveName,
  selectPhone,
  selectVerificationCode,
} from 'store/actions';
import { RootState } from 'store';
import AppHeader from 'components/AppHeader';
import useVenueInformationWithoutMenu from 'hooks/useVenueInformationWithoutMenus';
import useValidateField from 'hooks/useValidateField';
import { AppContent } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { useParams, useHistory } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { thunkVerifyPhoneNumber, thunkVerifyCode } from 'store/thunks';

const { H2, H5, SmallParagraph } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Container = styled.div``;
const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-family: ${(props) => props.theme.fonts.main};
  cursor: pointer;
  padding-left: 5px;
  text-decoration: none;
`;

const TextBoxContainer = styled.div`
  margin: auto;
`;

function FastPassHome() {
  const history = useHistory();
  let { id } = useParams();

  const unverifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.unverifiedPhoneNumber
  );
  const verifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.verifiedPhoneNumber
  );
  const invalidPhoneNumber = useSelector(
    (state: RootState) => state.order.invalidPhoneNumber
  );

  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );

  const venueDetails = useSelector(
    (state: RootState) => state.order.venue.details
  );

  const name = useSelector((state: RootState) => state.order.name);
  const dispatch = useDispatch();

  useVenueInformationWithoutMenu();

  let phoneNumber = useSelector((state: RootState) => state.order.phoneNumber);
  let verificationCode = useSelector(
    (state: RootState) => state.order.verificationCode
  );

  dispatch(selectPhone(phoneNumber));
  dispatch(clearFastPass());

  let [nameHasError, nameErrorMessage, validateName] = useValidateField(
    (state: RootState) => state.order.name,
    'name'
  );

  let [phoneHasError, phoneErrorMessage, validatePhone] = useValidateField(
    (state: RootState) => state.order.phoneNumber,
    `phone number`,
    undefined,
    undefined,
    (value) => {
      const isValid = isValidPhoneNumber(value, 'US');
      return {
        isValid,
        errorMessage: `Please enter a valid phone number`,
      };
    }
  );

  const question = `What is your first name and phone number?`;

  let firstTextBox = (
    <UserInformation
      question={question}
      details="(So we know who the order is for...)"
      value={name}
      hasError={nameHasError}
      errorMessage={nameErrorMessage}
      handleChange={(val: string) => dispatch(giveName(val))}
      placeHolder={'First Name'}
    />
  );

  let phoneVerificationBox = (
    <TextBoxContainer>
      <H2 textAlign="center" fontSize="23px">
        Verify Phone Number
      </H2>
      <H5 textAlign="center">
        Please enter the verification code we just sent you.
      </H5>
    </TextBoxContainer>
  );
  let verifyPhoneNumber = <React.Fragment />;

  useEffect(() => {
    if (venueDetails.venueName && !venueDetails.fastPassAvailable) {
      history.push(`/venue/${id}/fastpassdisabled`);
    }
  }, [venueDetails.venueName, venueDetails.fastPassAvailable, history, id]);

  const button = (
    <Button
      label={unverifiedPhoneNumber ? 'Submit Verification Code' : 'Next'}
      onClick={async () => {
        let hasNameError = await validateName();
        let hasPhoneError = await validatePhone();
        if (!hasPhoneError && !hasNameError) {
          if (verifiedPhoneNumber === phoneNumber) {
            //@ts-ignore
            window.clevertap.event.push('First Name Entered', {
              'First Name': name,
              LocationType: 'FastPass',
              venueId: venueId,
            });
            history.push(`/venue/${id}/fastpassorder`);
          } else if (unverifiedPhoneNumber) {
            dispatch(thunkVerifyCode(phoneNumber, verificationCode, true));
          } else {
            dispatch(thunkVerifyPhoneNumber(phoneNumber));
          }
        }
      }}
      styleType={ButtonStyleType.Secondary}
    />
  );
  verifyPhoneNumber = (
    <TextBoxContainer>
      <TextBox
        label="phoneNumberVerification"
        onChange={(event) => {
          dispatch(selectVerificationCode(event.target.value));
        }}
        value={verificationCode}
        styleType={TextBoxStyleType.Big}
        placeholder="Enter Verification Code"
      />
    </TextBoxContainer>
  );

  const secondTextBox = (
    <UserInformation
      value={phoneNumber}
      hasError={phoneHasError}
      errorMessage={phoneErrorMessage}
      handleChange={(val: string) => dispatch(selectPhone(val))}
      placeHolder={`Phone Number`}
    />
  );

  const content = (
    <PageContainer>
      <Container>
        {unverifiedPhoneNumber ? phoneVerificationBox : firstTextBox}
        {unverifiedPhoneNumber ? '' : secondTextBox}
        {unverifiedPhoneNumber ? verifyPhoneNumber : ''}
      </Container>
      <Container>
        {button}
        {invalidPhoneNumber ? (
          <SmallParagraph color={colors.error} margin="auto" textAlign="center">
            There is an issue verifying this phone number
          </SmallParagraph>
        ) : (
          ''
        )}
      </Container>
      <Container>
        <SmallParagraph>
          By entering a name and clicking continue, you are agreeing to Barpay's
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.barpay.com/terms-of-service"
          >
            terms of service.
          </StyledLink>
        </SmallParagraph>
      </Container>
    </PageContainer>
  );

  return (
    <React.Fragment>
      <AppHeader disableHours={true} />
      <AppContent>{content}</AppContent>
    </React.Fragment>
  );
}

export default FastPassHome;
