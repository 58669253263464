import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { CloseModalButton } from '@barpay/barpay-design-system';

const { H2, H5 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 2rem;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 3rem;
`;

const HeaderSubContainer = styled.div``;

function ReceiptConfirmation() {
  const history = useHistory();
  let { id } = useParams();

  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2></H2>
        </HeaderSubContainer>
        <HeaderSubContainer>
          <CloseModalButton
            onClick={() => {
              history.push(`/venue/${id}/menu` + history.location.search);
            }}
          />
        </HeaderSubContainer>
      </Header>
      <Main>
        <H2>Thank you!</H2>
        <H5 fontWeight={400}>Your receipt is on its way!</H5>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            history.push(`/venue/${id}/menu` + history.location.search);
          }}
          label="Home"
          styleType={ButtonStyleType.Primary}
        />
      </Footer>
    </PageContainer>
  );
}

export default ReceiptConfirmation;
