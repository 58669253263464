import React from 'react';
import styled from 'styled-components';
import VenueMenuSubcategory from 'components/VenueMenuSubcategory';
import { colors } from '@barpay/barpay-design-system';
import { MenuCategory, MenuItem, MenuSubList } from 'types/Menu';
import { toggleCategory } from 'store/actions';
import { useDispatch } from 'react-redux';

export interface VenueMenuCategoryProps {
  categoryTitle: string;
  items: Array<MenuCategory>;
  menuId: number;
}

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
`;

const Divider = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background: ${(p) => p.theme.colors.secondaryvarient};
`;

const itemReducer = (accumulator: Array<MenuItem>, currentValue: MenuSubList) =>
  accumulator.concat(currentValue.items);

function VenueMenuCategory(props: VenueMenuCategoryProps) {
  const dispatch = useDispatch();

  const listItems = props.items.map((category, idx) => (
    <React.Fragment key={category.id}>
      {idx === 0 ? <Divider /> : ''}
      <VenueMenuSubcategory
        id={category.id}
        subcategoryTitle={category.name}
        items={category.sublists.reduce(itemReducer, [])}
        key={category.id}
        handleClick={() => dispatch(toggleCategory(props.menuId, category.id))}
        selected={category.selected}
      />
    </React.Fragment>
  ));

  return <Container>{listItems}</Container>;
}

export default VenueMenuCategory;
