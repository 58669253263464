import { JsonObject, JsonProperty, Any } from 'json2typescript';

@JsonObject('ImageUrl')
export class ImageUrl {
  @JsonProperty('url', String)
  url: string;
  constructor() {
    this.url = '';
  }
}

@JsonObject('MenuItemExtra')
export class MenuItemExtra {
  @JsonProperty('id', Number)
  id: number;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('item_image', ImageUrl)
  itemImage: ImageUrl;
  @JsonProperty('description', String)
  description: string;
  @JsonProperty('retail', String)
  price: string;
  @JsonProperty('in_stock', Boolean)
  inStock: boolean;
  @JsonProperty('default_extra', Boolean)
  default: boolean;

  checked: boolean;

  constructor() {
    this.id = 0;
    this.name = '';
    this.itemImage = new ImageUrl();
    this.description = '';
    this.price = '';
    this.inStock = false;
    this.checked = false;
    this.default = false;
  }
}

@JsonObject('MenuItemExtrasMenu')
export class MenuItemExtrasMenu {
  @JsonProperty('limit', Any)
  limit: any;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('menu_items', [MenuItemExtra])
  items: MenuItemExtra[];

  constructor() {
    this.limit = '';
    this.name = '';
    this.items = [];
  }
}

@JsonObject('MenuItem')
export class MenuItem {
  @JsonProperty('id', Number)
  id: number;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('item_image', ImageUrl)
  itemImage: ImageUrl;
  @JsonProperty('description', String)
  description: string;
  @JsonProperty('retail', String)
  price: string;
  @JsonProperty('extras', Boolean)
  hasExtras: boolean;
  @JsonProperty('extras_menu', [MenuItemExtrasMenu], true)
  extrasMenus?: MenuItemExtrasMenu[];
  @JsonProperty('in_stock', Boolean)
  inStock: boolean;
  @JsonProperty('menu', String)
  menu: string;
  @JsonProperty('link', String)
  link: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.itemImage = new ImageUrl();
    this.description = '';
    this.price = '';
    this.hasExtras = false;
    this.extrasMenus = [];
    this.inStock = false;
    this.menu = '';
    this.link = '';
  }
}

@JsonObject('MenuSubList')
export class MenuSubList {
  @JsonProperty('id', Number)
  id: number;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('menu_items', [MenuItem])
  items: MenuItem[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.items = [];
  }
}

@JsonObject('MenuCategory')
export class MenuCategory {
  @JsonProperty('id', Number)
  id: number;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('menu_sub_lists', [MenuSubList])
  sublists: MenuSubList[];
  type: string;
  selected: boolean;
  @JsonProperty('menu_id', Number)
  menuId: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.sublists = [];
    this.type = '';
    this.selected = false;
    this.menuId = 0;
  }
}

@JsonObject('Menu')
export class Menu {
  @JsonProperty('id', Number)
  id: number;
  @JsonProperty('name', String)
  name: string;
  @JsonProperty('menu_categories', [MenuCategory])
  categories: MenuCategory[];

  constructor(data?: Menu) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.categories = data.categories;
    } else {
      this.id = 0;
      this.name = '';
      this.categories = [];
    }
  }
}
