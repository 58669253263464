import { RootState } from 'store';
import Api from 'api/api';
import { rollbar } from 'api/api';

export function buildGooglePaymentDataRequest(
  state: RootState,
  paymentAmount: number
) {
  let paymentDataRequest =
    state.order.googlePay.googlePayInstance.createPaymentDataRequest({
      transactionInfo: {
        currencyCode: 'USD',
        countryCode: 'US',
        totalPriceStatus: 'FINAL',
        totalPrice: paymentAmount.toFixed(2), // Your amount, has to be a string apparently. who designs these APIs
      },
    });

  paymentDataRequest.merchantInfo = {
    merchantName: 'Barpay',
    merchantId:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_GOOGLE_MERCHANT_ID
        : 'TEST',
  };

  let cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
  cardPaymentMethod.parameters.billingAddressRequired = true;
  cardPaymentMethod.parameters.billingAddressParameters = {
    format: 'FULL',
    phoneNumberRequired: true,
  };

  return paymentDataRequest;
}

export function createOrderAndPlaceWithoutPayment(
  API: Api,
  state: RootState,
  nonce: string
) {
  return API.createAndPlaceWithoutPayment(
    state.order.venue.details.venueId,
    state.order,
    nonce
  );
}

export function createOrderAndPayment(
  API: Api,
  state: RootState,
  nonce: string
) {
  return API.createAndPay(
    state.order.venue.details.venueId,
    state.order,
    'stripe',
    undefined
  );
}

export function createOrderAndStripePayment(
  API: Api,
  state: RootState,
  stripeData: any
) {
  return API.createAndPay(
    state.order.venue.details.venueId,
    state.order,
    'stripe',
    stripeData
  );
}

export function createFastPassAndPayment(
  API: Api,
  state: RootState,
  nonce: string,
  purchasedTier: number,
  fastPassStripeTemporary?: boolean
) {
  return API.createFastPassAndPay(
    state.order.venue.details.venueId,
    state.order,
    nonce,
    purchasedTier,
    undefined
  );
}

export function createFastPassAndStripePayment(
  API: Api,
  state: RootState,
  purchasedTier: number,
  stripeData: any
) {
  return API.createFastPassAndPay(
    state.order.venue.details.venueId,
    state.order,
    'stripe',
    purchasedTier,
    stripeData
  );
}

export function sendReceipt(API: Api, state: RootState) {
  const optSF = state.order.optSF;
  const useOptionalFee = optSF === state.order.venue.details.optionalFeeUUID;

  return API.sendReceipt(
    state.order.venue.details.venueId,
    state.order.email,
    state.order.tabId,
    useOptionalFee
  );
}

export async function buildGoogleInstance(
  state: RootState,
  braintreeClient: any
) {
  let googlePayMerchantDetails: any = {
    client: state.order.brainTree.braintreeInstance,
    googlePayVersion: 2,
  };

  if (process.env.REACT_APP_ENVIRONMENT === 'production')
    googlePayMerchantDetails.googleMerchantId =
      process.env.REACT_APP_GOOGLE_MERCHANT_ID;

  //@ts-ignore
  const googleInstance = await braintreeClient.googlePayment.create(
    googlePayMerchantDetails
  );

  return googleInstance;
}

export async function isGoogleReady(
  googlePaymentClient: any,
  googleInstance: any
) {
  return googlePaymentClient
    .isReadyToPay({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods:
        googleInstance.createPaymentDataRequest().allowedPaymentMethods,
    })
    .then((isReadyToPay: any) => {
      if (isReadyToPay.result) {
        return true;
      } else {
        return false;
      }
    })
    .catch((exception: any) => {
      rollbar.error(
        `The google payment setup failed ${JSON.stringify(exception)}`
      );
    });
}
