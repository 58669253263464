import React from 'react';
import styled from 'styled-components';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
`;

const ClearButton = styled.button`
  margin-top: -5px;
  margin-left: -35px;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  color: ${colors.secondary};
  cursor: pointer;
`;

interface SearchProps {
  value: string;
  handleChange: Function;
}

function Search(props: SearchProps) {
  return (
    <Container id="searchFilterContainer">
      <TextBox
        id="searchFilter"
        label="search"
        placeholder="Search"
        value={props.value}
        styleType={TextBoxStyleType.Standard}
        onChange={(event) => props.handleChange(event.target.value)}
      />
      <ClearButton onClick={() => props.handleChange('')}>X</ClearButton>
    </Container>
  );
}

export default Search;
