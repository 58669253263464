import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  margin: 0.5rem auto;
  width: 80%;
`;
const Main = styled.main`
  width: 100%;
  flex: 1;
  padding-bottom: 55px;
`;
const Footer = styled.footer`
  width: 100%;
  button {
    margin: 0;
  }
  position: fixed;
  bottom: 0;
`;

export { PageContainer, Header, Main, Footer };
