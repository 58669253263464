import React from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { CloseModalButton } from '@barpay/barpay-design-system';

export interface FastPassModalProps {
  onClick: Function;
}

const { H1, H2 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 2rem;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 3rem;
`;

const HeaderSubContainer = styled.div``;

function FastPassModal(props: FastPassModalProps) {
  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <CloseModalButton onClick={props.onClick} />
        </HeaderSubContainer>
      </Header>
      <Main>
        <H1 margin={50}>A Fast Pass Does Not Expire.</H1>
        <H1>Valid Until Redeemed!</H1>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            props.onClick();
          }}
          label="Continue"
          styleType={ButtonStyleType.Secondary}
        />
      </Footer>
    </PageContainer>
  );
}

export default FastPassModal;
