import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import OrderItemDetails from 'components/OrderItemDetails';
import QuantityCounter from 'components/QuantityCounter';
import Extras from 'components/Extras';
import { MenuItemExtra } from 'types/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { addMenuItem } from 'store/actions';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { OrderItemExtra } from 'store/types';
import { RootState } from 'store';

const { SmallParagraph } = Typography;

interface AddOrderItemProps {
  toggleModal?: Function;
}

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Header = styled.header`
  margin: 2rem auto 0;
  width: 80%;
  text-align: center;
`;
const Main = styled.main`
  width: 80%;
  flex: 1;
  margin: 0.5rem auto 0;
`;
const InstructionsContainer = styled.div``;
const Footer = styled.footer`
  width: 70%;
  margin: 1rem auto auto;
  button {
    margin: 0;
  }
  height: 100%;
`;

function AddOrderItem(props: AddOrderItemProps) {
  const initialExtrasState: OrderItemExtra[] = [];
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState(false);
  const [preferences, setPreferences] = useState('');
  const [extrasAdded, setExtrasAdded] = useState(initialExtrasState);
  const selectedItem = useSelector(
    (state: RootState) => state.order.selectedItem
  );
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );
  const enablePreferences = useSelector(
    (state: RootState) => state.order.venue.details.enableSpecialInstructions
  );

  const addItem = () => {
    if (quantity === 0) {
      setQuantityError(true);
      return;
    }

    //@ts-ignore
    window.clevertap.event.push('Product Viewed', {
      venueId: venueId,
      name: selectedItem.name,
      price: selectedItem.price,
    });

    dispatch(
      addMenuItem(
        selectedItem.id,
        quantity,
        preferences,
        extrasAdded,
        selectedItem.itemImage?.url,
        selectedItem.price,
        selectedItem.name,
        selectedItem.menu
      )
    );
    if (props.toggleModal) props.toggleModal();
  };

  const toggleAllExtrasUI = () => {
    const menus = selectedItem?.extrasMenus;

    if (menus) {
      for (const menu of menus) {
        for (const item of menu.items) {
          item.checked = false;
        }
      }
    }
  };

  const toggleUI = (groupName: string, id: number) => {
    const groups = selectedItem?.extrasMenus?.filter(
      (menu) => menu.name === groupName
    );
    if (groups && groups.length > 0) {
      const group = groups[0];
      const checkedItems = group.items.filter((item) => item.id === id);
      if (checkedItems.length > 0) {
        const item = checkedItems[0];
        item.checked = !item.checked;
      }
    }
  };

  const removeCheckedItem = (
    groupName: string,
    extra: MenuItemExtra,
    limitNumber: number
  ) => {
    //only remove itesm if the limit is 1. if its greater than 1, then the user will have to
    //deslect manually
    if (limitNumber === 1) {
      //item to add to the list after removing
      let addedExtra: OrderItemExtra = {
        menuItemId: extra.id,
        price: +extra.price,
        quantity: 1,
        groupName: groupName,
        extraName: extra.name,
      };

      const extrasInGroup = extrasAdded.filter(
        (extra) => extra.groupName === groupName
      );

      if (extrasInGroup.length > 0) {
        const itemToRemove = extrasInGroup[0];
        const listWithItemRemoved = extrasAdded.filter(
          (currExtra) => currExtra.menuItemId !== itemToRemove.menuItemId
        );

        listWithItemRemoved.push(addedExtra);
        setExtrasAdded(listWithItemRemoved);

        //uncheck the ui too
        toggleUI(groupName, addedExtra.menuItemId);
        toggleUI(groupName, itemToRemove.menuItemId);
      }
    }
  };

  const allowCheck = (limitNumber: number, groupName: string) => {
    const extrasInGroup = extrasAdded.filter(
      (extra) => extra.groupName === groupName
    );

    return extrasInGroup.length < limitNumber;
  };

  const addExtra = (
    extra: MenuItemExtra,
    checked: boolean,
    groupName: string
  ) => {
    let extraConverted: OrderItemExtra = {
      menuItemId: extra.id,
      price: +extra.price,
      quantity: 1,
      groupName: groupName,
      extraName: extra.name,
    };

    if (checked) {
      setExtrasAdded([...extrasAdded, extraConverted]);
      toggleUI(groupName, extra.id);
    } else {
      setExtrasAdded(
        extrasAdded.filter(
          (currExtra) => currExtra.menuItemId !== extraConverted.menuItemId
        )
      );
      toggleUI(groupName, extra.id);
    }
  };

  let showError = <React.Fragment />;
  if (quantityError) {
    showError = (
      <SmallParagraph margin="0" color={colors.error}>
        You must select a quantity
      </SmallParagraph>
    );
  }

  useEffect(() => {
    toggleAllExtrasUI(); //clean preemptively in case they use back button

    //loop over each extra menu and check whehter the extra is a default
    //configure the local state to reflect those defaults ONE time.
    let itemsToAdd: Array<OrderItemExtra> = [];
    selectedItem.extrasMenus?.forEach((extrasMenu) => {
      extrasMenu.items.forEach((extra) => {
        if (extra.default) {
          let extraConverted: OrderItemExtra = {
            menuItemId: extra.id,
            price: +extra.price,
            quantity: 1,
            groupName: extrasMenu.name,
            extraName: extra.name,
          };
          toggleUI(extrasMenu.name, extra.id);
          itemsToAdd.push(extraConverted);
        }
      });
    });

    //you can't do this in the loop because of the async nature of set function. items will end up overriding each other during the loop
    setExtrasAdded(itemsToAdd);

    // eslint-disable-next-line
  }, []); //if you pass an empty array it will ensure that useEffect only runs one time. Do not remove.

  let preferencesMarkup = (
    <InstructionsContainer>
      <TextBox
        label="instructions"
        placeholder="Instructions"
        styleType={TextBoxStyleType.Standard}
        onChange={(val) => setPreferences(val.target.value)}
        value={preferences}
      />
    </InstructionsContainer>
  );

  if (!enablePreferences) {
    preferencesMarkup = <React.Fragment />;
  }

  return (
    <PageContainer>
      <Header>
        <OrderItemDetails
          image={selectedItem.itemImage?.url}
          description={selectedItem.description}
          name={selectedItem.name}
          price={selectedItem.price}
        />
        <QuantityCounter
          quantity={quantity}
          decrement={() => {
            if (quantity > 1) setQuantity(quantity - 1);
          }}
          increment={() => setQuantity(quantity + 1)}
        />
        {showError}
      </Header>
      <Main>
        <Extras
          toggleChecked={addExtra}
          allowCheck={allowCheck}
          removeCheckedItem={removeCheckedItem}
          extrasMenus={selectedItem.extrasMenus}
          show={
            selectedItem.hasExtras &&
            selectedItem.extrasMenus &&
            selectedItem.extrasMenus.length > 0
          }
          menuItemId={selectedItem.id}
        />
        {preferencesMarkup}
      </Main>
      <Footer>
        <Button
          label="Add"
          styleType={ButtonStyleType.Secondary}
          onClick={() => {
            toggleAllExtrasUI();
            addItem();
          }}
        />
        <Button
          label="Cancel"
          styleType={ButtonStyleType.Back}
          onClick={() => {
            if (props.toggleModal) {
              toggleAllExtrasUI();
              props.toggleModal();
            }
          }}
        />
      </Footer>
    </PageContainer>
  );
}

export default AddOrderItem;
