import React from 'react';
import styled from 'styled-components';
import ExtraItem from 'components/ExtraItem';
import { Typography } from '@barpay/barpay-design-system';
import { MenuItemExtrasMenu } from 'types/Menu';
const { H5 } = Typography;

interface ContainerProps {
  show: boolean | undefined;
}
export interface ExtrasProps {
  extrasMenus: Array<MenuItemExtrasMenu> | undefined;
  show: boolean | undefined;
  toggleChecked: Function;
  allowCheck: Function;
  removeCheckedItem: Function;
  menuItemId: number;
}

const Container = styled.div<ContainerProps>`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

function Extras(props: ExtrasProps) {
  let extras = [<React.Fragment key={0} />];

  if (props.extrasMenus && props.extrasMenus.length > 0) {
    const extrasMap = props.extrasMenus.map((extraMenu) => {
      let limitNumber = +extraMenu.limit;
      let limitText = '';

      if (!isNaN(limitNumber) && limitNumber > 0) {
        if (limitNumber === 1) {
          limitText = ' (Choose 1)';
        } else {
          limitText = ` (Choose up to ${limitNumber})`;
        }
      } else {
        limitNumber = 0;
      }

      let subMenu = [
        <H5 marginLeft={0} key={extraMenu.name}>
          {extraMenu.name}
          {limitText}
        </H5>,
      ];

      for (const extra of extraMenu.items) {
        subMenu.push(
          <ExtraItem
            id={extra.id}
            allowCheck={() => {
              return props.allowCheck(limitNumber, extraMenu.name);
            }}
            toggleChecked={(checked: boolean) => {
              props.toggleChecked(extra, checked, extraMenu.name);
            }}
            removeCheckedItem={() => {
              return props.removeCheckedItem(
                extraMenu.name,
                extra,
                limitNumber
              );
            }}
            price={extra.price}
            name={extra.name}
            checked={extra.checked}
            key={extra.id}
          />
        );
      }
      return subMenu;
    });

    let t: JSX.Element[] = [];
    extras = t.concat.apply([], extrasMap); //flat is not supported in ios6s
  }
  return <Container show={props.show}>{extras}</Container>;
}

export default Extras;
