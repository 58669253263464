import React from 'react';
import styled from 'styled-components';

import { Typography } from '@barpay/barpay-design-system';

const { LargeLead } = Typography;

export interface ApplicationMenuItemProps {
  name: string;
  handleClick?: Function;
}

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  margin-left: 2.5rem;
  margin-top: 1.5rem;
  cursor: pointer;
`;

function ApplicationMenuItem(props: ApplicationMenuItemProps) {
  return (
    <Container
      onClick={(event) => {
        if (props.handleClick) props.handleClick();
      }}
    >
      <LargeLead>{props.name}</LargeLead>
    </Container>
  );
}

export default ApplicationMenuItem;
