import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MyOrderItemsPrintingError from 'components/MyOrderItemsPrintingError';
import { clearMenu } from 'store/actions';

const { H3, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

function PrintingError() {
  const now = new Date();
  const orderId = useSelector((state: RootState) => state.order.orderId);
  const [step, setStep] = useState(0);
  const [buttonColor, setButtonColor] = useState(colors.accent);
  const [buttonLabel, setButtonLabel] = useState('I Have Taken a Screenshot');
  const [timestamp] = useState(now);
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const order = useSelector((state: RootState) => state.order);

  const nextStep = () => {
    if (step === 0) {
      //turn the button red
      setButtonColor(colors.error);
      setButtonLabel('Are you sure?');
      setStep(1);
    } else if (step === 1) {
      //take them to the next page
      dispatch(clearMenu());
      history.push(`/venue/${id}/menu/receipt` + history.location.search);
    }
  };

  return (
    <PageContainer>
      <Header>
        <H3>Uhh ohh!</H3>
        <H5 fontWeight={400}>
          Your payment is completed but there was a problem while printing the
          order. Please notify your server or a staff member.
        </H5>
      </Header>
      <Main>
        <H3>TAKE A SCREENSHOT!</H3>
        <H5 fontWeight={400}>
          Take a quick screenshot to save the order details. This may be needed
          later.
        </H5>
        <H3 marginTop="2rem">Order Details</H3>
        <H5 margin={0} fontWeight={400}>
          Date: {timestamp.toLocaleString()}
        </H5>
        <H5 margin={0} fontWeight={400}>
          Order#: {orderId}
        </H5>
        <H5 margin={0} fontWeight={400}>
          Venue Id: {id}
        </H5>
      </Main>
      <Footer>
        <Button
          onClick={nextStep}
          bgColorOverride={buttonColor}
          labelOverride={buttonLabel}
          label=""
          styleType={ButtonStyleType.Secondary}
        />

        <MyOrderItemsPrintingError items={order.orderItems} />
      </Footer>
    </PageContainer>
  );
}

export default PrintingError;
