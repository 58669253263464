import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FastPass')
export class FastPass {
  @JsonProperty('quantity', Number)
  quantity: number;

  @JsonProperty('tier', Number)
  tier: number;

  @JsonProperty('price', String)
  price: string;

  @JsonProperty('remaining', Number)
  remaining: number;

  constructor() {
    this.quantity = 0;
    this.tier = 0;
    this.price = '';
    this.remaining = 0;
  }
}

@JsonObject('Venue')
export class Venue {
  @JsonProperty('id', Number)
  venueId: number;
  @JsonProperty('name', String)
  venueName: string;
  @JsonProperty('street_line1', String)
  venueAddress1: string;
  @JsonProperty('street_line2', String)
  venueAddress2: string;
  @JsonProperty('city', String)
  venueCity: string;
  @JsonProperty('state', String)
  venueState: string;
  @JsonProperty('zip_code', String)
  venueZipCode: string;
  @JsonProperty('hero_image', String)
  venueHeroImageUrl: string;
  @JsonProperty('logo_image', String)
  venueLogoImageUrl: string;
  @JsonProperty('food_tax_rate', String)
  foodTaxRate: string;
  @JsonProperty('drinks_tax_rate', String)
  drinksTaxRate: string;
  @JsonProperty('order_ready_in', Number)
  venueOrderReadyIn: number;
  @JsonProperty('optional_percentage_fee', Number)
  optionalPercentageFee: number;
  @JsonProperty('optional_fixed_fee', Number)
  optionalFixedFee: number;
  @JsonProperty('optional_fee_label', String)
  optionalFeeLabel: string;
  @JsonProperty('optional_fee_uuid', String)
  optionalFeeUUID: string;
  @JsonProperty('order_only', Boolean)
  orderOnly: boolean;
  @JsonProperty('opt_out_captcha', Boolean)
  optOutCaptcha: boolean;
  @JsonProperty('drinks_first', Boolean)
  drinksFirst: boolean;
  @JsonProperty('enable_special_instructions', Boolean)
  enableSpecialInstructions: boolean;
  @JsonProperty('bar_currently_open', Boolean)
  barOpen: boolean;
  @JsonProperty('kitchen_currently_open', Boolean)
  kitchenOpen: boolean;
  @JsonProperty('hours', String)
  openCloseHours: string;
  @JsonProperty('enable_qr_code', Boolean)
  enableQrCode: boolean;
  @JsonProperty('enable_busy_bar_warning', Boolean)
  busyBar: boolean;
  @JsonProperty('is_stripe_venue', Boolean)
  isStripeVenue: boolean;

  @JsonProperty('fast_passes', [FastPass])
  fastPasses: FastPass[];

  @JsonProperty('current_fast_pass_tier', Number)
  currentFastPassTier: number;

  @JsonProperty('fast_pass_available', Boolean)
  fastPassAvailable: boolean;

  constructor() {
    this.venueId = 0;
    this.venueName = '';
    this.venueAddress1 = '';
    this.venueAddress2 = '';
    this.venueCity = '';
    this.venueState = '';
    this.venueZipCode = '';
    this.venueHeroImageUrl = '';
    this.venueLogoImageUrl = '';
    this.foodTaxRate = '';
    this.drinksTaxRate = '';
    this.venueOrderReadyIn = 0;
    this.optionalPercentageFee = 0;
    this.optionalFixedFee = 0;
    this.optionalFeeLabel = '';
    this.optionalFeeUUID = '';
    this.orderOnly = false;
    this.optOutCaptcha = false;
    this.drinksFirst = true;
    this.enableSpecialInstructions = true;
    this.barOpen = false;
    this.kitchenOpen = false;
    this.openCloseHours = '';
    this.enableQrCode = false;
    this.fastPasses = [];
    this.currentFastPassTier = 0;
    this.fastPassAvailable = false;
    this.busyBar = false;
    this.isStripeVenue = false;
  }
}
