import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import {
  CountingButton,
  CountingButtonStyleType,
  CountingButtonSizeType,
} from '@barpay/barpay-design-system';

interface FastPassQuantityCounterProps {
  quantity: number;
  increment: Function;
  decrement: Function;
}
const { H1 } = Typography;
const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Article = styled.article`
  margin-top: auto;
  margin-bottom: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  touch-action: manipulation;
`;

function FastPassQuantityCounter(props: FastPassQuantityCounterProps) {
  return (
    <Container>
      <Article>
        <ButtonGroup>
          <CountingButton
            onClick={() => props.decrement()}
            styleType={CountingButtonStyleType.Decrement}
            size={CountingButtonSizeType.Big}
          />
          <H1 margin="auto 1rem" fontSize={50}>
            {props.quantity}
          </H1>
          <CountingButton
            onClick={() => props.increment()}
            styleType={CountingButtonStyleType.Increment}
            size={CountingButtonSizeType.Big}
          />
        </ButtonGroup>
      </Article>
    </Container>
  );
}

export default FastPassQuantityCounter;
