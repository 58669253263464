import {
  OrderState,
  OrderActionTypes,
  GIVE_NAME,
  GIVE_EMAIL,
  SELECT_TABLE,
  SELECT_PHONE,
  TOGGLE_MENU,
  RESET_SELECTED_CATEGORY,
  REQUEST_MENU,
  RECEIVE_MENU,
  REQUEST_VENUE,
  RECEIVE_VENUE,
  RAISE_ERROR,
  FILTER_MENU,
  ADD_MENU_ITEM,
  CLEAR_MENU,
  DELETE_MENU_ITEM,
  EDIT_TIP,
  TOGGLE_TIP,
  OrderItem,
  OrderItemExtra,
  UPDATE_TOTALS,
  REQUEST_STRIPE_INTENT,
  RECEIVE_STRIPE_INTENT_DETAILS,
  REQUEST_BRAINTREE_AUTH,
  RECEIVE_BRAINTREE_AUTH,
  REQUEST_GOOGLE_PAY,
  RECEIVE_GOOGLE_PAY,
  REQUEST_GOOGLE_PAY_MAKE_PAYMENT,
  RECEIVE_GOOGLE_PAY_MAKE_PAYMENT,
  REQUEST_APPLE_PAY,
  RECEIVE_APPLE_PAY,
  REQUEST_APPLE_PAY_MAKE_PAYMENT,
  RECEIVE_APPLE_PAY_MAKE_PAYMENT,
  ORDER_COMPLETED,
  MAKING_MANUAL_PAYMENT,
  MAKING_STRIPE_PAYMENT,
  RECEIVE_STRIPE_MAKE_PAYMENT,
  UPDATE_TOTAL_ONLY,
  ORDER_COMPLETED_WITH_ERROR,
  ORDER_COMPLETED_WITH_PRINTING_ERROR,
  ErrorDetails,
  CLEAR_PAYMENT_ERROR,
  SET_TAKEOUT,
  SET_LOCATION,
  SET_TAB_ID,
  SET_FAST_PASS_ID,
  SET_PICKUP_LOCATION,
  SET_PRINT_QR,
  SET_LOCATION_TYPE,
  SET_RECORD_ACCOUNT_NUMBER,
  SET_ACCOUNT_NUMBER,
  SET_OPT_SF,
  SET_LOCATION_LIMIT,
  SELECT_ITEM,
  REFRESH_MENU,
  NOTIFY_PRICE_CHANGE,
  NOTIFY_BUSY_BAR,
  SELECT_CATEGORY,
  TOGGLE_CATEGORY,
  UNVERIFIED_PHONE_NUMBER,
  VERIFIED_PHONE_NUMBER,
  SELECT_VERIFICATION_CODE,
  INVALID_PHONE_NUMBER,
  ORDER_FAST_PASS,
  DISPLAY_FAST_PASS,
  REQUEST_FAST_PASS,
  RECEIVE_FAST_PASS,
  REDEEMED_FAST_PASS,
  REDEEMING_FAST_PASS,
  CLEAR_FAST_PASS,
  RECEIVE_STRIPE_INTENT,
} from './types';
import { Menu, MenuItem } from 'types/Menu';
import { Venue } from 'types/Venue';

const extrasReducer = (accumulator: number, current: OrderItemExtra) =>
  (accumulator += +current.price);

const totalsReducer = (accumulator: number, current: OrderItem) => {
  const extrasTotal =
    current.extras.reduce(extrasReducer, 0) * current.quantity;
  accumulator += +current.price * current.quantity + extrasTotal;
  return accumulator;
};

// recompute order prices after a menu or schedule change
const updateItems = (currentState: OrderState): OrderItem[] => {
  const foodMenu = currentState.menu.foodMenu;
  const drinksMenu = currentState.menu.drinksMenu;

  const currentOrderItems = currentState.orderItems;
  const updatedOrderItems: OrderItem[] = [];
  const foodMenuItems = foodMenu.categories
    .map((category) => category.sublists.map((sublist) => sublist.items))
    .flat(2);

  const foodExtras = foodMenu.categories
    .map((category) =>
      category.sublists.map((sublist) =>
        sublist.items.map((foodItem) =>
          foodItem.extrasMenus?.map((extraMenu) => extraMenu.items)
        )
      )
    )
    .flat(4);

  const drinkMenuItems = drinksMenu.categories
    .map((category) => category.sublists.map((sublist) => sublist.items))
    .flat(2);
  const drinkExtras = drinksMenu.categories
    .map((category) =>
      category.sublists.map((sublist) =>
        sublist.items.map((drinkItem) =>
          drinkItem.extrasMenus?.map((extraMenu) => extraMenu.items)
        )
      )
    )
    .flat(4);

  for (let i = 0; i < currentOrderItems.length; i++) {
    let orderItem = currentOrderItems[i];
    if (orderItem.menu === 'food') {
      const updatedMenuItem = foodMenuItems.find(
        (menuItem) => menuItem.id === orderItem.menuItemId
      );
      if (updatedMenuItem) {
        orderItem.price = updatedMenuItem.price;
        if (orderItem.extras) {
          for (let j = 0; j < orderItem.extras.length; j++) {
            const updatedExtraItem = foodExtras.find(
              (extra) => extra?.id === orderItem.extras[j].menuItemId
            );
            if (updatedExtraItem) {
              orderItem.extras[j].price = +updatedExtraItem.price;
            }
          }
        }
      }
    } else {
      const updatedMenuItem = drinkMenuItems.find(
        (menuItem) => menuItem.id === orderItem.menuItemId
      );

      if (updatedMenuItem) {
        orderItem.price = updatedMenuItem.price;
        if (orderItem.extras) {
          for (let j = 0; j < orderItem.extras.length; j++) {
            const updatedExtraItem = drinkExtras.find(
              (extra) => extra?.id === orderItem.extras[j].menuItemId
            );
            if (updatedExtraItem) {
              orderItem.extras[j].price = +updatedExtraItem.price;
            }
          }
        }
      }
    }

    updatedOrderItems.push(orderItem);
  }

  return updatedOrderItems;
};

const calculateValues = (order: OrderState) => {
  const orderOnly = order.venue.details.orderOnly;
  const foodSubtotal = order.orderItems
    .filter((item) => item.menu === 'food')
    .reduce(totalsReducer, 0);
  const drinksSubtotal = order.orderItems
    .filter((item) => item.menu === 'drinks')
    .reduce(totalsReducer, 0);
  const subtotal = foodSubtotal + drinksSubtotal;

  const foodTax =
    Math.round((foodSubtotal * order.foodTaxPercent + Number.EPSILON) * 100) /
    100;
  const drinksTax =
    Math.round(
      (drinksSubtotal * order.drinksTaxPercent + Number.EPSILON) * 100
    ) / 100;
  const tax = foodTax + drinksTax;

  let convenienceFee = subtotal * 0.05;
  if (convenienceFee > 1) convenienceFee = 1;
  if (convenienceFee < 0.3) convenienceFee = 0.3;
  if (subtotal === 0) convenienceFee = 0;

  let optionalFee = 0;
  let optionalPercentageFee = order.venue.details.optionalPercentageFee;
  let optionalFixedFee = order.venue.details.optionalFixedFee;
  const optSF = order.optSF;
  const useOptionalFee = optSF === order.venue.details.optionalFeeUUID;

  if (
    !useOptionalFee ||
    (optionalPercentageFee === 0 && optionalFixedFee === 0)
  ) {
    optionalFee = 0;
  } else if (optionalPercentageFee === 0 && optionalFixedFee !== 0) {
    optionalFee = optionalFixedFee;
  } else {
    optionalFee = subtotal * optionalPercentageFee + optionalFixedFee;
  }

  let total = subtotal + +order.tip + tax + convenienceFee + optionalFee;
  if (orderOnly) {
    total = subtotal + tax + optionalFee;
  }

  return [subtotal, tax, total, convenienceFee, optionalFee];
};

export const initialState: OrderState = {
  etag: '',
  takeout: false,
  location: '',
  pickupLocation: '',
  printQr: false,
  recordAccountNumber: false,
  accountNumber: '',
  locationType: '',
  orderId: 0,
  tabId: 0,
  fastPassId: '',
  longQueue: false,
  waitTime: 0,
  name: localStorage.getItem('barpay_web_venue_name') ?? '',
  table: localStorage.getItem('barpay_web_venue_table') ?? '',
  phoneNumber: localStorage.getItem('barpay_web_venue_phone_number') ?? '',
  email: localStorage.getItem('barpay_web_venue_email') ?? '',
  foodTaxPercent: 0.0,
  drinksTaxPercent: 0.0,
  tip: '',
  hasEditedTip: false,
  subtotal: 0,
  tax: 0,
  total: 0,
  convenienceFee: 0,
  editTip: false,
  orderItems: [],
  previousOrderItems: [],
  error: false,
  unverifiedPhoneNumber: false,
  verifiedPhoneNumber:
    localStorage.getItem('barpay_web_verified_phone_number') ?? '',
  invalidPhoneNumber: false,
  fastPassesPrice: 0,
  fastPassesToPurchase: 0,
  fastPassesExpiration: new Date(1990, 1, 1),
  fastPassesRedeemed: false,
  fastPassUUID: '',
  fastPass: {
    isFetching: false,
    isRedeemed: false,
  },
  verificationCode: '',
  paymentError: null,
  paymentSuccess: false,
  stripe: {
    isFetching: false,
    stripeSecret: '',
    stripeIntentId: '',
    stripeAccountId: '',
    stripeAmount: 0,
    makingPayment: false,
  },
  brainTree: {
    isFetching: false,
    braintreeAuth: '',
    braintreeInstance: undefined,
    makingManualPayment: false,
  },
  googlePay: {
    isFetching: false,
    googlePayInstance: undefined,
    isReady: false,
    makingPayment: false,
    googlePayButton: undefined,
  },
  applePay: {
    isFetching: false,
    applePayInstance: undefined,
    isReady: false,
    makingPayment: false,
  },
  selectedCategory: 0,
  showMenu: false,
  venue: {
    isFetching: false,
    details: new Venue(),
  },
  menu: {
    isFetching: false,
    foodMenu: new Menu(),
    drinksMenu: new Menu(),
  },
  filter: '',
  selectedItem: new MenuItem(),
  notifiedPriceChange: false,
  notifiedBusyBar: false,
  optionalFee: 0,
  optSF: '',
  locationLimit: 1000,
  status: '',
};

export function orderReducer(
  state = initialState,
  action: OrderActionTypes
): OrderState {
  switch (action.type) {
    case INVALID_PHONE_NUMBER:
      return { ...state, invalidPhoneNumber: true };
    case SELECT_VERIFICATION_CODE:
      return { ...state, verificationCode: action.code };
    case SET_TAKEOUT:
      return { ...state, takeout: action.takeout };
    case SET_LOCATION:
      return { ...state, location: action.location };
    case SET_TAB_ID:
      return {
        ...state,
        tabId: action.tabId,
      };
    case SET_FAST_PASS_ID:
      return {
        ...state,
        fastPassId: action.fastPassId,
      };
    case SET_PICKUP_LOCATION:
      return { ...state, pickupLocation: action.pickupLocation };
    case SET_PRINT_QR:
      return { ...state, printQr: action.printQr };
    case SET_LOCATION_TYPE:
      return { ...state, locationType: action.locationType };
    case SET_RECORD_ACCOUNT_NUMBER:
      return { ...state, recordAccountNumber: action.recordAccountNumber };
    case SET_ACCOUNT_NUMBER:
      return { ...state, accountNumber: action.accountNumber };
    case SET_OPT_SF:
      return { ...state, optSF: action.optSF };
    case SET_LOCATION_LIMIT:
      return { ...state, locationLimit: action.locationLimit };
    case GIVE_NAME:
      localStorage.setItem('barpay_web_venue_name', action.name);
      return { ...state, name: action.name };
    case SELECT_TABLE:
      localStorage.setItem('barpay_web_venue_table', action.table);
      return { ...state, table: action.table };
    case SELECT_PHONE:
      localStorage.setItem('barpay_web_venue_phone_number', action.phone);
      return { ...state, phoneNumber: action.phone };
    case GIVE_EMAIL:
      localStorage.setItem('barpay_web_venue_email', action.email);
      return { ...state, email: action.email };
    case CLEAR_PAYMENT_ERROR:
      return { ...state, paymentError: null };
    case TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
        selectedCategory: action.selectedCategory,
      };
    case RESET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: 0,
      };
    case TOGGLE_CATEGORY:
      if (state.menu.foodMenu.id === action.menuId) {
        return {
          ...state,
          menu: {
            ...state.menu,
            foodMenu: {
              ...state.menu.foodMenu,
              categories: state.menu.foodMenu.categories.map((item) => {
                if (item.id === action.selectedCategory) {
                  let menuCat = item;
                  menuCat.selected = !item.selected;
                  return menuCat;
                } else {
                  return item;
                }
              }),
            },
          },
        };
      }

      if (state.menu.drinksMenu.id === action.menuId) {
        return {
          ...state,
          menu: {
            ...state.menu,
            drinksMenu: {
              ...state.menu.drinksMenu,
              categories: state.menu.drinksMenu.categories.map((item) => {
                if (item.id === action.selectedCategory) {
                  let menuCat = item;
                  menuCat.selected = !item.selected;
                  return menuCat;
                } else {
                  return item;
                }
              }),
            },
          },
        };
      }

      return {
        ...state,
      };
    case SELECT_CATEGORY:
      if (state.menu.foodMenu.id === action.menuId) {
        return {
          ...state,
          menu: {
            ...state.menu,
            foodMenu: {
              ...state.menu.foodMenu,
              categories: state.menu.foodMenu.categories.map((item) => {
                if (item.id === action.selectedCategory) {
                  let menuCat = item;
                  menuCat.selected = true;
                  return menuCat;
                } else {
                  return item;
                }
              }),
            },
          },
        };
      }

      if (state.menu.drinksMenu.id === action.menuId) {
        return {
          ...state,
          menu: {
            ...state.menu,
            drinksMenu: {
              ...state.menu.drinksMenu,
              categories: state.menu.drinksMenu.categories.map((item) => {
                if (item.id === action.selectedCategory) {
                  let menuCat = item;
                  menuCat.selected = true;
                  return menuCat;
                } else {
                  return item;
                }
              }),
            },
          },
        };
      }

      return {
        ...state,
      };
    case REQUEST_MENU:
      return {
        ...state,
        venue: {
          ...state.venue,
          details: {
            ...state.venue.details,
            venueId: action.venueId,
          },
        },
        menu: {
          isFetching: true,
          foodMenu: new Menu(),
          drinksMenu: new Menu(),
        },
      };
    case REFRESH_MENU:
      return {
        ...state,
        etag: action.etag,
        menu: {
          isFetching: false,
          foodMenu: action.foodMenu,
          drinksMenu: action.drinksMenu,
        },
      };
    case RECEIVE_MENU:
      return {
        ...state,
        etag: action.etag,
        menu: {
          isFetching: false,
          foodMenu: action.foodMenu,
          drinksMenu: action.drinksMenu,
        },
      };
    case RECEIVE_STRIPE_INTENT_DETAILS:
      return {
        ...state,
        status: action.status,
      };
    case REQUEST_STRIPE_INTENT:
      return {
        ...state,
        stripe: {
          isFetching: true,
          stripeSecret: '',
          stripeIntentId: '',
          stripeAccountId: '',
          stripeAmount: 0,
          makingPayment: false,
        },
      };
    case RECEIVE_STRIPE_INTENT:
      let stripeAmount = action.amount;
      if (action.amount < state.stripe.stripeAmount) {
        stripeAmount = state.stripe.stripeAmount;
      }
      return {
        ...state,
        stripe: {
          isFetching: false,
          stripeSecret: action.secret,
          stripeIntentId: action.intentId,
          stripeAccountId: action.accountId,
          stripeAmount: stripeAmount,
          makingPayment: false,
        },
      };
    case REQUEST_BRAINTREE_AUTH:
      return {
        ...state,
        brainTree: {
          isFetching: true,
          braintreeAuth: '',
          braintreeInstance: undefined,
          makingManualPayment: false,
        },
      };
    case RECEIVE_BRAINTREE_AUTH:
      return {
        ...state,
        brainTree: {
          isFetching: false,
          braintreeAuth: action.auth,
          braintreeInstance: action.braintreeInstance,
          makingManualPayment: false,
        },
      };
    case REQUEST_VENUE:
      return {
        ...state,
        venue: {
          ...state.venue,
          isFetching: true,
          details: {
            ...state.venue.details,
            venueId: action.venueId,
          },
        },
      };
    case RECEIVE_VENUE:
      let foodTaxPercent = 0;
      let drinksTaxPercent = 0;

      let foodTryToConvert = parseFloat(action.venue.foodTaxRate);
      let drinksTryToConvert = parseFloat(action.venue.drinksTaxRate);
      if (!isNaN(foodTryToConvert)) {
        foodTaxPercent = foodTryToConvert;
      }
      if (!isNaN(drinksTryToConvert)) {
        drinksTaxPercent = drinksTryToConvert;
      }

      return {
        ...state,
        foodTaxPercent: foodTaxPercent,
        drinksTaxPercent: drinksTaxPercent,
        venue: {
          isFetching: false,
          details: {
            ...state.venue.details,
            venueName: action.venue.venueName,
            venueAddress1: action.venue.venueAddress1,
            venueAddress2: action.venue.venueAddress2,
            venueCity: action.venue.venueCity,
            venueState: action.venue.venueState,
            venueZipCode: action.venue.venueZipCode,
            venueHeroImageUrl: action.venue.venueHeroImageUrl,
            venueLogoImageUrl: action.venue.venueLogoImageUrl,
            venueId: state.venue.details.venueId,
            venueOrderReadyIn: action.venue.venueOrderReadyIn,
            optionalPercentageFee: action.venue.optionalPercentageFee,
            optionalFixedFee: action.venue.optionalFixedFee,
            optionalFeeLabel: action.venue.optionalFeeLabel,
            optionalFeeUUID: action.venue.optionalFeeUUID,
            orderOnly: action.venue.orderOnly,
            optOutCaptcha: action.venue.optOutCaptcha,
            drinksFirst: action.venue.drinksFirst,
            enableSpecialInstructions: action.venue.enableSpecialInstructions,
            barOpen: action.venue.barOpen,
            kitchenOpen: action.venue.kitchenOpen,
            openCloseHours: action.venue.openCloseHours,
            enableQrCode: action.venue.enableQrCode,
            busyBar: action.venue.busyBar,
            currentFastPassTier: action.venue.currentFastPassTier,
            fastPassAvailable: action.venue.fastPassAvailable,
            fastPasses: action.venue.fastPasses,
            isStripeVenue: action.venue.isStripeVenue,
          },
        },
      };
    case RAISE_ERROR:
      return {
        ...state,
        error: true,
      };
    case UNVERIFIED_PHONE_NUMBER:
      return {
        ...state,
        unverifiedPhoneNumber: true,
      };
    case VERIFIED_PHONE_NUMBER:
      localStorage.setItem(
        'barpay_web_verified_phone_number',
        action.verifiedPhoneNumber
      );
      return {
        ...state,
        verifiedPhoneNumber: action.verifiedPhoneNumber,
      };
    case FILTER_MENU:
      return {
        ...state,
        filter: action.filter,
      };
    case ADD_MENU_ITEM:
      return {
        ...state,
        orderItems: [...state.orderItems, action.item],
      };
    case DELETE_MENU_ITEM:
      state.orderItems.splice(action.index, 1); //changes the array in place. removed item can be ignored
      return {
        ...state,
        orderItems: state.orderItems,
      };
    case CLEAR_MENU:
      return {
        ...state,
        orderItems: [],
        hasEditedTip: false,
      };
    case TOGGLE_TIP:
      return {
        ...state,
        editTip: action.turnOn,
        hasEditedTip:
          action.hasEditedTip !== undefined
            ? action.hasEditedTip.valueOf()
            : state.hasEditedTip || action.turnOn,
      };
    case EDIT_TIP:
      return {
        ...state,
        tip: action.tip,
      };
    case UPDATE_TOTAL_ONLY:
      const totalVal =
        state.subtotal + +state.tip + state.tax + state.convenienceFee;
      return {
        ...state,
        total: totalVal,
        stripe: {
          ...state.stripe,
          stripeAmount: totalVal * 100,
        },
      };
    case UPDATE_TOTALS:
      const [subtotal, tax, total, convenienceFee, optionalFee] =
        calculateValues(state);
      //if they are editing the tip, then just return the current state, otherwise calculate
      let tipPercentage = 0.2;
      if (state.takeout) {
        tipPercentage = 0.0;
      }

      const tip = (
        Math.round((subtotal * tipPercentage + Number.EPSILON) * 100) / 100
      ).toString(); //calculate tip at 20%, but only update the state if they have not edited the tip before

      if (state.hasEditedTip) {
        return {
          ...state,
          subtotal: subtotal,
          tax: tax,
          total: total,
          convenienceFee: convenienceFee,
          optionalFee: optionalFee,
          stripe: {
            ...state.stripe,
            stripeAmount: total * 100,
          },
        };
      } else {
        return {
          ...state,
          subtotal: subtotal,
          tax: tax,
          total: total,
          tip: tip,
          convenienceFee: convenienceFee,
          optionalFee: optionalFee,
          stripe: {
            ...state.stripe,
            stripeAmount: total * 100,
          },
        };
      }
    case REQUEST_GOOGLE_PAY:
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isFetching: true,
        },
      };
    case RECEIVE_GOOGLE_PAY:
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isFetching: false,
          googlePayInstance: action.googlePayInstance,
          isReady: action.isReady,
          googlePayButton: action.googlePayButton,
        },
      };
    case REQUEST_GOOGLE_PAY_MAKE_PAYMENT:
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isFetching: true,
          makingPayment: true,
        },
      };
    case RECEIVE_GOOGLE_PAY_MAKE_PAYMENT:
      if (action.result instanceof ErrorDetails) {
        return {
          ...state,
          orderId: action.result.orderId as number,
          tabId: action.result.tabId as number,
          paymentError: action.result as ErrorDetails,
          paymentSuccess: false,
          googlePay: {
            ...state.googlePay,
            makingPayment: false,
            isFetching: false,
          },
        };
      } else {
        if ('orderId' in action.result) {
          return {
            ...state,
            orderId: action.result.orderId as number,
            tabId: action.result.tabId as number,
            longQueue: action.result.longQueue as boolean,
            waitTime: action.result.waitTime as number,
            paymentError: null,
            paymentSuccess: true,
            googlePay: {
              ...state.googlePay,
              makingPayment: false,
              isFetching: false,
            },
          };
        } else {
          // this is a fast pass
          return {
            ...state,
            orderId: action.result.id as number,
            fastPassUUID: action.result.uuid,
            paymentError: null,
            paymentSuccess: true,
            googlePay: {
              ...state.googlePay,
              makingPayment: false,
              isFetching: false,
            },
          };
        }
      }
    case RECEIVE_STRIPE_MAKE_PAYMENT:
      if (action.result instanceof ErrorDetails) {
        return {
          ...state,
          orderId: action.result.orderId as number,
          tabId: action.result.tabId as number,
          paymentError: action.result as ErrorDetails,
          paymentSuccess: false,
          stripe: {
            ...state.stripe,
            makingPayment: false,
            isFetching: false,
          },
        };
      } else {
        if ('orderId' in action.result) {
          return {
            ...state,
            orderId: action.result.orderId as number,
            tabId: action.result.tabId as number,
            longQueue: action.result.longQueue as boolean,
            waitTime: action.result.waitTime as number,
            paymentError: null,
            paymentSuccess: true,
            stripe: {
              ...state.stripe,
              makingPayment: false,
              isFetching: false,
            },
          };
        } else {
          // this is a fast pass
          return {
            ...state,
            orderId: action.result.id as number,
            fastPassUUID: action.result.uuid,
            paymentError: null,
            paymentSuccess: true,
            stripe: {
              ...state.stripe,
              makingPayment: false,
              isFetching: false,
            },
          };
        }
      }
    case REQUEST_APPLE_PAY:
      return {
        ...state,
        applePay: {
          ...state.applePay,
          isFetching: true,
        },
      };
    case RECEIVE_APPLE_PAY:
      return {
        ...state,
        applePay: {
          ...state.applePay,
          isFetching: false,
          applePayInstance: action.applePayInstance,
          isReady: action.isReady,
        },
      };
    case REQUEST_APPLE_PAY_MAKE_PAYMENT:
      return {
        ...state,
        applePay: {
          ...state.applePay,
          makingPayment: true,
          isFetching: true,
        },
      };
    case RECEIVE_APPLE_PAY_MAKE_PAYMENT:
      if (action.result instanceof ErrorDetails) {
        return {
          ...state,
          orderId: action.result.orderId as number,
          tabId: action.result.tabId as number,
          paymentError: action.result as ErrorDetails,
          paymentSuccess: false,
          applePay: {
            ...state.applePay,
            makingPayment: false,
            isFetching: false,
          },
        };
      } else {
        if ('orderId' in action.result) {
          return {
            ...state,
            orderId: action.result.orderId as number,
            tabId: action.result.tabId as number,
            paymentError: null,
            paymentSuccess: true,
            applePay: {
              ...state.applePay,
              makingPayment: false,
              isFetching: false,
            },
          };
        } else {
          return {
            ...state,
            orderId: action.result.id as number,
            fastPassUUID: action.result.uuid,
            paymentError: null,
            paymentSuccess: true,
            applePay: {
              ...state.applePay,
              makingPayment: false,
              isFetching: false,
            },
          };
        }
      }
    case ORDER_COMPLETED_WITH_PRINTING_ERROR:
      return {
        ...state,
        hasEditedTip: false,
        paymentSuccess: false,
        paymentError: null,
        brainTree: {
          ...state.brainTree,
          makingManualPayment: false,
        },
        stripe: {
          isFetching: false,
          stripeSecret: '',
          stripeIntentId: '',
          stripeAccountId: '',
          stripeAmount: 0,
          makingPayment: false,
        },
      };
    case ORDER_COMPLETED:
      return {
        ...state,
        fastPassesPrice: 0,
        fastPassesToPurchase: 0,
        orderItems: [],
        previousOrderItems: state.orderItems,
        hasEditedTip: false,
        paymentSuccess: false,
        paymentError: null,
        brainTree: {
          ...state.brainTree,
          makingManualPayment: false,
        },
        stripe: {
          isFetching: false,
          stripeSecret: '',
          stripeIntentId: '',
          stripeAccountId: '',
          stripeAmount: 0,
          makingPayment: false,
        },
      };
    case ORDER_COMPLETED_WITH_ERROR:
      return {
        ...state,
        hasEditedTip: false,
        paymentError: state.paymentError,
        paymentSuccess: false,
        brainTree: {
          ...state.brainTree,
          makingManualPayment: false,
        },
        stripe: {
          isFetching: false,
          stripeSecret: '',
          stripeIntentId: '',
          stripeAccountId: '',
          stripeAmount: 0,
          makingPayment: false,
        },
      };
    case MAKING_MANUAL_PAYMENT:
      return {
        ...state,
        brainTree: {
          ...state.brainTree,
          makingManualPayment: true,
        },
      };
    case MAKING_STRIPE_PAYMENT:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          makingPayment: true,
        },
      };
    case SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.item,
      };
    case NOTIFY_PRICE_CHANGE:
      const updatedItems = updateItems(state);
      return {
        ...state,
        orderItems: updatedItems,
        notifiedPriceChange: true,
      };
    case NOTIFY_BUSY_BAR:
      return {
        ...state,
        notifiedBusyBar: true,
      };
    case ORDER_FAST_PASS:
      return {
        ...state,
        fastPassesPrice: action.fastPassesPrice,
        fastPassesToPurchase: action.fastPassesToPurchase,
        fastPassStripeTemporary: action.fastPassStripeTemporary,
      };
    case REQUEST_FAST_PASS:
      return {
        ...state,
        fastPass: {
          isFetching: true,
          isRedeemed: false,
        },
      };
    case RECEIVE_FAST_PASS:
      return {
        ...state,
        fastPassUUID: action.uuid,
        fastPassesToPurchase: action.quantity,
        fastPassesExpiration: action.expiration,
        fastPassesRedeemed: action.redeemed,
        fastPass: {
          isFetching: false,
          isRedeemed: false,
        },
      };
    case CLEAR_FAST_PASS:
      return {
        ...state,
        fastPassesRedeemed: false,
      };
    case DISPLAY_FAST_PASS:
      return {
        ...state,
        fastPassUUID: action.uuid,
      };
    case REDEEMING_FAST_PASS:
      return {
        ...state,
        fastPass: {
          isFetching: true,
          isRedeemed: false,
        },
      };
    case REDEEMED_FAST_PASS:
      return {
        ...state,
        fastPass: {
          isFetching: false,
          isRedeemed: true,
        },
      };
    default:
      return state;
  }
}
