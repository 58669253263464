import React from 'react';
import styled from 'styled-components';
import errorImage from '@barpay/barpay-design-system/lib/assets/images/ErrorImage.png';
import { Typography } from '@barpay/barpay-design-system';
import { device } from '@barpay/barpay-design-system';
import { MenuItem } from 'types/Menu';
import { useHistory } from 'react-router-dom';
import { Link } from '@barpay/barpay-design-system';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { selectItem } from 'store/actions';
import { containsLink, parseUrl } from 'util/urlUtils';
const { LargeLead, SmallLead } = Typography;

export interface VenueMenuItemProps {
  item: MenuItem;
}

interface MenuItemLogo {
  url: string;
}

const VenueMenuItemLogo = styled.div<MenuItemLogo>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const VenueMenuItemLogoImg = styled.img`
  @media ${device.mobileS} {
    width: 80px;
    height: 80px;
  }
  @media ${device.mobileL} {
    width: 96px;
    height: 96px;
  }

  @media ${device.tablet} {
    width: 120px;
    height: 120px;
  }

  visibility: hidden; /*this is a hack to get the size of the div to be the same as the image*/
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled.article``;

const RightSide = styled.article`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  align-items: center;
`;

function VenueMenuItem(props: VenueMenuItemProps) {
  const linkConvertText = 'More Info ';
  const history = useHistory();
  const dispatch = useDispatch();
  const { venueId } = useSelector(
    (state: RootState) => state.order.venue.details
  );
  //@ts-ignore
  const addDefaultSrc = (ev) => {
    ev.target.src = errorImage;
  };

  const shorten = (desc: string, max: number) => {
    if (desc && desc.length > max) return desc.substring(0, max) + '...';
    else return desc;
  };

  let descriptionComponent = (
    <SmallLead>{shorten(props.item.description, 100)}</SmallLead>
  );

  if (containsLink(props.item.description)) {
    const { firstPart, url, secondPart } = parseUrl(props.item.description);
    descriptionComponent = (
      <SmallLead>
        {firstPart}
        <Link
          label={linkConvertText}
          onClick={(event: any) => {
            event.stopPropagation();
            window.open(url);
          }}
        />
        {shorten(secondPart, 100 - (firstPart.length + linkConvertText.length))}
      </SmallLead>
    );
  }

  let menuItem = <Container></Container>;

  if (containsLink(props.item.link)) {
    const link = props.item.link;

    menuItem = (
      <Container
        onClick={(event: any) => {
          event.stopPropagation();
          window.open(link);
        }}
      >
        <LeftSide>
          <LargeLead>{props.item.name}</LargeLead>
          {descriptionComponent}
        </LeftSide>
        <RightSide>
          <VenueMenuItemLogo url={props.item.itemImage?.url ?? errorImage}>
            <VenueMenuItemLogoImg
              src={props.item.itemImage?.url ?? errorImage}
              onError={addDefaultSrc}
            />
          </VenueMenuItemLogo>
        </RightSide>
      </Container>
    );
  } else {
    menuItem = (
      <Container
        onClick={() => {
          dispatch(selectItem(props.item));
          history.push(
            `/venue/${venueId}/menu/item-details` + history.location.search
          );
        }}
      >
        <LeftSide>
          <LargeLead>{props.item.name}</LargeLead>
          {descriptionComponent}
        </LeftSide>
        <RightSide>
          <VenueMenuItemLogo url={props.item.itemImage?.url ?? errorImage}>
            <VenueMenuItemLogoImg
              src={props.item.itemImage?.url ?? errorImage}
              onError={addDefaultSrc}
            />
          </VenueMenuItemLogo>
        </RightSide>
      </Container>
    );
  }

  return menuItem;
}

export default VenueMenuItem;
