import { useState } from 'react';
import { useSelector, DefaultRootState } from 'react-redux';

function UseValidateNumericField<
  TState = DefaultRootState,
  TSelected = unknown
>(
  selector: (state: TState) => TSelected,
  fieldName: string
): [boolean, string, () => void] {
  const field = useSelector(selector);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  let regexpNumber: RegExp = /^\$?[0-9]+(\.[0-9]{1,2})?$/;

  const validate = () => {
    if (!field || !regexpNumber.test((field as unknown) as string)) {
      setHasError(true);
      setErrorMessage(`You must enter a valid ${fieldName}`);
    } else {
      setHasError(false);
      setErrorMessage('');
    }
  };

  return [hasError, errorMessage, validate];
}

export default UseValidateNumericField;
