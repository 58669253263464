import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch } from 'react-redux';
import { clearPaymentError } from 'store/actions';
const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

function BarClosedError() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  return (
    <PageContainer>
      <Header></Header>
      <Main>
        <H2>Uhh ohh!</H2>
        <H5 fontWeight={400}>
          Your order wasn't placed because the bar/kitchen is closed.
        </H5>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            dispatch(clearPaymentError());
            history.push(
              `/venue/${id}/menu/my-order` + history.location.search
            );
          }}
          label="Go Back"
          styleType={ButtonStyleType.Primary}
        />
      </Footer>
    </PageContainer>
  );
}

export default BarClosedError;
