import React from 'react';
import styled from 'styled-components';
import {
  CountingButtonSizeType,
  Typography,
} from '@barpay/barpay-design-system';
import {
  CountingButton,
  CountingButtonStyleType,
} from '@barpay/barpay-design-system';

interface QuantityCounterProps {
  quantity: number;
  increment: Function;
  decrement: Function;
}
const { H4 } = Typography;
const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Article = styled.article`
  margin-top: auto;
  margin-bottom: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  touch-action: manipulation;
`;

function QuantityCounter(props: QuantityCounterProps) {
  return (
    <Container>
      <Article>
        <H4 fontWeight={400} margin="1rem 0">
          How many?
        </H4>
      </Article>
      <Article>
        <ButtonGroup>
          <CountingButton
            onClick={() => props.decrement()}
            styleType={CountingButtonStyleType.Decrement}
            size={CountingButtonSizeType.Small}
          />
          <H4 margin="auto 1rem">{props.quantity}</H4>
          <CountingButton
            onClick={() => props.increment()}
            styleType={CountingButtonStyleType.Increment}
            size={CountingButtonSizeType.Small}
          />
        </ButtonGroup>
      </Article>
    </Container>
  );
}

export default QuantityCounter;
