import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';

const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

function CartCleared() {
  const history = useHistory();
  let { id } = useParams();

  return (
    <PageContainer>
      <Header></Header>
      <Main>
        <H2>Uhh ohh!</H2>
        <H5 fontWeight={400}>
          Heads up! It looks like the menu has been updated and the prices in
          your cart have been updated to reflect any new prices. Please verify
          any additional tip you may have added.
        </H5>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            history.push(`/venue/${id}/menu` + history.location.search);
          }}
          label="Go Back"
          styleType={ButtonStyleType.Primary}
        />
      </Footer>
    </PageContainer>
  );
}

export default CartCleared;
