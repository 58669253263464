import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';

const { H2, H5, SmallParagraph } = Typography;

interface UserInformationProps {
  question?: string;
  details?: string;
  value: string;
  handleChange: Function;
  hasError?: boolean;
  errorMessage?: string;
  placeHolder?: string;
}

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
  text-align: center;
`;

const TextBoxContainer = styled.div`
  width: 80%;
  margin: auto;
`;

function UserInformation(props: UserInformationProps) {
  return (
    <Container>
      {props.question === null ? '' : <H2>{props.question}</H2>}
      {props.details === null ? '' : <H5 fontWeight={400}>{props.details}</H5>}
      <TextBoxContainer>
        <TextBox
          label="pedigree"
          onChange={(event) => props.handleChange(event.target.value)}
          value={props.value}
          styleType={TextBoxStyleType.Big}
          hasError={props.hasError}
          placeholder={props.placeHolder}
        />
      </TextBoxContainer>
      <SmallParagraph margin="0" color={colors.error}>
        {props.errorMessage}
      </SmallParagraph>
    </Container>
  );
}

export default UserInformation;
