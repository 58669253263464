import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType, colors } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { Modal } from '@barpay/barpay-design-system';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { thunkGetFastPass } from 'store/thunks';
import { RootState } from 'store';
import LoadingPage from './LoadingPage';
import FastPassModal from './FastPassModal';

const { SmallParagraph, H1, H2 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Main = styled.main`
  height: 40%;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
`;

const Footer = styled.footer`
  width: 100%;
`;

function FastPassOrderConfirmed() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id, uuid } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const isFetching = useSelector(
    (state: RootState) => state.order.fastPass.isFetching
  );

  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );

  const fastPassesRedeemed = useSelector(
    (state: RootState) => state.order.fastPassesRedeemed
  );

  let loading = <React.Fragment />;
  if (isFetching) {
    loading = <LoadingPage show={isFetching} />;
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalContent = <FastPassModal onClick={closeModal} />;

  useEffect(() => {
    if (!uuid || uuid.length !== 36) {
      history.push(`/venue/${id}/fastpass`);
    } else if (fastPassesRedeemed) {
      history.push(
        `/venue/${id}/fastpasserror?error=Your fast pass has already been redeemed.`
      );
    } else {
      dispatch(thunkGetFastPass(id, uuid));
    }
  }, [id, dispatch, uuid, history, fastPassesRedeemed]);

  return (
    <PageContainer>
      {loading}
      <Main>
        <Modal content={modalContent} isOpen={isModalOpen} />
        <H2 marginTop={75}>Order Confirmation</H2>
        <SmallParagraph fontSize={15}>
          You are almost in! Please show this screen to the person at the door.
        </SmallParagraph>
        <H2 marginTop={20}>Valid For:</H2>
        <H1 margin={2} color={colors.success} fontSize={'60px'}>
          {fastPassesToPurchase}
        </H1>
        <H2 margin={2}>{fastPassesToPurchase === 1 ? 'Guest' : 'Guests'}</H2>
        <SmallParagraph marginTop={'25px'}>
          {fastPassesToPurchase === 1
            ? 'Your pass will remain valid until it is scanned. '
            : 'Your passes will remain valid until they are scanned. '}
          If you exit this screen, you can retrieve your passes via the text
          message you were sent.
        </SmallParagraph>
      </Main>
      <Footer>
        <Button
          label={`Scan Now`}
          onClick={async () => {
            history.push(`/venue/${id}/fastpassredeem/${uuid}`);
          }}
          styleType={ButtonStyleType.Secondary}
        />
      </Footer>
    </PageContainer>
  );
}

export default FastPassOrderConfirmed;
