import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonStyleType,
  TextBox,
  TextBoxStyleType,
} from '@barpay/barpay-design-system';
import UserInformation from 'components/UserInformation';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  giveName,
  setTakeout,
  setLocation,
  setPickupLocation,
  setLocationType,
  setLocationLimit,
  selectTable,
  setOptSF,
  selectPhone,
  selectVerificationCode,
  setPrintQr,
  setRecordAccountNumber,
  setAccountNumber,
  clearMenu,
} from 'store/actions';
import { RootState } from 'store';
import AppHeader from 'components/AppHeader';
import useVenueInformation from 'hooks/useVenueInformation';
import useValidateField from 'hooks/useValidateField';
import { AppContent } from '@barpay/barpay-design-system';
import { useLocation } from 'react-router-dom';
import { Typography } from '@barpay/barpay-design-system';
import { useParams, useHistory } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  thunkVerifyPhoneNumber,
  thunkVerifyCode,
  thunkStripeGetPaymentIntentDetails,
} from 'store/thunks';

const { H2, H5, SmallParagraph } = Typography;

interface HomeProps {
  name: string;
}
const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Container = styled.div``;
const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-family: ${(props) => props.theme.fonts.main};
  cursor: pointer;
  padding-left: 5px;
  text-decoration: none;
`;

const TextBoxContainer = styled.div`
  margin: auto;
`;

function Home() {
  const history = useHistory();
  let { id } = useParams();
  let queryParams = new URLSearchParams(useLocation().search);
  let takeout = queryParams.get('takeout');
  let location = queryParams.get('location');
  let pickupLocation = queryParams.get('pickupLocation');
  let printQr = queryParams.get('printQR');
  let recordAccountNumber = queryParams.get('recordAccountNumber');
  let optSF = queryParams.get('optSF');
  let locationLimit = parseInt(
    queryParams.get('locationInputLimit') || '1000',
    10
  );
  let locationType = queryParams.get('locationType');
  let paymentIntent = queryParams.get('payment_intent');

  const takeoutSaved = useSelector((state: RootState) => state.order.takeout);
  const unverifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.unverifiedPhoneNumber
  );
  const verifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.verifiedPhoneNumber
  );
  const invalidPhoneNumber = useSelector(
    (state: RootState) => state.order.invalidPhoneNumber
  );
  const locationSaved = useSelector((state: RootState) => state.order.location);
  const pickupLocationSaved = useSelector(
    (state: RootState) => state.order.pickupLocation
  );
  const printQrSaved = useSelector((state: RootState) => state.order.printQr);
  const locationTypeSaved = useSelector(
    (state: RootState) => state.order.locationType
  );
  const recordAccountNumberSaved = useSelector(
    (state: RootState) => state.order.recordAccountNumber
  );
  const optSFSaved = useSelector((state: RootState) => state.order.optSF);
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );

  const paymentIntentStatus = useSelector(
    (state: RootState) => state.order.status
  );

  if (takeoutSaved) {
    takeout = takeoutSaved.toString();
  }
  if (locationSaved) {
    location = locationSaved;
  }
  if (pickupLocationSaved) {
    pickupLocation = pickupLocationSaved;
  }
  if (printQrSaved) {
    printQr = printQrSaved.toString();
  }
  if (locationTypeSaved) {
    locationType = locationTypeSaved;
  }
  if (recordAccountNumberSaved) {
    recordAccountNumber = recordAccountNumberSaved.toString();
  }
  if (optSFSaved) {
    optSF = optSFSaved;
  }

  const name = useSelector((state: RootState) => state.order.name);
  const dispatch = useDispatch();

  useVenueInformation();
  dispatch(setTakeout(takeout === 'true'));
  dispatch(setLocation(location ?? ''));
  dispatch(setPickupLocation(pickupLocation ?? ''));
  dispatch(setPrintQr(printQr === 'true'));
  dispatch(setLocationType(locationType ?? ''));
  dispatch(setRecordAccountNumber(recordAccountNumber === 'true'));
  dispatch(setLocationLimit(locationLimit ?? ''));
  dispatch(setOptSF(optSF ?? ''));

  let table = useSelector((state: RootState) => state.order.table);
  let phoneNumber = useSelector((state: RootState) => state.order.phoneNumber);
  let accountNumber = useSelector(
    (state: RootState) => state.order.accountNumber
  );
  let verificationCode = useSelector(
    (state: RootState) => state.order.verificationCode
  );
  dispatch(selectTable(table));
  dispatch(selectPhone(phoneNumber));
  dispatch(setAccountNumber(accountNumber));

  if (takeout === 'true' && !printQr) dispatch(selectTable('takeout'));
  if (printQr) dispatch(selectTable('qrcode'));
  if ((!takeout || takeout === 'false') && table === 'takeout')
    dispatch(selectTable(''));
  if (
    (!takeout || takeout === 'false') &&
    !printQr &&
    location &&
    location?.length > 0
  ) {
    dispatch(selectTable(location));
  }

  if (
    locationType === null ||
    locationType === undefined ||
    locationType === ''
  ) {
    locationType = 'table';
  }

  let [nameHasError, nameErrorMessage, validateName] = useValidateField(
    (state: RootState) => state.order.name,
    'name'
  );

  useEffect(() => {
    if (paymentIntent && !paymentIntentStatus) {
      dispatch(thunkStripeGetPaymentIntentDetails(paymentIntent));
    }
  }, []);

  if (paymentIntentStatus === 'succeeded') {
    dispatch(clearMenu());
    history.push(`/venue/${id}/menu/receipt` + history.location.search);
  }

  let [tableHasError, tableErrorMessage, validateTable] = useValidateField(
    (state: RootState) => state.order.table,
    `${locationType}`,
    undefined,
    locationLimit
  );

  let [phoneHasError, phoneErrorMessage, validatePhone] = useValidateField(
    (state: RootState) => state.order.phoneNumber,
    `phone number`,
    undefined,
    undefined,
    (value) => {
      const isValid = isValidPhoneNumber(value, 'US');
      return {
        isValid,
        errorMessage: `Please enter a valid phone number`,
      };
    }
  );

  let [
    accountNumberHasError,
    accountNumberErrorMessage,
    validateAccountNumber,
  ] = useValidateField(
    (state: RootState) => state.order.accountNumber,
    'valid account number'
  );

  let question = `Full name and ${locationType} number?`;
  if (takeout === 'true' || location !== null) {
    question = `What is your full name?`;
  }
  if (printQr) {
    question = `What is your full name and phone number?`;
  }
  let firstTextBox = (
    <UserInformation
      question={question}
      details="(So we know who the order is for...)"
      value={name}
      hasError={nameHasError}
      errorMessage={nameErrorMessage}
      handleChange={(val: string) => dispatch(giveName(val))}
      placeHolder={'Full Name'}
    />
  );

  let secondTextBox = (
    <UserInformation
      value={table}
      hasError={tableHasError}
      errorMessage={tableErrorMessage}
      handleChange={(val: string) => dispatch(selectTable(val))}
      placeHolder={`${
        locationType.charAt(0).toUpperCase() + locationType.slice(1)
      } Number`}
    />
  );

  let thirdTextBox = (
    <UserInformation
      value={accountNumber}
      hasError={accountNumberHasError}
      errorMessage={accountNumberErrorMessage}
      handleChange={(val: string) => dispatch(setAccountNumber(val))}
      placeHolder={'Account Number'}
    />
  );

  let phoneVerificationBox = (
    <TextBoxContainer>
      <H2 textAlign="center" fontSize="23px">
        Verify Phone Number
      </H2>
      <H5 textAlign="center">
        Please enter the verification code we just sent you.
      </H5>
    </TextBoxContainer>
  );
  let verifyPhoneNumber = <React.Fragment />;
  let button = (
    <Button
      label="Continue"
      onClick={async () => {
        let hasNameError = await validateName();
        let hasTableError = !printQr && (await validateTable());
        let hasPhoneError = printQr && (await validatePhone());
        let hasAccountNumberError =
          recordAccountNumber === 'true' && (await validateAccountNumber());
        if (takeout === 'true' && !printQr && !hasNameError) {
          history.push(`/venue/${id}/menu` + history.location.search);
        }

        if (
          (takeout === null || (takeout === 'true' && printQr)) &&
          !hasNameError &&
          !hasTableError &&
          !hasPhoneError &&
          !hasAccountNumberError
        ) {
          history.push(`/venue/${id}/menu` + history.location.search);
        }

        //@ts-ignore
        window.clevertap.event.push('First Name Entered', {
          'First Name': name,
          Takeout: takeout === 'true',
          Location: location,
          LocationType: locationType,
          venueId: venueId,
        });
      }}
      styleType={ButtonStyleType.Secondary}
    />
  );

  if (takeout === 'true' || location !== null) {
    secondTextBox = <React.Fragment />;
  }
  if (printQr) {
    secondTextBox = (
      <UserInformation
        value={phoneNumber}
        hasError={phoneHasError}
        errorMessage={phoneErrorMessage}
        handleChange={(val: string) => dispatch(selectPhone(val))}
        placeHolder={`Phone Number`}
      />
    );
    button = (
      <Button
        label={unverifiedPhoneNumber ? 'Submit Verification Code' : 'Next'}
        onClick={async () => {
          let hasPhoneError = printQr && (await validatePhone());
          if (!hasPhoneError) {
            if (verifiedPhoneNumber === phoneNumber) {
              history.push(`/venue/${id}/menu` + history.location.search);
            } else if (unverifiedPhoneNumber) {
              dispatch(thunkVerifyCode(phoneNumber, verificationCode));
            } else {
              dispatch(thunkVerifyPhoneNumber(phoneNumber));
            }
          }
        }}
        styleType={ButtonStyleType.Secondary}
      />
    );
    verifyPhoneNumber = (
      <TextBoxContainer>
        <TextBox
          label="phoneNumberVerification"
          onChange={(event) => {
            dispatch(selectVerificationCode(event.target.value));
          }}
          value={verificationCode}
          styleType={TextBoxStyleType.Big}
          placeholder="Enter Verification Code"
        />
      </TextBoxContainer>
    );
  }
  return (
    <React.Fragment>
      <AppHeader />
      <AppContent>
        <PageContainer>
          <Container>
            {unverifiedPhoneNumber ? phoneVerificationBox : firstTextBox}
            {unverifiedPhoneNumber ? '' : secondTextBox}
            {unverifiedPhoneNumber ? verifyPhoneNumber : ''}
            {recordAccountNumber === 'true' ? thirdTextBox : ''}
          </Container>
          <Container>
            {button}
            {invalidPhoneNumber ? (
              <SmallParagraph
                color={colors.error}
                margin="auto"
                textAlign="center"
              >
                There is an issue verifying this phone number
              </SmallParagraph>
            ) : (
              ''
            )}
          </Container>
          <Container>
            <SmallParagraph>
              By entering a name and clicking continue, you are agreeing to
              Barpay's
              <StyledLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.barpay.com/terms-of-service"
              >
                terms of service.
              </StyledLink>
            </SmallParagraph>
          </Container>
        </PageContainer>
      </AppContent>
    </React.Fragment>
  );
}

export default Home;
