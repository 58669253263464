import React from 'react';
import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';
import appleBadge from '@barpay/barpay-design-system/lib/assets/images/AppleBadge.svg';
import googleBadge from '@barpay/barpay-design-system/lib/assets/images/google-play-badge.png';
import barpayBadge from '@barpay/barpay-design-system/lib/assets/images/BarpayBadge.png';
import ApplicationMenuItem from './ApplicationMenuItem';
import { Hamburger } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from 'store/actions';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
const { H5 } = Typography;

export interface SidebarProps {
  children?: React.ReactNode;
  show: boolean;
}

export interface StyledContainerProps {
  show: boolean;
}

const AppleBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  cursor: pointer;
`;
const GoogleBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  cursor: pointer;
`;
const BarpayBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
`;

const ContentContainer = styled.div`
  position: relative;
  height: 100%;
`;

const BadgeContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const SideBarContainer = styled.div<StyledContainerProps>`
  background-color: ${colors.background};
  height: 100%;
  width: 60%;
  max-width: 400px;
  opacity: ${(props) => (props.show ? '100' : '0')};
  position: fixed;
  z-index: ${(props) =>
    props.show
      ? '50'
      : '-1'}; /* have to update z-index too or the hamburger icon from venueinformation is not accessible*/
  top: 0;
  left: 0;
  border: none;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 0; /* Place content 60px from the top */
  transition: opacity 0.3s;
  padding-right: 30px;
`;

const Header = styled.header`
  width: 90%;
  h5 {
    letter-spacing: 2px;
  }
`;
const Main = styled.main`
  width: 100%;
  flex: 1;
  overflow-y: auto; /*flex 1 + overflow-y: auto is what keeps the badges sticky*/
  overflow-x: hidden; /* Disable horizontal scroll */
`;
const Footer = styled.footer`
  width: 60%;
  margin: auto;
  margin-top: 2rem;
`;

const HamburgerContainer = styled.div`
  position: absolute;
  z-index: 40;
  top: 20px;
  left: 20px;
`;

const SettingsContainer = styled.div`
  padding-top: 70px;
`;

const Overlay = styled.div<StyledContainerProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(50, 62, 72, 0.6);
  transition: opacity 0.1s;
  opacity: ${(props) => (props.show ? '100' : '-1')};
  z-index: ${(props) =>
    props.show
      ? '40' /*set z-index below the sidebar z-index*/
      : '-1'}; /* have to update z-index too or the hamburger icon from venueinformation is not accessible*/
`;

function Sidebar(props: SidebarProps) {
  const dispatch = useDispatch();
  const showMenu = () => dispatch(toggleMenu());
  const history = useHistory();
  const { venueId } = useSelector(
    (state: RootState) => state.order.venue.details
  );

  const takeout = useSelector((state: RootState) => state.order.takeout);
  const location = useSelector((state: RootState) => state.order.location);

  let editTableNumber = (
    <ApplicationMenuItem
      name="Edit Table Number"
      handleClick={() => {
        history.push(`/venue/${venueId}/table` + history.location.search);
        showMenu();
      }}
    />
  );

  if (takeout || (location && location.length > 0))
    editTableNumber = <React.Fragment />;

  return (
    <PageContainer>
      <SideBarContainer show={props.show}>
        <ContentContainer>
          <Header>
            <HamburgerContainer>
              <Hamburger color={colors.primary} onClick={showMenu} />
            </HamburgerContainer>
            <SettingsContainer>
              <H5 marginBottom={0} color={colors.secondary}>
                Settings
              </H5>
              <ApplicationMenuItem
                name="Edit Name"
                handleClick={() => {
                  history.push(`/venue/${venueId}` + history.location.search);
                  showMenu();
                }}
              />
              {editTableNumber}
            </SettingsContainer>
          </Header>
          <Main>{props.children}</Main>
          <Footer>
            <BadgeContainer>
              <BarpayBadge src={barpayBadge} alt="Barpay Badge" />
            </BadgeContainer>
          </Footer>
        </ContentContainer>
      </SideBarContainer>
      <Overlay show={props.show} onClick={showMenu} />
    </PageContainer>
  );
}

export default Sidebar;
