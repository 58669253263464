import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import barpayBadge from '@barpay/barpay-design-system/lib/assets/images/BarpayBadge.png';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { useHistory, useParams } from 'react-router-dom';

const { H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled.header`
  height: 20%;
  width: 100%;
  text-align: center;
`;

const Main = styled.main`
  height: 80%;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
`;

const BarpayBadge = styled.img`
  border-radius: 10%;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-top: 2rem;
`;

const Header = styled.header`
  margin: 1rem auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSubContainer = styled.div``;

function AlcoholAgreement() {
  const history = useHistory();
  const { id } = useParams();
  return (
    <PageContainer>
      <Main>
        <Header>
          <HeaderSubContainer></HeaderSubContainer>
          <HeaderSubContainer>
            <H2>Attention!</H2>
          </HeaderSubContainer>
          <HeaderSubContainer>
            <CloseModalButton
              onClick={() => {
                history.push(
                  `/venue/${id}/menu/my-order` + history.location.search
                );
              }}
            />
          </HeaderSubContainer>
        </Header>
        <H5 fontWeight={400}>
          If you are ordering alcoholic beverages you agree that you are at
          least 21 years of age, have a valid ID, and that you have read, and
          are bound by,{' '}
          <a
            href="https://www.barpay.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Barpay’s Terms of Service
          </a>{' '}
          which state, “Users that are underage, or too inebriated to order,
          will not receive their order and WILL NOT be refunded"
        </H5>
      </Main>
      <Footer>
        <BarpayBadge src={barpayBadge} alt="Barpay Badge" />
      </Footer>
    </PageContainer>
  );
}

export default AlcoholAgreement;
