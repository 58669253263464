import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import VenueMenuCategory from 'components/VenueMenuCategory';
import { colors } from '@barpay/barpay-design-system';
import { Menu } from 'types/Menu';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { resetSelectedCategory } from 'store/actions';
import { useDispatch } from 'react-redux';

interface VenueMenuProps {
  foodMenu: Menu;
  drinksMenu: Menu;
}

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;

  ul {
    padding-inline-start: 0px;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    li {
      display: inline;
      cursor: pointer;
      padding: 0 2rem 7px;
      margin-right: 5px;
      color: ${(props) => props.theme.colors.primary};
      font-size: 0.8rem;
      font-family: ${(props) => props.theme.fonts.main};
      letter-spacing: 2px;
      text-transform: uppercase;

      &.react-tabs__tab--selected {
        border-bottom: 2px solid ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

function VenueMenu(props: VenueMenuProps) {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const selectedCategory = useSelector(
    (state: RootState) => state.order.selectedCategory
  );
  const { foodMenu, drinksMenu } = useSelector(
    (state: RootState) => state.order.menu
  );

  const drinksFirst = useSelector(
    (state: RootState) => state.order.venue.details.drinksFirst
  );

  useEffect(() => {
    if (selectedCategory > 0) {
      //determine which tabl the item belongs to and activate the tab
      if (foodMenu.categories.some((cat) => cat.id === selectedCategory)) {
        setTabIndex(drinksFirst ? 1 : 0);
      } else if (
        drinksMenu.categories.some((cat) => cat.id === selectedCategory)
      ) {
        setTabIndex(drinksFirst ? 0 : 1);
      }
    }
  }, [
    selectedCategory,
    foodMenu.categories,
    drinksMenu.categories,
    drinksFirst,
  ]);

  let menus = [props.drinksMenu, props.foodMenu];
  if (!drinksFirst) {
    menus = [props.foodMenu, props.drinksMenu];
  }

  const tabs = menus.map((menu) => (
    <TabPanel key={menu.id} className={'tabPanel'}>
      <VenueMenuCategory
        categoryTitle={menu.name}
        items={menu.categories}
        key={menu.id}
        menuId={menu.id}
      />
    </TabPanel>
  ));

  const tabTitles = menus.map((menu) => {
    if (menu.id === 421) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 437) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 472) {
      return <Tab key={menu.id}>{'Merchandise'}</Tab>;
    } else if (menu.id === 476) {
      return <Tab key={menu.id}>{'Merchandise'}</Tab>;
    } else if (menu.id === 504) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 517) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 521) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 529) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else if (menu.id === 533) {
      return <Tab key={menu.id}>{'Healing Services'}</Tab>;
    } else {
      return (
        <Tab key={menu.id}>
          {menu.name === 'Drinks' ? 'Beverages' : menu.name}
        </Tab>
      );
    }
  });

  return (
    <Container>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => {
          setTabIndex(tabIndex);
          dispatch(resetSelectedCategory());
        }}
      >
        <TabList id="tabListMenu">{tabTitles}</TabList>
        {tabs}
      </Tabs>
    </Container>
  );
}

export default VenueMenu;
