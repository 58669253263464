import React, { useEffect, useState } from 'react';

import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import Search from 'components/Search';
import VenueMenu from 'components/VenueMenu';
import { Modal } from '@barpay/barpay-design-system';
import MyOrder from 'pages/MyOrder';
import OrderConfirmed from 'pages/OrderConfirmed';
import BarpayStationOrder from 'pages/BarpayStationOrder';
import VerifyMyOrder from 'pages/VerifyMyOrder';
import PrintingError from 'pages/PrintingError';
import PaymentError from 'pages/PaymentError';
import GenericError from 'pages/GenericError';
import OutOfStockError from 'pages/OutOfStockError';
import BarClosedError from 'pages/BarClosedError';
import AppHeader from 'components/AppHeader';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Receipt from './Receipt';
import useVenueInformation from 'hooks/useVenueInformation';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { filterMenu, notifyBusyBar } from 'store/actions';
import Payment from 'pages/Payment';
import StripePayment from 'pages/StripePayment';
import AlcoholAgreement from 'pages/AlcoholAgreement';
import { PageContainer, Header, Main, Footer } from 'pages/Menu.styles';
import { AppContent } from '@barpay/barpay-design-system';
import ReceiptConfirmation from 'pages/ReceiptConfirmation';
import AddOrderItem from 'pages/AddOrderItem';
import CartCleared from './CartCleared';
import BusyBar from './BusyBar';
import { browserHistory } from 'index';

let orderNowFooter = document.querySelector<HTMLElement>('#orderNowFooter');

function Menu() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<div />);
  const { foodMenu, drinksMenu } = useSelector(
    (state: RootState) => state.order.menu
  );
  const notifiedBusyBar = useSelector(
    (state: RootState) => state.order.notifiedBusyBar
  );
  const busyBar = useSelector(
    (state: RootState) => state.order.venue.details.busyBar
  );

  const orderItems = useSelector((state: RootState) => state.order.orderItems);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  useVenueInformation();

  useEffect(() => {
    orderNowFooter = document.querySelector<HTMLElement>('#orderNowFooter');
    orderNowFooter?.style.setProperty(
      'width',
      orderNowFooter?.parentElement?.offsetWidth + 'px'
    );

    const contentMapping = [
      <MyOrder
        toggleModal={() =>
          history.push(`/venue/${id}/menu` + history.location.search)
        }
      />,
      <VerifyMyOrder />,
      <OrderConfirmed />,
      <BarpayStationOrder />,
      <PrintingError />,
      <PaymentError />,
      <GenericError />,
      <Receipt />,
      <ReceiptConfirmation />,
      <StripePayment
        toggleModal={() =>
          history.push(`/venue/${id}/menu` + history.location.search)
        }
      />,
      <Payment
        toggleModal={() =>
          history.push(`/venue/${id}/menu` + history.location.search)
        }
      />,
      <AlcoholAgreement />,
      <OutOfStockError />,
      <BarClosedError />,
      <AddOrderItem
        toggleModal={() =>
          history.push(`/venue/${id}/menu` + history.location.search)
        }
      />,
      <CartCleared />,
      <BusyBar />,
    ];
    const validPaths = [
      'my-order',
      'verify-order',
      'order-confirmed',
      'barpay-station-order',
      'printing-error',
      'payment-error',
      'generic-error',
      'receipt',
      'confirmedrec',
      'payment/stripe',
      'payment',
      'alcohol-agreement',
      'outofstock-error',
      'barclosed-error',
      'item-details',
      'cart-cleared',
      'busy-bar',
    ];
    const path = location.pathname.replace('/menu/', '/');
    const pathId = validPaths.findIndex((valid) => path.indexOf(valid) >= 0);

    if (pathId >= 0) {
      setIsModalOpen(true);
      setModalContent(contentMapping[pathId]);

      if (pathId === 8) {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('payment_intent')) {
          queryParams.delete('payment_intent');
          queryParams.delete('payment_intent_client_secret');
          queryParams.delete('source_type');
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    } else {
      setIsModalOpen(false);
      setModalContent(contentMapping[0]);
    }
  }, [location, history, id]);

  let queryParams = new URLSearchParams(useLocation().search);
  let paymentIntent = queryParams.get('payment_intent');
  if (busyBar && !notifiedBusyBar && !paymentIntent) {
    dispatch(notifyBusyBar());
    browserHistory.push(
      `/venue/${id}/menu/busy-bar` + browserHistory.location.search
    );
  }

  const venueMenu =
    drinksMenu.id > 0 ? (
      <VenueMenu foodMenu={foodMenu} drinksMenu={drinksMenu} />
    ) : (
      <React.Fragment />
    );

  const filter = useSelector((state: RootState) => state.order.filter);
  const button =
    orderItems.length > 0 ? (
      <Button
        onClick={() =>
          history.push(`/venue/${id}/menu/my-order` + history.location.search)
        }
        label="VIEW CART"
        styleType={ButtonStyleType.Secondary}
      />
    ) : (
      <React.Fragment />
    );

  return (
    <React.Fragment>
      <AppHeader />
      <AppContent>
        <PageContainer>
          <Modal content={modalContent} isOpen={isModalOpen} />
          <Header id="headerSearch">
            <Search
              value={filter}
              handleChange={(val: string) => dispatch(filterMenu(val))}
            />
          </Header>
          <Main>{venueMenu}</Main>
          <Footer id={'orderNowFooter'}>{button}</Footer>
        </PageContainer>
      </AppContent>
    </React.Fragment>
  );
}

export default Menu;
