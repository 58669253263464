import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';

import {
  editTip,
  updateTotals,
  toggleTip,
  updateTotalOnly,
} from 'store/actions';
import { colors } from '@barpay/barpay-design-system';

const { SmallLead, SmallParagraph } = Typography;

interface MyOrderPricingProps {
  hasError: boolean;
  errorMessage: string;
  validate: Function;
}

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 90%;
  margin: 1rem;
`;

const Main = styled.main`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
`;

const TipContainer = styled.main`
  display: flex;
  display-
`;

const LeftSide = styled.article`
  margin-top: auto;
  margin-bottom: auto;
`;

const RightSide = styled.article`
  margin-left: auto;
`;

function MyOrderPricing(props: MyOrderPricingProps) {
  const order = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch();
  const subtotal = useSelector((state: RootState) => state.order.subtotal);
  const tax = useSelector((state: RootState) => state.order.tax);
  const total = useSelector((state: RootState) => state.order.total);
  const convenienceFee = useSelector(
    (state: RootState) => state.order.convenienceFee
  );
  const optionalFee = useSelector(
    (state: RootState) => state.order.optionalFee
  );
  const optionalFeeLabel = useSelector(
    (state: RootState) => state.order.venue.details.optionalFeeLabel
  );
  const orderOnly = useSelector(
    (state: RootState) => state.order.venue.details.orderOnly
  );
  const optionalFeeUUID = useSelector(
    (state: RootState) => state.order.venue.details.optionalFeeUUID
  );
  const notifiedPriceChange = useSelector(
    (state: RootState) => state.order.notifiedPriceChange
  );
  const optSF = useSelector((state: RootState) => state.order.optSF);
  const tip = useSelector((state: RootState) => state.order.tip);

  let useOptionalFee = optSF === optionalFeeUUID;

  useEffect(() => {
    dispatch(updateTotals());
  }, [
    dispatch,
    subtotal,
    tax,
    tip,
    optionalFee,
    convenienceFee,
    notifiedPriceChange,
  ]);

  let errorMessageContainer = <React.Fragment />;

  if (props.hasError) {
    errorMessageContainer = (
      <SmallParagraph margin="0" color={colors.error}>
        {props.errorMessage}
      </SmallParagraph>
    );
  }

  let editComponent = (
    <TipContainer>
      <Button
        label="Change"
        padding="0"
        margin="0 1rem"
        fontSize="0.75rem"
        color="gray"
        styleType={ButtonStyleType.Back}
        onClick={() => dispatch(toggleTip(true))}
      />
      <SmallLead>
        <NumberFormat
          value={tip}
          displayType={'text'}
          thousandSeparator={true}
          allowNegative={true}
          fixedDecimalScale={true}
          prefix={'$'}
        />
      </SmallLead>
    </TipContainer>
  );

  let optionalFeeComponent = (
    <Main>
      <LeftSide>
        <SmallLead>{optionalFeeLabel}</SmallLead>
      </LeftSide>
      <RightSide>
        <SmallLead fontWeight={700}>
          <NumberFormat
            value={optionalFee}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </SmallLead>
      </RightSide>
    </Main>
  );

  let regexpNumber: RegExp = /^\$?[0-9]+(\.[0-9]{1,2})?$/;
  if (regexpNumber.test(tip)) {
    editComponent = (
      <TipContainer>
        <Button
          label="Change"
          padding="0"
          color="gray"
          fontSize="0.75rem"
          margin="0 1rem"
          styleType={ButtonStyleType.Back}
          onClick={() => dispatch(toggleTip(true))}
        />
        <SmallLead>
          <NumberFormat
            value={tip}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </SmallLead>
      </TipContainer>
    );
  }

  if (order.editTip) {
    editComponent = (
      <TextBox
        label="edit-tip"
        styleType={TextBoxStyleType.Standard}
        value={tip}
        onChange={(val) => {
          dispatch(editTip(val.target.value));
          dispatch(updateTotalOnly());
        }}
        onBlur={(val) => {
          dispatch(editTip(val.target.value));
          props.validate();
          dispatch(toggleTip(false));
        }}
      />
    );
  }
  return (
    <Container>
      <Main>
        <LeftSide>
          <SmallLead>Subtotal</SmallLead>
        </LeftSide>
        <RightSide>
          <SmallLead>
            <NumberFormat
              value={subtotal}
              displayType={'text'}
              thousandSeparator={true}
              allowNegative={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </SmallLead>
        </RightSide>
      </Main>
      <Main>
        <LeftSide>
          <SmallLead>Taxes and Fees</SmallLead>
        </LeftSide>
        <RightSide>
          <SmallLead>
            <NumberFormat
              value={tax + convenienceFee}
              displayType={'text'}
              thousandSeparator={true}
              allowNegative={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </SmallLead>
        </RightSide>
      </Main>
      {orderOnly ? (
        <React.Fragment />
      ) : (
        <Main>
          <LeftSide>
            <SmallLead>Tip</SmallLead>
          </LeftSide>
          <RightSide>
            {editComponent}
            {errorMessageContainer}
          </RightSide>
        </Main>
      )}
      {!useOptionalFee || optionalFee === 0 ? '' : optionalFeeComponent}
      <Main>
        <LeftSide>
          <SmallLead fontWeight={700}>Total</SmallLead>
        </LeftSide>
        <RightSide>
          <SmallLead fontWeight={700}>
            <NumberFormat
              value={total}
              displayType={'text'}
              thousandSeparator={true}
              allowNegative={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </SmallLead>
        </RightSide>
      </Main>
    </Container>
  );
}

export default MyOrderPricing;
