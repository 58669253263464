import styled from 'styled-components';
import { colors } from '@barpay/barpay-design-system';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.header`
  margin: 2rem auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderSubContainer = styled.div``;
const Main = styled.main`
  width: 100%;
  margin: 20px auto 0;
`;
const Footer = styled.footer`
  width: 70%;
  margin: 1rem auto;
  button {
    margin: 0;
  }
`;

const PaymentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaymentButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ManualPaymentButtonContainer = styled.div``;

export {
  PageContainer,
  Header,
  HeaderSubContainer,
  Main,
  Footer,
  PaymentContainer,
  PaymentButtonContainer,
  ManualPaymentButtonContainer,
  LoadingContainer,
};
