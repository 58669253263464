import React from 'react';
import styled from 'styled-components';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import ShoppingCart from 'components/ShoppingCart';
import VenuePedigreeInformation from 'components/VenuePedigreeInformation';
import { Hamburger } from '@barpay/barpay-design-system';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from 'store/actions';
import { RootState } from 'store';

const { H4 } = Typography;

interface VenueInformationProps {
  logo: string;
  heroImage: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  disableHours?: boolean;
}

const Hero = styled.div`
  background-image: url('${(p: { heroImage: string }) => p.heroImage}');
  /* Set a specific height */
  height: 100%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const VenueOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(50, 62, 72, 0.1);
  color: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const VenueLogo = styled.img`
  border-radius: 10%;
  text-align: center;
  width: auto;
  height: ${(p: { height: number; marginTop: number }) => p.height}%;
  margin-top: ${(p: { height: number; marginTop: number }) => p.marginTop}px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 45px;
`;

const HamburgerContainer = styled.div`
  position: absolute;
  z-index: 40;
  top: 20px;
  left: 20px;
`;

const CartContainer = styled.div`
  position: absolute;
  z-index: 40;
  top: 0px;
  right: 10px;
`;

const TopMenuContainer = styled.div`
  background-color: ${colors.primary};
  height: 30%;
`;

function VenueInformation(props: VenueInformationProps) {
  const orderItems = useSelector((state: RootState) => state.order.orderItems);
  const barOpen = useSelector(
    (state: RootState) => state.order.venue.details.barOpen
  );
  const kitchenOpen = useSelector(
    (state: RootState) => state.order.venue.details.kitchenOpen
  );
  const openCloseHours = useSelector(
    (state: RootState) => state.order.venue.details.openCloseHours
  );
  const isOpen = barOpen || kitchenOpen;

  const dispatch = useDispatch();
  const showMenu = () => dispatch(toggleMenu());
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  let navIcons = <React.Fragment />;

  let venueLogo = (
    <VenueLogo src={props.logo} alt="logo" height={65} marginTop={0} />
  );

  if (location.pathname.indexOf('menu') >= 0) {
    venueLogo = (
      <VenueLogo src={props.logo} alt="logo" height={55} marginTop={65} />
    );
    navIcons = (
      <TopMenuContainer>
        <HamburgerContainer>
          <Hamburger color={colors.background} onClick={showMenu} />
        </HamburgerContainer>
        <H4
          textAlign={'center'}
          marginTop={0}
          marginBottom={0}
          marginLeft={60}
          marginRight={60}
          paddingTop={22}
          color={colors.background}
        >
          {props.name.length > 24
            ? `${props.name.substr(0, 24)}...`
            : props.name}
        </H4>
        <CartContainer>
          <ShoppingCart
            onClick={() => {
              history.push(
                `/venue/${id}/menu/my-order` + history.location.search
              );
            }}
            items={orderItems.length}
          />
        </CartContainer>
      </TopMenuContainer>
    );
  }

  let venuePedigree = (
    <VenuePedigreeInformation
      open={isOpen ? 'Open' : 'Closed'}
      hours={openCloseHours}
      address1={
        props.address1.length > 24
          ? `${props.address1.substr(0, 24)}...`
          : props.address1
      }
      address2={props.address2}
      city={
        props.city.length > 24 ? `${props.city.substr(0, 24)}...` : props.city
      }
      state={props.state}
      zipCode={props.zipCode}
    />
  );

  if (props.disableHours) {
    venuePedigree = <React.Fragment />;
  }

  return (
    <Container>
      <Hero heroImage={props.heroImage}>
        {navIcons}
        <VenueOverlay>{venueLogo}</VenueOverlay>
      </Hero>
      {venuePedigree}
    </Container>
  );
}

export default VenueInformation;
