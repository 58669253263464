import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import shoppingIcon from '@barpay/barpay-design-system/lib/assets/images/shopping-cart.svg';
import shoppingIconWithBadge from '@barpay/barpay-design-system/lib/assets/images/shopping-cart-with-badge.svg';

interface StyledIconButtonProps {
  items: number;
}
interface ShoppingCartProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  items: number;
}

const StyledIconButton = styled.button<StyledIconButtonProps>`
  margin: 0;
  padding: 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  position: relative;

  ${(props) =>
    props.items > 0 &&
    `
        &[data-badge]:after {
        content: attr(data-badge);
    
        position: absolute;
        top: 12px;
        right: 10px;
    
        font-size: 10px;
        font-family: ${props.theme.fonts.main};
    
        text-align: center;
        line-height: 14px;
    
        background: ${props.theme.colors.error};
        color: ${props.theme.colors.background};
    
        width: 14px;
        height: 14px;
    
        border-radius: 50%;
        border: 2px solid ${props.theme.colors.background};
      `}
`;

const StyledIcon = styled.img`
  height: 4rem;
  width: 4rem;
  cursor: pointer;
`;

function ShoppingCart({ items = 0, ...props }: ShoppingCartProps) {
  const img =
    items > 0 ? (
      <StyledIcon src={shoppingIconWithBadge} />
    ) : (
      <StyledIcon src={shoppingIcon} />
    );
  return (
    <StyledIconButton {...props} items={items} data-badge={items}>
      {img}
    </StyledIconButton>
  );
}

export default ShoppingCart;
