import { useEffect, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useParams, useHistory } from 'react-router-dom';
import {
  thunkGetMenu,
  thunkGetVenueInformation,
  thunkRefreshMenu,
} from 'store/thunks';
import { raiseError } from 'store/actions';

const refreshMenu = (dispatch: Dispatch<any>, id: number) => {
  dispatch(thunkRefreshMenu(id));
  setTimeout(() => refreshMenu(dispatch, id), 30 * 1000);
};

function UseVenueInformation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateVenueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );
  const etag = useSelector((state: RootState) => state.order.etag);
  let { id } = useParams();
  const validId = id > 0;

  if (!validId) dispatch(raiseError());
  if (
    stateVenueId === 0 &&
    history.location.pathname !== `/venue/${id}` &&
    history.location.pathname.indexOf('review') < 0 && //should not redirect on a review
    history.location.pathname.indexOf('completedreview') < 0 && //should not redirect on a review
    history.location.pathname.indexOf('thankyou') < 0 && //should not redirect on a review
    history.location.pathname.indexOf('fastpass') < 0 //should not redirect on a fastpass
  )
    history.push(`/venue/${id}` + history.location.search);

  useEffect(() => {
    //if the url is valid and the API has not already been called
    if (validId && stateVenueId === 0) {
      dispatch(thunkGetMenu(id, etag));
      dispatch(thunkGetVenueInformation(id));
      setTimeout(() => refreshMenu(dispatch, id), 30 * 1000);
    }
  }, [id, validId, dispatch, stateVenueId, etag]);
}

export default UseVenueInformation;
