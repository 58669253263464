import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
const { H2, H3, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  background-color: ${colors.error};
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
  button {
    margin: 0;
  }
`;

function OrderConfirmed() {
  const history = useHistory();
  const table = useSelector((state: RootState) => state.order.table);
  const takeout = useSelector((state: RootState) => state.order.takeout);
  const location = useSelector((state: RootState) => state.order.location);
  const longQueue = useSelector((state: RootState) => state.order.longQueue);
  const waitTime = useSelector((state: RootState) => state.order.waitTime);

  let locationType = useSelector(
    (state: RootState) => state.order.locationType
  );

  if (locationType === '') locationType = 'table';

  let orderReadyIn = useSelector(
    (state: RootState) => state.order.venue.details.venueOrderReadyIn
  );
  const venueName = useSelector(
    (state: RootState) => state.order.venue.details.venueName
  );
  const orderOnly = useSelector(
    (state: RootState) => state.order.venue.details.orderOnly
  );

  let { id } = useParams();

  if (orderReadyIn === null || orderReadyIn === undefined) {
    orderReadyIn = 20;
  }

  let longQueueMessage =
    'The venue is currently more busy than usual. It may take longer than normal to receive your order and we thank you for your patience.';

  if (waitTime > 0) {
    longQueueMessage = `The venue is currently more busy than usual. Your order will begin to be prepared in approximately ${waitTime} minutes. We thank you for your patience.`;
  }

  let confirmMessage = '';
  if (table === 'qrcode') {
    confirmMessage = `Your order has been placed and you will receive a text message when its ready. ${
      longQueue ? longQueueMessage : ''
    }`;
  } else if (takeout && location.length === 0) {
    confirmMessage = `Your order has been received and will be ready for pickup at ${venueName} in approximately ${orderReadyIn} minutes. See you soon!`;
  } else if (takeout && location.length > 0) {
    confirmMessage = `Your order has been received and will be ready for pickup at ${location} in approximately ${orderReadyIn} minutes. See you soon!`;
  } else if (location.length > 0) {
    confirmMessage = `Your order has been placed and will be delivered to ${location}${
      longQueue ? longQueueMessage : ''
    }`;
  } else {
    confirmMessage = `Your order has been placed and will be delivered to ${locationType} ${table}. ${
      longQueue ? longQueueMessage : ''
    }`;
  }

  let notice = (
    <React.Fragment>
      <H3 color={'#fff'}>Notice!</H3>
      <H5 fontWeight={400} color={'#fff'}>
        If you change your location/table before your next order, please scan
        the QR code at the new location so we know where to deliver your order.
      </H5>
    </React.Fragment>
  );

  if (takeout || table === 'qrcode') {
    notice = <React.Fragment></React.Fragment>;
  }

  return (
    <PageContainer>
      <Header>{notice}</Header>
      <Main>
        <H2>Order Confirmed!</H2>
        <H5 fontWeight={400}>{confirmMessage}</H5>
      </Main>
      <Footer>
        <Button
          onClick={() =>
            !orderOnly
              ? history.push(
                  `/venue/${id}/menu/receipt` + history.location.search
                )
              : history.push(`/venue/${id}/menu` + history.location.search)
          }
          label="Continue"
          styleType={ButtonStyleType.Secondary}
        />
      </Footer>
    </PageContainer>
  );
}

export default OrderConfirmed;
