import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import { Typography } from '@barpay/barpay-design-system';
import { device } from '@barpay/barpay-design-system';
import trashicon from '@barpay/barpay-design-system/lib/assets/images/delete.svg';
import errorImage from '@barpay/barpay-design-system/lib/assets/images/ErrorImage.png';
import { useDispatch } from 'react-redux';
import { deleteMenuItem, updateTotals } from 'store/actions';

const { LargeLead, SmallLead } = Typography;

export interface MyOrderItemProps {
  name: string;
  price: string;
  logo: string;
  quantity: number;
  id: number;
  index: number;
}

interface MenuItemLogo {
  url: string;
}

const MyOrderItemLogo = styled.div<MenuItemLogo>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const MyOrderItemLogoImg = styled.img`
  @media ${device.mobileS} {
    width: 80px;
    height: 80px;
  }
  @media ${device.mobileL} {
    width: 96px;
    height: 96px;
  }

  @media ${device.tablet} {
    width: 120px;
    height: 120px;
  }
  visibility: hidden; /*this is a hack to get the size of the div to be the same as the image*/
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrashIcon = styled.img`
  @media ${device.mobileS} {
    height: 1.5rem;
  }
  @media ${device.mobileL} {
    height: 2rem;
  }
  cursor: pointer;
`;
const LeftSide = styled.article``;

const RightSide = styled.article`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

function MyOrderItem(props: MyOrderItemProps) {
  const dispatch = useDispatch();
  //@ts-ignore
  const addDefaultSrc = (ev) => {
    ev.target.src = errorImage;
  };

  return (
    <Container>
      <LeftSide>
        <LargeLead>{props.name}</LargeLead>
        <SmallLead>Qty: {props.quantity}</SmallLead>
        <SmallLead>
          <NumberFormat
            value={props.price}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </SmallLead>
      </LeftSide>
      <RightSide>
        <IconWrapper>
          <TrashIcon
            src={trashicon}
            onClick={() => {
              dispatch(deleteMenuItem(props.index));
              dispatch(updateTotals());
            }}
          />
        </IconWrapper>
        <MyOrderItemLogo url={props.logo ?? errorImage}>
          <MyOrderItemLogoImg
            src={props.logo ?? errorImage}
            alt={props.name}
            onError={addDefaultSrc}
          />
        </MyOrderItemLogo>
      </RightSide>
    </Container>
  );
}

export default MyOrderItem;
