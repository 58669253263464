import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { Link } from '@barpay/barpay-design-system';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { giveEmail } from 'store/actions';
import useValidateEmailField from 'hooks/useValidateEmailField';
import { thunkSendReceipt } from 'store/thunks';
import { TextBox, TextBoxStyleType } from '@barpay/barpay-design-system';

const { H2, H5, SmallParagraph } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 2rem;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 3rem;
`;
const UserContainer = styled.div``;
const HeaderSubContainer = styled.div``;

function Receipt() {
  const history = useHistory();
  let { id } = useParams();
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );
  const [hasError, errorMessage, validate] = useValidateEmailField(
    (state: RootState) => state.order.email,
    'email',
    `/menu/confirmedrec`
  );

  const email = useSelector((state: RootState) => state.order.email);
  const dispatch = useDispatch();

  const sendReceipt = () => {
    dispatch(thunkSendReceipt());
    //@ts-ignore
    window.clevertap.profile.push({
      venueId: venueId,
      Site: {
        Identity: email,
        Email: email,
      },
    });
    //@ts-ignore
    window.clevertap.event.push('Receipt Requested', {
      venueId: venueId,
      Identity: email,
      email: email,
    });
  };

  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2></H2>
        </HeaderSubContainer>
      </Header>
      <Main>
        <H2>Get Your Receipt</H2>
        <H5 fontWeight={400}>
          Please enter your email address so we know where to send your receipt.
          If you enjoyed this service, consider downloading the{' '}
          <Link
            label="Barpay App "
            onClick={() =>
              window.open('https://apps.apple.com/us/app/barpay/id1110920135')
            }
          />
          for an even faster experience.
        </H5>
        <UserContainer>
          <TextBox
            label="email"
            onChange={(event: any) => dispatch(giveEmail(event.target.value))}
            value={email}
            styleType={TextBoxStyleType.Standard}
            hasError={hasError}
          />
          <SmallParagraph margin="0" color={colors.error}>
            {errorMessage}
          </SmallParagraph>
        </UserContainer>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            validate(sendReceipt);
          }}
          label="Send Receipt"
          styleType={ButtonStyleType.Secondary}
        />
      </Footer>
    </PageContainer>
  );
}

export default Receipt;
