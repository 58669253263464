import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
const { H1, H2, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
  button {
    margin: 0;
  }
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-family: ${(props) => props.theme.fonts.main};
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
`;

const OrderContainer = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 90%;
  margin: 10px auto;
  border: solid 1px #aaa;
  border-radius: 2%;
  overflow-x: hidden;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20% 10px 0;
  border-bottom: solid 2px #eee;
`;

const Extras = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 25px;
`;

function BarpayStationOrder() {
  const now = new Date();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [buttonColor, setButtonColor] = useState(colors.success);
  const [buttonLabel, setButtonLabel] = useState('I Picked Up My Order!');
  const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);
  const [timestamp] = useState(now);
  const orderNumber = useSelector((state: RootState) => state.order.orderId);
  const previousOrderItems = useSelector(
    (state: RootState) => state.order.previousOrderItems
  );
  const pickupLocation = useSelector(
    (state: RootState) => state.order.pickupLocation
  );

  let { id } = useParams();

  let confirmMessage =
    'You can pick up your order at the pickup station. Please show this screen to the staff to collect your order.';
  let pickup = 'Pickup Station';

  if (
    pickupLocation !== undefined &&
    pickupLocation !== null &&
    pickupLocation !== ''
  ) {
    confirmMessage = `You can pick up your order at ${pickupLocation}. Please show this screen to the staff to collect your order.`;
    pickup = pickupLocation;
  }

  const nextStep = () => {
    if (step === 0) {
      setButtonColor(colors.error);
      setButtonLabel('Are you sure?');
      setStep(1);
    } else {
      history.push(`/venue/${id}/menu/receipt` + history.location.search);
    }
  };

  const toggleOrderDetailsOpen = () => {
    setOrderDetailsOpen(!orderDetailsOpen);
  };

  let orderDetails = [<React.Fragment />];

  if (orderDetailsOpen && previousOrderItems.length > 0) {
    const menuItems = previousOrderItems.map((orderItem) => {
      let extras = [<Extras></Extras>];
      if (orderItem.extras.length > 0) {
        let extrasArray = orderItem.extras.map((extra) => {
          let name = extra.extraName;
          let quantity = extra.quantity;
          let extraString = `${quantity > 1 ? `${quantity}  ` : ''}${name}`;
          return (
            <H2 margin="0 10%" paddingLeft="15%">
              - {extraString}
            </H2>
          );
        });
        extras = extrasArray;
      }

      let menuItem = (
        <Container key={orderItem.menuItemId}>
          <H1 marginLeft="10%" textAlign="left">
            {orderItem.quantity} {orderItem.name}
          </H1>
          {extras}
        </Container>
      );
      return menuItem;
    });

    orderDetails = [
      <React.Fragment>
        <OrderContainer>{menuItems}</OrderContainer>
      </React.Fragment>,
    ];
  }

  return (
    <PageContainer>
      <Main>
        <H2 marginTop={75}>Pickup Location:</H2>
        <H2 color={colors.error}>{pickup}</H2>
        <H5 fontWeight={400}>{confirmMessage}</H5>
        <H2 marginTop="2rem">Order #:</H2>
        <H1 fontSize={100}>{orderNumber}</H1>
        <StyledLink onClick={toggleOrderDetailsOpen}>
          {orderDetailsOpen ? 'Close Order Details' : 'Order Details'}
        </StyledLink>
        {orderDetails}
        <H5 margin={0} fontWeight={400}>
          Date: {timestamp.toLocaleString()}
        </H5>
      </Main>
      <Footer>
        <Button
          onClick={nextStep}
          label="I Picked Up My Order!"
          styleType={ButtonStyleType.Secondary}
          bgColorOverride={buttonColor}
          labelOverride={buttonLabel}
        />
      </Footer>
    </PageContainer>
  );
}

export default BarpayStationOrder;
