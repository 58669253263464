import React from 'react';
import styled from 'styled-components';
import MyOrderItem from 'components/MyOrderItem';
import { colors } from '@barpay/barpay-design-system';
import { OrderItem, OrderItemExtra } from 'store/types';
import { v4 as uuidv4 } from 'uuid';

import { Typography } from '@barpay/barpay-design-system';
const { H4 } = Typography;

export interface MyOrderItemsProps {
  items: OrderItem[];
}

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
`;

const Divider = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background: ${(p) => p.theme.colors.secondaryvarient};
`;

function MyOrderItems(props: MyOrderItemsProps) {
  let emptyContent = (
    <React.Fragment>
      <H4>Please add some items!</H4>
    </React.Fragment>
  );

  const listItems = props.items.map((myItem, idx) => {
    const extrasReducer = (accumulator: number, current: OrderItemExtra) =>
      (accumulator += +current.price);
    const extraTotal = myItem.extras.reduce(extrasReducer, 0);
    const price = +myItem.price + extraTotal;

    return (
      <React.Fragment key={uuidv4()}>
        <MyOrderItem
          id={myItem.menuItemId}
          index={idx}
          name={myItem.name + (myItem.extras.length > 0 ? ' w/ Extras' : '')}
          price={price.toString()}
          logo={myItem.logo}
          quantity={myItem.quantity}
          key={myItem.menuItemId}
        />
        <Divider />
      </React.Fragment>
    );
  });

  return (
    <Container>
      <Divider />
      {listItems.length > 0 ? listItems : emptyContent}
    </Container>
  );
}

export default MyOrderItems;
