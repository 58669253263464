const urlCheckTokens = ['https', 'http', 'www.'];

export const containsLink = (desc: string) => {
  if (!desc) return false;

  return urlCheckTokens.reduce((returnValue, token) => {
    return returnValue || desc.indexOf(token) >= 0;
  }, false);
};

export const parseUrl = (desc: string) => {
  let startLocation = -1;
  let endLocation = -1;

  //configure values for parsing
  urlCheckTokens.forEach((token) => {
    //only loop until it finds the right token
    if (startLocation === -1) {
      startLocation = desc.indexOf(token);
      if (startLocation >= 0) {
        endLocation = desc.indexOf(' ', startLocation);
        if (endLocation === -1) endLocation = desc.length;
      }
    }
  });

  const firstPart = desc.substr(0, startLocation);
  const url = desc.substr(startLocation, endLocation - startLocation);
  const secondPart = desc.substr(endLocation, desc.length - endLocation);
  return { firstPart, url, secondPart };
};
