import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@barpay/barpay-design-system';
import { device } from '@barpay/barpay-design-system';

interface VenuePedigreeInformationProps {
  open: string;
  hours: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 30px;
  margin-top: ${(p: { margin: number }) => p.margin}px;
  margin-bottom: 3px;
`;

const InlineParagraph = styled.p`
  margin: 0;
  color: ${(p: { letterSpacing: number; color: string }) => p.color};
  font-family: ${fonts.secondary};
  font-weight: 800;
  @media ${device.mobileS} {
    font-size: 12px;
  }
  @media ${device.mobileL} {
    font-size: 16px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 45px;
`;

function VenuePedigreeInformation(props: VenuePedigreeInformationProps) {
  return (
    <Container>
      <Line margin={15}>
        <InlineParagraph letterSpacing={1.25} color={colors.primary}>
          {props.address1} {props.address2}
        </InlineParagraph>
        <InlineParagraph letterSpacing={1.25} color={colors.primary}>
          {props.open}
        </InlineParagraph>
      </Line>
      <Line margin={0}>
        <InlineParagraph letterSpacing={1.25} color={colors.primary}>
          {props.city}, {props.state} {props.zipCode}
        </InlineParagraph>
        <InlineParagraph
          letterSpacing={1.25}
          color={props.open === 'Open' ? colors.success : colors.error}
        >
          {props.hours}
        </InlineParagraph>
      </Line>
    </Container>
  );
}

export default VenuePedigreeInformation;
