import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';

const { H1, H2, H3, H4, H5 } = Typography;
const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 2rem;
  text-align: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

function BusyBar() {
  const history = useHistory();
  let { id } = useParams();

  return (
    <PageContainer>
      <Header></Header>
      <Main>
        <H1 marginBottom={'20px'}>Attention:</H1>
        <H2>
          If the main bar looks busy, consider ordering to a different bar.
        </H2>
      </Main>
      <Footer>
        <Button
          onClick={() => {
            history.push(`/venue/${id}/menu` + history.location.search);
          }}
          label="Got It"
          styleType={ButtonStyleType.Primary}
        />
      </Footer>
    </PageContainer>
  );
}

export default BusyBar;
