// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { Loader } from '@barpay/barpay-design-system';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm';

const { H2 } = Typography;

interface PaymentProps {
  toggleModal?: Function;
}

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin: 2rem auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderSubContainer = styled.div``;

function StripePayment(props: PaymentProps) {
  const history = useHistory();
  let { id } = useParams();
  const stripe = useSelector((state: RootState) => state.order.stripe);
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: stripe.stripeAccountId,
  });

  let mainPaymentContent = (
    <LoadingContainer>
      <Loader
        color={colors.primary}
        message={'Placing Your Order'}
        subheader={
          'If the venue is busy it can take a couple of minutes to print your order.'
        }
        secondsubheader={'PLEASE DO NOT REFRESH'}
      />
    </LoadingContainer>
  );
  let footerContent = <React.Fragment></React.Fragment>;
  let paymentButton = <React.Fragment />;

  if (!stripe.makingPayment) {
    paymentButton = (
      <CloseModalButton
        onClick={() => {
          if (props.toggleModal) props.toggleModal();
        }}
      />
    );

    let options = undefined;

    if (stripe && stripe.stripeSecret) {
      options = {
        paymentMethodCreation: 'manual',
        clientSecret: stripe.stripeSecret,
        appearance: { theme: 'stripe' },
      };
    }

    mainPaymentContent = <div></div>;
    mainPaymentContent = options && stripePromise && (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    );

    footerContent = (
      <React.Fragment>
        <Button
          onClick={() => {
            history.push(
              `/venue/${id}/menu/my-order` + history.location.search
            );
            //@ts-ignore
            window.clevertap.event.push('Payment Exit Modal', {
              venueId: venueId,
            });
          }}
          label="Go Back"
          styleType={ButtonStyleType.Back}
        />
      </React.Fragment>
    );
  }

  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2>Payment</H2>
        </HeaderSubContainer>
        <HeaderSubContainer>{paymentButton}</HeaderSubContainer>
      </Header>
      <Main>{mainPaymentContent}</Main>
      <Footer>{footerContent}</Footer>
    </PageContainer>
  );
}

export default StripePayment;
