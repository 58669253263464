import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import VenueMenuItem from 'components/VenueMenuItem';
import { colors } from '@barpay/barpay-design-system';
import { MenuItem } from 'types/Menu';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';

export interface VenueMenuSubcategoryProps {
  subcategoryTitle: string;
  items: Array<MenuItem>;
  id: number;
  handleClick?: Function;
  selected: boolean;
}

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: ${(p) => p.theme.fonts.main};
  font-size: 0.9rem;
  color: ${(p) => p.theme.colors.secondary};
  background-color: ${colors.surface};
  text-transform: uppercase;
  letter-spacing: 1.25px;
  width: 100%;
  padding: 1rem;
  flex: 75%;
`;

const Divider = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background: ${(p) => p.theme.colors.secondaryvarient};
`;

const ChevronContainer = styled.div`
  background-color: ${colors.surface};
  width: 100%;
  height: 100%;
  flex: 25%;
`;

const ChevronUp = styled.div`
  display: inline-block;
  border-right: 2px solid ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
  width: 9px;
  height: 9px;
  transform: rotate(-135deg);
  background-color: ${colors.surface};
  margin-top: 23px;
  margin-right: 23px;
  float: right;
`;

const ChevronDown = styled.div`
  display: inline-block;
  border-right: 2px solid ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
  width: 9px;
  height: 9px;
  transform: rotate(-315deg);
  background-color: ${colors.surface};
  margin-top: 18px;
  margin-right: 23px;
  float: right;
`;

function VenueMenuSubcategory(props: VenueMenuSubcategoryProps) {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.order.filter);
  const scrollRef = useRef<HTMLDivElement>(null);
  const selectedCategory = useSelector(
    (state: RootState) => state.order.selectedCategory
  );

  let items = props.items;
  if (filter && filter.length > 0) {
    items = items.filter(
      (item) => item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    );
  }

  const listItems = props.selected ? (
    items.map((item) => (
      <React.Fragment key={item.id}>
        <VenueMenuItem item={item} key={item.id} />
        <Divider />
      </React.Fragment>
    ))
  ) : (
    <React.Fragment />
  );

  useLayoutEffect(() => {
    if (selectedCategory === props.id) {
      if (scrollRef.current) {
        const rootElement = document.querySelector<HTMLElement>('#root');
        const rect = scrollRef.current.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const headerSearch = document.querySelector<HTMLElement>(
          '#headerSearch'
        );
        const tabListMenu = document.querySelector<HTMLElement>('#tabListMenu');
        const offset =
          headerSearch && tabListMenu
            ? headerSearch?.offsetHeight + tabListMenu?.offsetHeight
            : 0;

        rootElement?.scrollTo({
          top: rect.top + scrollTop - offset - 100,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedCategory, props.id, dispatch]);

  const chevron = props.selected ? <ChevronUp /> : <ChevronDown />;

  return items.length > 0 ? (
    <Container id={`menu-category-${props.id}`}>
      <TitleContainer
        onClick={(event) => {
          if (props.handleClick) props.handleClick();
        }}
      >
        <Title ref={scrollRef}>
          {props.subcategoryTitle.length > 22
            ? `${props.subcategoryTitle.substr(0, 22)}...`
            : props.subcategoryTitle}
        </Title>
        <ChevronContainer>{chevron}</ChevronContainer>
      </TitleContainer>
      <Divider />
      {listItems}
    </Container>
  ) : (
    <React.Fragment />
  );
}

export default VenueMenuSubcategory;
