// @ts-nocheck
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyleType } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { CloseModalButton } from '@barpay/barpay-design-system';
import { Loader } from '@barpay/barpay-design-system';
import { thunkMakeManualPayment } from 'store/thunks';
import { rollbar } from 'api/api';

const { H2 } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  margin: 2rem auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Main = styled.main`
  text-align: center;
  width: 80%;
`;
const Footer = styled.footer`
  width: 80%;
  margin-bottom: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderSubContainer = styled.div``;

function FastPassManualPayment() {
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams();
  const fastPassesPrice = useSelector(
    (state: RootState) => state.order.fastPassesPrice
  );
  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );

  const venueDetails = useSelector(
    (state: RootState) => state.order.venue.details
  );

  const order = useSelector((state: RootState) => state.order);
  const venueId = useSelector(
    (state: RootState) => state.order.venue.details.venueId
  );

  useEffect(() => {
    if (fastPassesToPurchase <= 0) {
      history.push(`/venue/${id}/fastpass`);
    }
  });

  useEffect(() => {
    var submitButton = document.querySelector('#submit-button');
    braintree.dropin.create(
      {
        authorization: order.brainTree.braintreeAuth,
        selector: '#dropin-container',
      },
      function (err, dropinInstance) {
        if (err) {
          rollbar.error(
            'An error occurred when creating the braintree drop in UI',
            err
          );
          return;
        }
        submitButton.addEventListener('click', function () {
          dropinInstance.requestPaymentMethod(function (err, payload) {
            if (err) {
              rollbar.error(
                'An error occurred when requesting a payment method',
                err
              );
            } else {
              window.scrollTo(0, 0);
              dispatch(
                thunkMakeManualPayment(
                  payload.nonce,
                  true,
                  venueDetails.currentFastPassTier
                )
              );
            }
          });
        });
      }
    );
  }, [
    dispatch,
    order.brainTree.braintreeAuth,
    venueDetails.currentFastPassTier,
  ]);

  let mainPaymentContent = (
    <LoadingContainer>
      <Loader
        color={colors.primary}
        message={'Placing Your Fast Pass Order'}
        subheader={''}
        secondsubheader={'PLEASE DO NOT REFRESH'}
      />
    </LoadingContainer>
  );
  let footerContent = <React.Fragment></React.Fragment>;
  let paymentButton = <React.Fragment />;

  if (!order.brainTree.makingManualPayment) {
    paymentButton = (
      <CloseModalButton
        onClick={() => {
          history.push(`/venue/${id}/fastpasspayment`);
        }}
      />
    );
    mainPaymentContent = <div id="dropin-container"></div>;

    footerContent = (
      <React.Fragment>
        <Button
          id="submit-button"
          onClick={() => {
            //@ts-ignore
            window.clevertap.event.push('Placed Fast Pass Order', {
              venueId: venueId,
              ItemName: 'Fast Pass',
              Quantity: fastPassesToPurchase,
            });

            //@ts-ignore
            window.clevertap.event.push('Charged', {
              venueId: venueId,
              Type: 'Manual',
              Amount: fastPassesPrice,
              Venue: order.venue.details.venueName,
            });
          }}
          label="Complete Payment"
          styleType={ButtonStyleType.Primary}
        />
        <Button
          onClick={() => {
            history.push(`/venue/${id}/fastpasspayment`);
          }}
          label="Go Back"
          styleType={ButtonStyleType.Back}
        />
      </React.Fragment>
    );
  }

  return (
    <PageContainer>
      <Header>
        <HeaderSubContainer></HeaderSubContainer>
        <HeaderSubContainer>
          <H2>Payment</H2>
        </HeaderSubContainer>
        <HeaderSubContainer>{paymentButton}</HeaderSubContainer>
      </Header>
      <Main>{mainPaymentContent}</Main>
      <Footer>{footerContent}</Footer>
    </PageContainer>
  );
}

export default FastPassManualPayment;
