import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonStyleType, device } from '@barpay/barpay-design-system';
import { colors } from '@barpay/barpay-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import AppHeader from 'components/AppHeader';
import useVenueInformation from 'hooks/useVenueInformationWithoutMenus';
import { AppContent } from '@barpay/barpay-design-system';
import { Typography } from '@barpay/barpay-design-system';
import { Modal } from '@barpay/barpay-design-system';
import { useParams, useHistory } from 'react-router-dom';
import lightningIcon from 'assets/images/lightning.png';
import FastPassQuantityCounter from 'components/FastPassQuantityCounter';
import NumberFormat from 'react-number-format';
import { orderFastPass } from 'store/actions';
import FastPassModal from './FastPassModal';

const { H2, H1, SmallParagraph } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Lightning = styled.img`
  @media ${device.mobileS} {
    height: 2.5rem;
  }
  @media ${device.mobileL} {
    height: 3rem;
  }
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

function FastPassOrder() {
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const verifiedPhoneNumber = useSelector(
    (state: RootState) => state.order.verifiedPhoneNumber
  );

  const venueDetails = useSelector(
    (state: RootState) => state.order.venue.details
  );

  useVenueInformation();

  useEffect(() => {
    if (venueDetails.venueName && !venueDetails.fastPassAvailable) {
      history.push(`/venue/${id}/fastpassdisabled`);
    }

    if (!verifiedPhoneNumber || verifiedPhoneNumber.length === 0) {
      history.push(`/venue/${id}/fastpass`);
    }
  });

  const getPassByTierNumber = (tierNum: number) => {
    return venueDetails.fastPasses.find((fp) => fp.tier === tierNum);
  };

  const totalFastPassesRemaining = venueDetails.fastPasses.reduce(
    (accumulator, curr) => accumulator + curr.remaining,
    0
  );

  const currentFastPassTier = getPassByTierNumber(
    venueDetails.currentFastPassTier
  );

  const nextTier = getPassByTierNumber(venueDetails.currentFastPassTier + 1);

  const updatePrice = (newQuantity: number) => {
    let price = 0;
    let purchasedComplete = newQuantity;
    let currTier = venueDetails.currentFastPassTier;

    while (purchasedComplete > 0) {
      const currTierPass = getPassByTierNumber(currTier);

      if (currTierPass) {
        const purchased =
          currTierPass.remaining > purchasedComplete
            ? purchasedComplete
            : currTierPass.remaining;
        price += purchased * parseInt(currTierPass.price, 10);
        purchasedComplete -= purchased;

        if (purchased !== newQuantity) currTier++;
      }
    }

    setCurrentPurchasePrice(price);
  };

  const updateQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    updatePrice(newQuantity);
  };

  const [quantity, setQuantity] = useState(0);
  const [currentPurchasePrice, setCurrentPurchasePrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(true);

  let content = <React.Fragment />;

  if (currentFastPassTier) {
    const nextTierContent = nextTier ? (
      <IconWrapper>
        <Lightning src={lightningIcon} />
        <SmallParagraph margin={0} marginLeft={10} fontSize={13}>
          {nextTier.quantity} additional passes will be sold at{' '}
          <NumberFormat
            value={nextTier.price}
            displayType={'text'}
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={0}
            prefix={'$'}
          />
        </SmallParagraph>
      </IconWrapper>
    ) : (
      <React.Fragment />
    );
    content = (
      <PageContainer>
        <Container>
          <H2 margin={0}>Only {currentFastPassTier.remaining} left at</H2>
          <H1 margin={2} color={colors.success} fontSize={'60px'}>
            <NumberFormat
              value={currentFastPassTier.price}
              displayType={'text'}
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={0}
              prefix={'$'}
            />
          </H1>
          {nextTierContent}
        </Container>
        <Container>
          <H2 margin={3}>Select Quantity</H2>
          <SmallParagraph margin={3} fontSize={15}>
            How many Fast Passes would you like?
          </SmallParagraph>
          <FastPassQuantityCounter
            quantity={quantity}
            decrement={() => {
              if (quantity > 0) updateQuantity(quantity - 1);
            }}
            increment={() => {
              if (quantity < totalFastPassesRemaining)
                updateQuantity(quantity + 1);
            }}
          />
        </Container>
        <Container>
          <Button
            label={`$${currentPurchasePrice} - Purchase Now`}
            onClick={async () => {
              if (quantity > 0) {
                dispatch(orderFastPass(currentPurchasePrice, quantity));
                history.push(`/venue/${id}/fastpasspayment`);
              }
            }}
            disabled={currentPurchasePrice <= 0}
            styleType={ButtonStyleType.Secondary}
          />
        </Container>
      </PageContainer>
    );
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalContent = <FastPassModal onClick={closeModal} />;

  return (
    <React.Fragment>
      <Modal content={modalContent} isOpen={isModalOpen} />
      <AppHeader disableHours={true} />
      <AppContent>{content}</AppContent>
    </React.Fragment>
  );
}

export default FastPassOrder;
