import { useParams, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, DefaultRootState } from 'react-redux';

function UseValidateField<TState = DefaultRootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  fieldName: string,
  url?: string,
  len?: number,
  validateFunction?: (
    value: string
  ) => { isValid: boolean; errorMessage: string }
): [boolean, string, () => Promise<boolean>] {
  const history = useHistory();
  let { id } = useParams();
  const field = useSelector(selector);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const validate = async (): Promise<boolean> => {
    const str = (field as unknown) as string;
    if (!str || str.length === 0) {
      setHasError(true);
      setErrorMessage(`You must enter a ${fieldName}`);
      return Promise.resolve(true);
    } else if (
      len !== undefined &&
      len !== null &&
      len > 0 &&
      len !== 1000 &&
      str.length !== len
    ) {
      setHasError(true);
      setErrorMessage(
        `Please enter exactly ${len} characters for ${fieldName}`
      );
      return Promise.resolve(true);
    } else if (validateFunction) {
      const result = validateFunction(str);
      if (!result.isValid) {
        setHasError(true);
        setErrorMessage(result.errorMessage);
        return Promise.resolve(true);
      }
    }

    setHasError(false);
    setErrorMessage('');
    if (url !== null && url !== undefined && url !== '') {
      history.push(`/venue/${id}${url}` + history.location.search);
    }
    return Promise.resolve(false);
  };

  return [hasError, errorMessage, validate];
}

export default UseValidateField;
