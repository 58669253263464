//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  Button,
  ButtonStyleType,
  colors,
  Typography,
} from '@barpay/barpay-design-system';
import { thunkMakeStripePayment } from 'store/thunks';

const { Paragraph } = Typography;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CheckoutForm = () => {
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const amount = useSelector(
    (state: RootState) => state.order.stripe.stripeAmount
  );

  const secret = useSelector(
    (state: RootState) => state.order.stripe.stripeSecret
  );

  const intentId = useSelector(
    (state: RootState) => state.order.stripe.stripeIntentId
  );

  const fastPassesToPurchase = useSelector(
    (state: RootState) => state.order.fastPassesToPurchase
  );

  const venueDetails = useSelector(
    (state: RootState) => state.order.venue.details
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    } else {
      setLoading(true);

      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.error(submitError);
        setLoading(false);
        setStatus(`${submitError.message}`);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
      });

      if (error) {
        console.error(res.error);
        setLoading(false);
        setStatus(`${error.message}`);
        return;
      }

      const stripeData = {
        secret: secret,
        payment: paymentMethod,
        intent: intentId,
        url: window.location.href,
      };

      if (fastPassesToPurchase === 0) {
        dispatch(thunkMakeStripePayment(stripeData));
      } else {
        dispatch(
          thunkMakeStripePayment(
            stripeData,
            true,
            venueDetails.currentFastPassTier
          )
        );
      }
    }
  };

  const getDescription = () => {
    let description = 'Pay';
    if (loading) {
      description = 'Processing...';
    } else if (amount) {
      let roundedTotal = (amount / 100).toFixed(2);
      description = `Pay $${roundedTotal}`;
    }

    return description;
  };

  return (
    <PageContainer>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          type="submit"
          label={getDescription()}
          styleType={ButtonStyleType.Primary}
          disabled={!stripe || loading}
        />
      </form>
      {status && <Paragraph>{status}</Paragraph>}
    </PageContainer>
  );
};

export default CheckoutForm;
